import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const createCustomFormEntry = (body) =>
  axios.post(`${API_URL}prospectiveClients`, body);

const updateCustomFormEntry = (body, uniqueId) =>
  axios.post(`${API_URL}prospectiveClients/${uniqueId}`, body);

// eslint-disable-next-line
export default {
  createCustomFormEntry,
  updateCustomFormEntry,
};
