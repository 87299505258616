import React from 'react';

function RadioButton({ label, data, onChange, value, name }) {
  return (
    <div>
      <div>
        <label className="text-base font-medium text-gray-900">{label}</label>
        <fieldset className="mt-2">
          <div className="space-y-2">
            {data?.map((v) => (
              <div key={v.id} className="flex items-center">
                <input
                  onChange={onChange}
                  id={v?.id}
                  name={name}
                  type="radio"
                  checked={v?.id === value}
                  value={v?.id}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor={v?.id}
                  className="ml-3 block text-sm  text-gray-900"
                >
                  {v?.label}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default RadioButton;
