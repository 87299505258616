import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ProviderGeneralTable from './ProviderGeneralTable';
import ProfessionalUpkeepTable from './ProfessionalUpkeepTable';
import ProviderProfessionalInfoTable from './ProviderProfessionalInfoTable';
import ProviderInsuranceInfoTable from './ProviderInsuranceInfoTable';
import ProviderPracticeInfoTable from './ProviderPracticeInfoTable';

const TabLabelWithDescription = ({ title, description }) => (
  <div style={{ textAlign: 'center' }}>
    <div style={{ fontSize: '1rem' }}>{title}</div>{' '}
    {/* Larger font size for title */}
    <div className="tabDescription">{description}</div>{' '}
    {/* Smaller font size for description */}
  </div>
);

export default function Provider() {
  const [showActiveProviders, setShowActiveProviders] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    localStorage.removeItem('activeTab');

    setActiveTab(newValue);

    // Assuming the sidebar is only meant to be visible on the first tab (index 0)
    if (newValue !== 0) {
      // Hide the sidebar and remove the class when switching away from the first tab
    }
  };

  const toggleShowActiveProviders = () => {
    const newShowActive = !showActiveProviders;

    // Before changing the showActiveProviders state, adjust the activeTab if necessary
    if (!newShowActive && activeTab === 2) {
      // If we are about to hide the "Professional Upkeep" tab and it is active
      setActiveTab(1); // Move to a safe tab, e.g., "Insurance Information"
    } else if (newShowActive && activeTab >= 2) {
      // Adjust to correct for the reintroduction of the "Professional Upkeep" tab
      setActiveTab((prev) => prev + 1);
    }

    setShowActiveProviders(newShowActive);
  };

  const getAdjustedTabIndex = (baseIndex) => {
    if (!showActiveProviders && baseIndex >= 2) {
      // If the "Professional Upkeep" tab is usually at index 2 and it's not shown,
      // decrease the index of all tabs originally after it by 1.
      return baseIndex - 1;
    }
    return baseIndex;
  };

  return (
    <div>
      {/* Tabs Component */}
      <div
        style={{
          position: 'sticky',
          top: 1,
          bottom: 1,
          zIndex: 1000,
          width: '100%',
          marginTop: '75px',
        }}
      >
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            aria-label="client tabs"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{
              paddingTop: '1px',
              paddingBottom: '1px',
              background: '#F5F5F5',
              display: 'flex', // Ensure the tabs are aligned as you want (optional)
              justifyContent: 'center', // Center the tabs in the tab bar (optional)
              marginTop: '-4px',
            }}
          >
            <Tab
              label={
                <TabLabelWithDescription
                  title="Personal Info"
                  description="Name, Email, DOB, etc"
                />
              }
              style={{
                minWidth: 160,
                maxWidth: 260,
                marginRight: '20px',
                borderRight: '1x solid #e0e0e0',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              className={
                activeTab === getAdjustedTabIndex(0) ? 'customTabSelected' : ''
              }
              classes={{ root: 'customTabRoot' }}
            />

            <Tab
              label={
                <TabLabelWithDescription
                  title="Insurance Information"
                  description="Insurance Credentialing Status, Provider Numbers, Effective Dates, etc"
                />
              }
              style={{
                minWidth: 160,
                maxWidth: 260,
                marginRight: '20px',
                borderRight: '1x solid #e0e0e0',
                borderLeft: '1px solid #e0e0e0',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              className={
                activeTab === getAdjustedTabIndex(1) ? 'customTabSelected' : ''
              }
              v
              classes={{ root: 'customTabRoot' }}
            />

            {showActiveProviders && (
              <Tab
                label={
                  <TabLabelWithDescription
                    title="Professional Upkeep"
                    description="Attestations, Rosters, Licensure & Malpractice Insurance"
                  />
                }
                style={{
                  minWidth: 160,
                  maxWidth: 260,
                  marginRight: '20px',
                  borderRight: '1x solid #e0e0e0',
                  borderLeft: '1px solid #e0e0e0',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
                className={
                  activeTab === getAdjustedTabIndex(2)
                    ? 'customTabSelected'
                    : ''
                }
                classes={{ root: 'customTabRoot' }}
              />
            )}

            <Tab
              label={
                <TabLabelWithDescription
                  title="Education & Supervision"
                  description="Graduating School & Supervisory Information"
                />
              }
              style={{
                minWidth: 160,
                maxWidth: 260,
                marginRight: '20px',
                borderRight: '1x solid #e0e0e0',
                borderLeft: '1px solid #e0e0e0',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              className={
                activeTab === getAdjustedTabIndex(3) ? 'customTabSelected' : ''
              }
              classes={{ root: 'customTabRoot' }}
            />

            <Tab
              label={
                <TabLabelWithDescription
                  title="Provider Practice Info "
                  description="HIPAA Workforce, Doxy/Meet, LLC Info, etc"
                />
              }
              style={{
                minWidth: 160,
                maxWidth: 260,
                marginRight: '20px',
                marginLeft: '20px',
                borderRight: '1x solid #e0e0e0',
                borderLeft: '1px solid #e0e0e0',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              className={
                activeTab === getAdjustedTabIndex(4) ? 'customTabSelected' : ''
              }
              classes={{ root: 'customTabRoot' }}
            />
          </Tabs>
        </Box>
      </div>

      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          {activeTab !== 2 && (
            <FormControlLabel
              control={
                <Switch
                  checked={showActiveProviders}
                  onChange={toggleShowActiveProviders}
                  name="showActiveProviders"
                />
              }
              label={
                showActiveProviders ? 'Active Providers' : 'Inactive Providers'
              }
              style={{ margin: 0 }}
            />
          )}
        </div>
      </TableContainer>
      {activeTab === getAdjustedTabIndex(0) && (
        <ProviderGeneralTable
          showActiveProviders={showActiveProviders}
          setShowActiveProviders={setShowActiveProviders}
        />
      )}
      {activeTab === getAdjustedTabIndex(1) && (
        <ProviderInsuranceInfoTable
          showActiveProviders={showActiveProviders}
          setShowActiveProviders={setShowActiveProviders}
        />
      )}
      {showActiveProviders && activeTab === getAdjustedTabIndex(2) && (
        <ProfessionalUpkeepTable
          showActiveProviders={showActiveProviders}
          setShowActiveProviders={setShowActiveProviders}
        />
      )}
      {activeTab === getAdjustedTabIndex(3) && (
        <ProviderProfessionalInfoTable
          showActiveProviders={showActiveProviders}
          setShowActiveProviders={setShowActiveProviders}
        />
      )}
      {activeTab === getAdjustedTabIndex(4) && (
        <ProviderPracticeInfoTable
          showActiveProviders={showActiveProviders}
          setShowActiveProviders={setShowActiveProviders}
        />
      )}
    </div>
  );
}
