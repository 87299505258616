import React, { useState } from 'react';
import {
  Button,
  Popover,
  Typography,
  CardActions,
  Paper,
  Fade,
} from '@mui/material';

export default function AppropAlertButton({
  provider,
  getProviderYesMaybeText,
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handleClick = (event) => {
    setPopoverOpen((prev) => !prev);
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setPopoverAnchorEl(null);
  };

  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  return (
    <CardActions disableSpacing style={{ marginTop: '-10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: '5px',
          height: '25px',
        }}
      >
        <div style={{ alignItems: 'center' }}>
          {getProviderYesMaybeText &&
            provider &&
            getProviderYesMaybeText(provider).length > 0 && (
              <Button
                variant="contained"
                onClick={handleClick}
                style={{
                  height: '18px',
                  width: '100%',
                  margin: '10px auto 0',
                }}
              >
                <Typography variant="caption" style={{ fontSize: '8px' }}>
                  Service Alert
                </Typography>
              </Button>
            )}
          <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            TransitionComponent={Fade}
            transitionDuration={350}
          >
            <Paper>
              <Typography sx={{ p: 4 }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      getProviderYesMaybeText &&
                      provider &&
                      getProviderYesMaybeText(provider),
                  }}
                ></div>
              </Typography>
            </Paper>
          </Popover>
        </div>
      </div>
    </CardActions>
  );
}
