import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  brown,
  pink,
  green,
  blue,
  yellow,
  purple,
  grey,
} from '@mui/material/colors';
import Button from '@mui/material/Button';
import PanToolIcon from '@mui/icons-material/PanTool';
import Popover from '@mui/material/Popover';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import '../../styles/ProviderCard.css';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import moment from 'moment-timezone';
import { isDST, getFirstCharacters } from '../../common/utility';
import UserService from '../../services/user.service';
import { Col, Row } from 'reactstrap';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.6rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

// time 4 hours added to make it GMT
function formatSlotDateTime(date) {
  const startDateTime = moment(date);

  const isDst = isDST(startDateTime.toDate());

  const hoursToAdd = isDst ? 4 : 5;

  startDateTime.add(hoursToAdd, 'hours');

  const formattedStartDate = startDateTime.format('ddd M/DD/YY');
  const formattedStartTime = startDateTime
    .tz('America/New_York')
    .format('h:mmA'); // Have added that .tz here so that it always converts to EST time

  return `${formattedStartDate} ${formattedStartTime}`;
}

function getFrequencyAvatar(frequency) {
  switch (frequency) {
    case 'weekly':
      return { avatar: { letter: 'W', color: grey[50] } };
    case 'every_other_week':
      return { avatar: { letter: 'EOW', color: pink[100] } };
    case 'floater':
      return { avatar: { letter: 'FL', color: brown[300] } };
    default:
      return null;
  }
}

function getFrequencyText(frequency) {
  switch (frequency) {
    case 'weekly':
      return '(Weekly)';
    case 'every_other_week':
      return '(EOW)';
    case 'floater':
      return '(Floater)';
    default:
      return '';
  }
}

const getSlotAgeColor = (age) => {
  if (age >= 14 && age < 21) {
    return 'goldenrod';
  } else if (age >= 21 && age < 28) {
    return 'darkorange';
  } else if (age >= 28) {
    return 'red';
  }
  return 'transparent'; // default, in case it's used elsewhere
};

function getLocationDetails(slotOrLocation) {
  let location;
  let setting;

  if (typeof slotOrLocation === 'string') {
    location = slotOrLocation;
    setting = null;
  } else if (typeof slotOrLocation === 'object') {
    location = slotOrLocation.location;
    setting = slotOrLocation.setting;
  } else {
    return [{ avatar: { letter: 'Que', color: grey[500] } }]; // Default case
  }

  if (setting === 'Any Setting') {
    const locationAvatar = getLocationAvatar(location);
    if (locationAvatar) {
      return [
        { avatar: { letter: 'Rem', color: yellow[700] } },
        locationAvatar,
      ];
    }
    return [{ avatar: { letter: 'Rem', color: yellow[700] } }];
  } else if (setting === 'Remote Only') {
    return [{ avatar: { letter: 'Rem', color: yellow[700] } }];
  }

  return [getLocationAvatar(location)];
}

function getLocationAvatar(location) {
  switch (location) {
    case 'Baltimore':
      return { avatar: { letter: 'Bal', color: purple[500] } };
    case 'Silver Spring':
      return { avatar: { letter: 'SS', color: green[500] } };
    case 'Columbia':
      return { avatar: { letter: 'Col', color: blue[500] } };
    default:
      return null;
  }
}

export default function ProviderCard({
  step03FormValues,
  step05FormValues,
  title,
  image,
  getProviderYesMaybeText,
  getProviderDetail,
  obj,
  availableSlots,
  obj: provider,
  expandedOfToggle,
  onSlotClickHadler,
  isManual,
  entryTypeFormValues,
  onWithoutCalenderClickHandler,
  setSelectedProvider,
  isCardClickable,
}) {
  let providerSlots = availableSlots.filter(
    (slot) => slot.provider_id === provider.id
  );

  const [expanded, setExpanded] = React.useState(false);
  const [isPsyPactMember, setIsPsyPactMember] = React.useState(false);
  const [licenseInOtherStates, setLicenseInOtherStates] = React.useState();

  React.useEffect(() => {
    setExpanded(expandedOfToggle);
  }, [expandedOfToggle]);

  React.useEffect(() => {
    async function getProviderDetaiFromAPI() {
      const response = await getProviderDetail(obj.id, obj.email);
      setIsPsyPactMember(
        response?.data?.provider?.psyPactDetails?.isPsyPactMember === 'yes'
          ? true
          : false
      );
      setLicenseInOtherStates(
        response?.data?.provider?.licensed_in_other_states?.split(',')
      );
    }
    getProviderDetaiFromAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // below is for the service alert
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [popoverHoldAnchorEl, setPopoverHoldAnchorEl] = React.useState({});
  const [popoverNoteAnchorEl, setPopoverNoteAnchorEl] = React.useState({});
  const [isClicked, setIsClicked] = React.useState(false);

  const handleChange = (panel, isExpanded) => {
    if (isExpanded === true) {
      setExpanded(panel);
    } else if (isExpanded === panel) {
      setExpanded(true);
    } else if (isExpanded !== panel) {
      setExpanded(panel);
    }
  };
  const groupedSlots = providerSlots.reduce((result, slot) => {
    const groupId = slot.provider_availability_id;
    if (!result[groupId]) {
      result[groupId] = [slot];
    } else {
      result[groupId].push(slot);
    }
    return result;
  }, {});

  // handleClick is for the Service Button
  const handleClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const renderStateBadges = () => {
    const states = [];
    const selectedState = step05FormValues?.provider_other_licensed_state;

    // Add other licensed states
    if (licenseInOtherStates?.includes(selectedState)) {
      states.push(selectedState);
    }

    // Check if the provider is a PsyPact member and add the PsyPact badge, excluding "MD Only" and no state selected cases
    if (isPsyPactMember && selectedState && selectedState !== 'MD Only') {
      states.push('PsyPact');
    }

    return states.map((state, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: state === 'PsyPact' ? green[500] : purple[500], // Green for PsyPact, purple for others
          color: '#fff',
          padding: '2px 8px',
          borderRadius: '12px',
          marginLeft: '4px',
          fontSize: '0.75rem',
        }}
      >
        <VerifiedUserIcon sx={{ marginRight: '4px', fontSize: '1rem' }} />
        {state}
      </Box>
    ));
  };

  const handleCardClick = async () => {
    if (isCardClickable) {
      try {
        const providerDetails = await UserService.getProviderDetail(
          obj.id,
          obj.email
        );
        if (providerDetails && providerDetails.data) {
          // Pass the details up to the parent component
          setSelectedProvider(
            providerDetails.data.provider.degrees_and_license
          );
        } else {
          console.log('No provider details found');
        }
      } catch (error) {
        console.error('Failed to fetch provider details', error);
      }
      setIsClicked(true); // Set card as clicked
    } else {
      console.log('Card clicked but not clickable');
    }
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleNotePopoverClose = (slotId) => {
    setPopoverNoteAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: null,
    }));
  };

  const handleHoldPopoverClose = (slotId) => {
    setPopoverHoldAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: null,
    }));
  };

  const handleHoldClick = (event, slotId) => {
    setPopoverHoldAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: event.currentTarget,
    }));
  };

  const handleNoteClick = (event, slotId) => {
    setPopoverNoteAnchorEl((prevState) => ({
      ...prevState,
      [slotId]: event.currentTarget,
    }));
  };

  const popoverOpen = Boolean(popoverAnchorEl);
  const popoverId = popoverOpen ? 'popover-popover' : undefined;

  // * Anchor holds and notes
  const holdOpen = Boolean(popoverHoldAnchorEl);
  const holdId = holdOpen ? 'hold-popover' : undefined;

  const noteOpen = Boolean(popoverNoteAnchorEl);
  const noteId = noteOpen ? 'note-popover' : undefined;

  // Filter providerSlots to get open slots
  let providerOpens = providerSlots.filter((slot) => slot.type === 'Open');
  // Get the unique provider_availability_ids for open slots
  let uniqueOpenProviderAvailabilityIds = [
    ...new Set(providerOpens.map((open) => open.provider_availability_id)),
  ];

  // Get the number of unique open slots
  let numberOfUniqueOpens = uniqueOpenProviderAvailabilityIds.length;

  // Filter providerSlots to get *Holds
  let providerHolds = providerSlots.filter((slot) => slot.type === 'Hold');

  // Get the unique provider_availability_ids
  let uniqueProviderAvailabilityIds = [
    ...new Set(providerHolds.map((hold) => hold.provider_availability_id)),
  ];

  // Get the number of unique holds
  let numberOfUniqueHolds = uniqueProviderAvailabilityIds.length;

  let frequencies = providerSlots.map((slot) => slot.frequency);
  let uniqueFrequencies = [...new Set(frequencies)];

  const locationDetailsArray = providerSlots.flatMap((slot) =>
    getLocationDetails(slot)
  );
  const uniqueLocationDetailsArray = locationDetailsArray.reduce(
    (uniqueArray, locationDetails) => {
      const isDuplicate = uniqueArray.some(
        (ld) =>
          ld.avatar.letter === locationDetails.avatar.letter &&
          ld.avatar.color === locationDetails.avatar.color
      );

      if (!isDuplicate) {
        uniqueArray.push(locationDetails);
      }

      return uniqueArray;
    },
    []
  );

  // Calculate the number of unique slots that are 14 days or older and are of type 'opening'
  const now = moment();

  const numberOfUniqueOlderSlots = Object.values(groupedSlots).reduce(
    (count, slotGroup) => {
      // Filter out holds and get only opening slots
      const openingsOnly = slotGroup.filter((slot) => slot.type !== 'Hold');

      if (!openingsOnly.length) return count; // If there are no openings in this group, just continue

      const firstOpeningSlot = openingsOnly[0]; // Consider the first opening slot from the group
      const createdAt = moment(firstOpeningSlot.createdAt);
      const slotAgeInDays = now.diff(createdAt, 'days');

      return slotAgeInDays >= 14 ? count + 1 : count;
    },
    0
  );

  const isTopIssuesIncludeInSevice = (issue) => {
    let check =
      getProviderYesMaybeText(obj)?.provider?.therapy?.topIssues?.[issue];
    if (step03FormValues?.services?.length > 0)
      return step03FormValues?.services?.some((v) => v.title === check);
    else {
      return false;
    }
  };
  // The below function is responsible for inner sorting i.e latest inner slot would come first and oldest would come at the last.
  const sortObjectKeysBySlotStartTime = (obj) => {
    const currentDate = new Date();

    const keyValuePairs = Object.entries(obj).map(([key, value]) => {
      const slotDateTime = value[0] ? new Date(value[0].start_date_time) : null;
      const timeDifference = slotDateTime
        ? Math.abs(currentDate - slotDateTime)
        : Infinity;
      return { key, timeDifference, data: obj[key] };
    });

    keyValuePairs.sort((a, b) => a.timeDifference - b.timeDifference);

    const sortedArray = keyValuePairs.map((item) => ({
      [item.key]: item.data,
    }));

    return sortedArray;
  };
  const sortedInnerSlots = sortObjectKeysBySlotStartTime(groupedSlots);

  if (!isManual) {
    if (uniqueFrequencies.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      return (
        <div>
          <Row className="g-2">
            <div className="col-12 relative-container">
              <Col xs={12}>
                {getProviderYesMaybeText(obj)?.provider?.therapy
                  ?.providerPriorityScheduling && (
                  <div className="priorityRibbon">Priority</div>
                )}
                <div style={{ width: '100%' }}>
                  <Card
                    onClick={handleCardClick}
                    style={{
                      width: '100%',
                      backgroundColor: '#f5f5f5',
                      minHeight: '125px',
                      cursor: isCardClickable ? 'pointer' : 'default', // Change cursor to pointer if clickable
                      position: 'relative', // Relative positioning for overlay
                    }}
                  >
                    {isCardClickable && !isClicked && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px', // Position towards the top
                          left: '50%',
                          transform: 'translateX(-50%)', // Center horizontally
                          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Grey overlay
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          fontSize: '1.2em',
                          padding: '5px 10px', // Optional: add some padding for better visibility
                          zIndex: 1, // Ensure overlay is on top
                        }}
                      >
                        Click to Update Fee with License
                      </div>
                    )}

                    <CardHeader
                      style={{ height: '50px' }}
                      avatar={
                        <React.Fragment>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              margin: -10,
                              justifyContent: 'space-between',
                              marginLeft: getProviderYesMaybeText(obj)?.provider
                                ?.therapy?.providerPriorityScheduling
                                ? 13
                                : -10,
                            }}
                          >
                            {!isManual &&
                              uniqueLocationDetailsArray?.map(
                                (locationDetails) => (
                                  <Col
                                    xs={3}
                                    key={locationDetails.avatar.letter}
                                  >
                                    <Box
                                      sx={{
                                        bgcolor: locationDetails.avatar.color,
                                        fontSize: '12px',
                                        width: 26,
                                        height: 26,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                      aria-label={provider.name}
                                    >
                                      {locationDetails.avatar.letter}
                                    </Box>
                                  </Col>
                                )
                              )}
                          </div>
                          {renderStateBadges()}{' '}
                          {/* This line renders the badges */}
                        </React.Fragment>
                      }
                      action={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: -10,
                            paddingRight: 10,
                          }}
                        >
                          {!isManual &&
                            uniqueFrequencies?.map((frequency) => {
                              let frequencyDetails =
                                getFrequencyAvatar(frequency);
                              return (
                                <Box
                                  key={frequencyDetails.avatar.letter}
                                  sx={{
                                    bgcolor: frequencyDetails.avatar.color,
                                    fontSize: '11px',
                                    width: 26,
                                    height: 26,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  aria-label={frequency}
                                >
                                  {frequencyDetails.avatar.letter}
                                </Box>
                              );
                            })}
                        </div>
                      }
                    />

                    <div style={{ marginTop: '-10px', textAlign: 'center' }}>
                      <ThemeProvider theme={theme}>
                        <Typography
                          variant="body"
                          style={{ fontWeight: 'bold' }}
                        >
                          {title}
                        </Typography>
                      </ThemeProvider>
                    </div>

                    <div style={{ marginTop: '5px' }}>
                      <CardMedia
                        component="img"
                        height="194"
                        image={image}
                        alt="Provider img"
                      />
                    </div>
                    <CardActions disableSpacing style={{ marginTop: '-10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          marginTop: '5px',
                        }}
                      >
                        <div style={{ alignItems: 'center' }}>
                          {isManual && (
                            <Row
                              style={{
                                color: 'red',
                                height: '28px',
                                cursor: 'pointer',
                                marginTop: '10px',
                                paddingLeft: '10px',
                                marginBottom: '10px',
                                paddingRight: '10px',

                                width: '100%',
                              }}
                              onClick={async (e) => {
                                onWithoutCalenderClickHandler(obj, title);
                              }}
                            >
                              {!(
                                entryTypeFormValues.entryType === 'existing' &&
                                entryTypeFormValues.existingClientOptions ===
                                  'Turning 18 years-Old'
                              ) ? (
                                'Schedule appointment without Calendar availability'
                              ) : (
                                <div>
                                  <Button>Select</Button>
                                </div>
                              )}
                            </Row>
                          )}

                          {getProviderYesMaybeText &&
                            obj &&
                            getProviderYesMaybeText(obj).out.length > 0 && (
                              <Button
                                variant="contained"
                                onClick={handleClick}
                                style={{
                                  height: '20px',
                                  width: '100%',
                                  margin: '20px auto 0',
                                }}
                              >
                                Service Alert
                              </Button>
                            )}

                          <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={popoverAnchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            TransitionComponent={Fade}
                            transitionDuration={350}
                          >
                            <Paper>
                              <Typography sx={{ p: 4 }}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      getProviderYesMaybeText &&
                                      obj &&
                                      getProviderYesMaybeText(obj).out,
                                  }}
                                ></div>
                                {/* {getProviderYesMaybeText &&
                                obj &&
                                getProviderYesMaybeText(obj).out} */}
                              </Typography>
                            </Paper>
                          </Popover>
                        </div>
                      </div>
                    </CardActions>

                    <CardActions>
                      {!isManual && numberOfUniqueOpens > 0 && (
                        <Badge
                          sx={{
                            '.MuiBadge-badge': {
                              background:
                                numberOfUniqueOlderSlots > 0
                                  ? `linear-gradient(to right, green ${(
                                      (numberOfUniqueOpens.toString().length /
                                        (numberOfUniqueOpens.toString().length +
                                          numberOfUniqueOlderSlots.toString()
                                            .length)) *
                                      100
                                    ).toFixed(0)}%, orange ${(
                                      (numberOfUniqueOpens.toString().length /
                                        (numberOfUniqueOpens.toString().length +
                                          numberOfUniqueOlderSlots.toString()
                                            .length)) *
                                      100
                                    ).toFixed(0)}%)`
                                  : 'green',
                              color: 'white',
                              fontWeight: 'bold',
                              padding: '0 6px',
                              fontSize: '0.75rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '24px', // Ensuring a consistent height
                              minWidth: '40px', // Ensuring a minimum width for clear visibility
                              whiteSpace: 'nowrap', // Prevent text wrapping
                              overflow: 'hidden', // Prevent overflow of text
                            },
                          }}
                          badgeContent={
                            numberOfUniqueOlderSlots > 0
                              ? `${numberOfUniqueOpens}  ${numberOfUniqueOlderSlots}`
                              : numberOfUniqueOpens
                          }
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <IconButton>
                            <EventAvailableIcon
                              style={{ width: '22px', height: '22px' }}
                              sx={{ color: 'green' }}
                            />
                          </IconButton>
                        </Badge>
                      )}
                      <Box
                        sx={{
                          color: 'action.active',
                          display: 'flex',
                          flexDirection: 'column',
                          '& > *': {
                            marginBottom: '2px',
                          },
                          '& .MuiBadge-root': {
                            marginRight: 4,
                            marginTop: '5px', // Adjust the marginTop value to move the Badge further down
                          },
                        }}
                      >
                        {!isManual && numberOfUniqueHolds > 0 && (
                          <Badge
                            badgeContent={numberOfUniqueHolds}
                            color="error"
                            sx={{ '& .MuiBadge-badge': { marginTop: '5px' } }}
                          >
                            <IconButton>
                              <PanToolIcon
                                style={{ width: '18px', height: '18px' }}
                                sx={{ color: 'red' }}
                              />
                            </IconButton>
                          </Badge>
                        )}
                      </Box>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        {isTopIssuesIncludeInSevice('issue3') && (
                          <Typography style={{ fontSize: '2em' }}>
                            <span role="img" aria-label="note">
                              🥉
                            </span>
                          </Typography>
                        )}
                        {isTopIssuesIncludeInSevice('issue2') && (
                          <Typography style={{ fontSize: '2em' }}>
                            <span role="img" aria-label="note">
                              🥈
                            </span>
                          </Typography>
                        )}
                        {isTopIssuesIncludeInSevice('issue1') && (
                          <Typography style={{ fontSize: '2em' }}>
                            <span role="img" aria-label="note">
                              🥇
                            </span>
                          </Typography>
                        )}
                      </div>

                      {!isManual &&
                        !Object.values(groupedSlots).length === 0 && (
                          <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                    </CardActions>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent style={{ width: '100%' }}>
                        {sortedInnerSlots.map((sortedInnerObj) => {
                          return Object.values(sortedInnerObj).map(
                            (slotGroup, groupIndex) => {
                              const firstSlot = slotGroup[0];
                              const isFirstSlotGroup = true;

                              // const isFirstSlotGroup =
                              //   groupIndex === 0 || firstSlot?.type === 'Hold';
                              // const isFirstSlotGroup = true;

                              // Calculate the slot age in days
                              const createdAt = moment(firstSlot.createdAt);
                              const now = moment();
                              const slotAgeInDays = now.diff(createdAt, 'days');

                              // Decide the display text based on the slot age
                              let slotAgeDisplay;
                              if (slotAgeInDays < 1) {
                                slotAgeDisplay = 'Brand New - < 1 day';
                              } else {
                                slotAgeDisplay = `(${slotAgeInDays} days-old)`;
                              }
                              return (
                                <Accordion
                                  key={`group${groupIndex}`}
                                  expanded={expanded === `group${groupIndex}`}
                                  style={{ width: '100%' }}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      slotGroup.length > 1 && (
                                        <ExpandMoreIcon
                                          onClick={() =>
                                            handleChange(
                                              `group${groupIndex}`,
                                              expanded
                                            )
                                          }
                                        />
                                      )
                                    }
                                    aria-controls={`group${groupIndex}-content`}
                                    id={`group${groupIndex}-header`}
                                    style={{
                                      width: '100%',
                                      minHeight: '120px',
                                    }}
                                  >
                                    {/* Display the first upcoming slot in the regular Accordion Summary */}
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            flexShrink: 0,
                                            fontSize: '14px',
                                          }}
                                          onClick={() => {
                                            onSlotClickHadler(
                                              {
                                                ...firstSlot,
                                                start_date_time: moment(
                                                  formatSlotDateTime(
                                                    firstSlot.start_date_time
                                                  ),
                                                  'ddd M/DD/YY h:mmA'
                                                ).format('YYYY-MM-DDTHH:mm'),
                                                end_date_time: moment(
                                                  formatSlotDateTime(
                                                    firstSlot.end_date_time
                                                  ),
                                                  'ddd M/DD/YY h:mmA'
                                                ).format('YYYY-MM-DDTHH:mm'),
                                              },
                                              obj,
                                              title
                                            );
                                          }}
                                        >
                                          {formatSlotDateTime(
                                            firstSlot.start_date_time
                                          )}{' '}
                                          {/* Adjust the formatting based on your requirement */}
                                        </Typography>
                                        {isFirstSlotGroup && (
                                          <Typography
                                            style={{
                                              fontSize: '10px',
                                              color: 'grey',
                                            }}
                                          >
                                            {slotAgeDisplay}
                                          </Typography>
                                        )}

                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                            }}
                                          >
                                            {slotAgeInDays >= 14 && (
                                              <IconButton>
                                                <AccessTimeTwoToneIcon
                                                  style={{
                                                    color:
                                                      getSlotAgeColor(
                                                        slotAgeInDays
                                                      ),
                                                  }}
                                                />
                                              </IconButton>
                                            )}

                                            {firstSlot?.type === 'Open' &&
                                              firstSlot.note && (
                                                <IconButton
                                                  onClick={(event) =>
                                                    handleNoteClick(
                                                      event,
                                                      firstSlot.id
                                                    )
                                                  }
                                                >
                                                  <NoteAltOutlinedIcon
                                                    style={{
                                                      width: '30px',
                                                      height: '30px',
                                                      marginTop: '5px',
                                                    }}
                                                    sx={{ color: 'blue' }}
                                                  />
                                                </IconButton>
                                              )}

                                            {firstSlot?.type === 'Hold' && (
                                              <IconButton
                                                onClick={(event) =>
                                                  handleHoldClick(
                                                    event,
                                                    firstSlot.id
                                                  )
                                                }
                                              >
                                                <PanToolIcon
                                                  style={{
                                                    width: '30px',
                                                    height: '30px',
                                                    marginTop: '-3px',
                                                  }}
                                                  sx={{ color: 'red' }}
                                                />
                                              </IconButton>
                                            )}

                                            <Popover
                                              id={noteId}
                                              open={Boolean(
                                                popoverNoteAnchorEl[
                                                  firstSlot.id
                                                ]
                                              )}
                                              anchorEl={
                                                popoverNoteAnchorEl[
                                                  firstSlot.id
                                                ]
                                              }
                                              onClose={() =>
                                                handleNotePopoverClose(
                                                  firstSlot.id
                                                )
                                              }
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }}
                                              TransitionComponent={Fade}
                                              transitionDuration={350}
                                            >
                                              <Paper>
                                                <Typography sx={{ p: 4 }}>
                                                  {firstSlot.note}
                                                </Typography>
                                              </Paper>
                                            </Popover>

                                            <Popover
                                              id={holdId}
                                              open={Boolean(
                                                popoverHoldAnchorEl[
                                                  firstSlot.id
                                                ]
                                              )}
                                              anchorEl={
                                                popoverHoldAnchorEl[
                                                  firstSlot.id
                                                ]
                                              }
                                              onClose={() =>
                                                handleHoldPopoverClose(
                                                  firstSlot.id
                                                )
                                              }
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                              }}
                                              TransitionComponent={Fade}
                                              transitionDuration={350}
                                            >
                                              <Paper>
                                                <Typography sx={{ p: 4 }}>
                                                  <p>
                                                    {`Hold Created By ${getFirstCharacters(
                                                      firstSlot?.person_creating_hold
                                                    )} on ${moment(
                                                      firstSlot?.holdCreationDate
                                                    ).format('MM/DD/YY')} RE -${
                                                      firstSlot?.clientInitials
                                                    }`}
                                                  </p>
                                                  <p
                                                    style={{
                                                      'text-align': 'center',
                                                    }}
                                                  >
                                                    {firstSlot?.note}
                                                  </p>
                                                </Typography>
                                              </Paper>
                                            </Popover>
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div>
                                            {getFrequencyText(
                                              firstSlot.frequency
                                            ) && (
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                {getFrequencyText(
                                                  firstSlot.frequency
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div style={{ marginLeft: '10px' }}>
                                            {getLocationDetails(firstSlot).map(
                                              (locationDetail, index) => (
                                                <Box
                                                  key={index}
                                                  sx={{
                                                    bgcolor:
                                                      locationDetail.avatar
                                                        .color,
                                                    fontSize: '8px',
                                                    width: 18,
                                                    height: 18,
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                  }}
                                                  aria-label={
                                                    locationDetail.avatar.letter
                                                  }
                                                >
                                                  {locationDetail.avatar.letter}
                                                </Box>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      height:
                                        expanded === `group${groupIndex}`
                                          ? 'auto'
                                          : '40px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {/* Display the remaining slots in the Accordion Details */}
                                    {slotGroup
                                      .slice(1)
                                      .map((slot, slotIndex) => (
                                        <div
                                          key={`slot${slotIndex}`}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            minHeight: '80px',
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              flexShrink: 0,
                                              fontSize: '14px',
                                              height: '30px',
                                            }}
                                            onClick={async () => {
                                              try {
                                                // Correctly reference the function passed as a prop
                                                const providerDetails =
                                                  await getProviderDetail(
                                                    obj.id,
                                                    obj.email
                                                  );
                                                if (
                                                  providerDetails &&
                                                  providerDetails.data
                                                ) {
                                                  console.log(
                                                    `License and Degree Information for ${obj.username}:`,
                                                    providerDetails.data
                                                      .provider
                                                      .degrees_and_license
                                                  );
                                                }
                                              } catch (error) {
                                                console.error(
                                                  'Failed to fetch provider details',
                                                  error
                                                );
                                              }
                                              onSlotClickHadler(
                                                {
                                                  ...slot,
                                                  start_date_time: moment(
                                                    formatSlotDateTime(
                                                      slot.start_date_time
                                                    ),
                                                    'ddd M/DD/YY h:mmA'
                                                  ).format('YYYY-MM-DDTHH:mm'),
                                                  end_date_time: moment(
                                                    formatSlotDateTime(
                                                      slot.end_date_time
                                                    ),
                                                    'ddd M/DD/YY h:mmA'
                                                  ).format('YYYY-MM-DDTHH:mm'),
                                                },
                                                obj,
                                                title
                                              );
                                            }}
                                          >
                                            <span style={{ cursor: 'pointer' }}>
                                              {formatSlotDateTime(
                                                slot.start_date_time
                                              )}{' '}
                                            </span>{' '}
                                            {/* Adjust the formatting based on your requirement */}
                                          </Typography>
                                        </div>
                                      ))}
                                    {/* Add any additional slot details here */}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            }
                          );
                        })}
                      </CardContent>
                    </Collapse>
                  </Card>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      );
    } else {
      return false;
    }
  } else {
    // eslint-disable-next-line no-unused-expressions
    return (
      <div>
        <Row className="g-2">
          <Col xs={12}>
            {getProviderYesMaybeText(obj)?.provider?.therapy
              ?.providerPriorityScheduling && (
              <div className="priorityRibbon">Priority</div>
            )}
            <div style={{ width: '100%' }}>
              <Card
                style={{
                  width: '100%',
                  backgroundColor: '#f5f5f5',
                  minHeight: '125px',
                }}
              >
                <CardHeader
                  style={{ height: '50px' }}
                  avatar={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: -10,
                        marginLeft: -25,
                      }}
                    >
                      {!isManual &&
                        uniqueLocationDetailsArray?.map((locationDetails) => (
                          <Col xs={3} key={locationDetails.avatar.letter}>
                            <Box
                              sx={{
                                bgcolor: locationDetails.avatar.color,
                                fontSize: '12px',
                                width: 26,
                                height: 26,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              aria-label={provider.name}
                            >
                              {locationDetails.avatar.letter}
                            </Box>
                          </Col>
                        ))}
                      {renderStateBadges()} {/* This line renders the badges */}
                    </div>
                  }
                  action={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingTop: -10,
                        paddingRight: 1,
                      }}
                    >
                      {!isManual &&
                        uniqueFrequencies?.map((frequency) => {
                          let frequencyDetails = getFrequencyAvatar(frequency);
                          return (
                            <Box
                              key={frequencyDetails.avatar.letter}
                              sx={{
                                bgcolor: frequencyDetails.avatar.color,
                                fontSize: '11px',
                                width: 26,
                                height: 26,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              aria-label={frequency}
                            >
                              {frequencyDetails.avatar.letter}
                            </Box>
                          );
                        })}
                    </div>
                  }
                />

                <div style={{ marginTop: '-10px', textAlign: 'center' }}>
                  <ThemeProvider theme={theme}>
                    <Typography variant="body" style={{ fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                  </ThemeProvider>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <CardMedia
                    component="img"
                    height="194"
                    image={image}
                    alt="Provider img"
                  />
                </div>
                <CardActions disableSpacing style={{ marginTop: '-10px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      marginTop: '5px',
                    }}
                  >
                    <div style={{ alignItems: 'center' }}>
                      {isManual && (
                        <Row
                          style={{
                            color: 'red',
                            height: '28px',
                            cursor: 'pointer',
                            marginTop: '10px',
                            paddingLeft: '10px',
                            marginBottom: '10px',
                            paddingRight: '10px',

                            width: '100%',
                          }}
                          onClick={async (e) => {
                            onWithoutCalenderClickHandler(obj, title);
                          }}
                        >
                          {!(
                            entryTypeFormValues.entryType === 'existing' &&
                            entryTypeFormValues.existingClientOptions ===
                              'Turning 18 years-Old'
                          ) ? (
                            'Schedule appointment without Calendar availability'
                          ) : (
                            <div>
                              <Button>Select</Button>
                            </div>
                          )}
                        </Row>
                      )}

                      {getProviderYesMaybeText &&
                        obj &&
                        getProviderYesMaybeText(obj).out.length > 0 && (
                          <Button
                            variant="contained"
                            onClick={handleClick}
                            style={{
                              height: '20px',
                              width: '100%',
                              margin: '20px auto 0',
                            }}
                          >
                            Service Alert
                          </Button>
                        )}

                      <Popover
                        id={popoverId}
                        open={popoverOpen}
                        anchorEl={popoverAnchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        TransitionComponent={Fade}
                        transitionDuration={350}
                      >
                        <Paper>
                          <Typography sx={{ p: 4 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  getProviderYesMaybeText &&
                                  obj &&
                                  getProviderYesMaybeText(obj).out,
                              }}
                            ></div>
                          </Typography>
                        </Paper>
                      </Popover>
                    </div>
                  </div>
                </CardActions>

                <CardActions>
                  {!isManual && numberOfUniqueOpens > 0 && (
                    <Badge
                      sx={{
                        '.MuiBadge-badge': {
                          bgcolor: 'green',
                          color: 'white',
                          marginTop: '7px',
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      badgeContent={numberOfUniqueOpens}
                    >
                      <IconButton>
                        <EventAvailableIcon
                          style={{ width: '22px', height: '22px' }}
                          set
                          your
                          desired
                          dimensions
                          here
                          sx={{ color: 'green' }}
                        />
                      </IconButton>
                    </Badge>
                  )}

                  <Box
                    sx={{
                      color: 'action.active',
                      display: 'flex',
                      flexDirection: 'column',
                      '& > *': {
                        marginBottom: '2px',
                      },
                      '& .MuiBadge-root': {
                        marginRight: 4,
                        marginTop: '5px', // Adjust the marginTop value to move the Badge further down
                      },
                    }}
                  >
                    {!isManual && numberOfUniqueHolds > 0 && (
                      <Badge
                        badgeContent={numberOfUniqueHolds}
                        color="error"
                        sx={{ '& .MuiBadge-badge': { marginTop: '5px' } }}
                      >
                        <IconButton>
                          <PanToolIcon
                            style={{ width: '18px', height: '18px' }}
                            sx={{ color: 'red' }}
                          />
                        </IconButton>
                      </Badge>
                    )}
                  </Box>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    {isTopIssuesIncludeInSevice('issue3') && (
                      <span
                        style={{ fontSize: '2em' }}
                        role="img"
                        aria-label="Emoji here"
                      >
                        🥉
                      </span>
                    )}
                    {isTopIssuesIncludeInSevice('issue2') && (
                      <span
                        style={{ fontSize: '2em' }}
                        role="img"
                        aria-label="Emoji here"
                      >
                        🥈
                      </span>
                    )}
                    {isTopIssuesIncludeInSevice('issue1') && (
                      <span
                        style={{ fontSize: '2em' }}
                        role="img"
                        aria-label="Emoji here"
                      >
                        🥇
                      </span>
                    )}
                  </div>

                  {!isManual && !Object.values(groupedSlots).length === 0 && (
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </CardActions>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent style={{ width: '100%' }}>
                    {Object.values(groupedSlots).map(
                      (slotGroup, groupIndex) => {
                        const firstSlot = slotGroup[0];
                        const isFirstSlotGroup = true;

                        // const isFirstSlotGroup =
                        //   groupIndex === 0 || firstSlot?.type === 'Hold';
                        // const isFirstSlotGroup = true;

                        // Calculate the slot age in days
                        const createdAt = moment(firstSlot.createdAt);
                        const now = moment();
                        const slotAgeInDays = now.diff(createdAt, 'days');

                        // Decide the display text based on the slot age
                        let slotAgeDisplay;
                        if (slotAgeInDays < 1) {
                          slotAgeDisplay = 'Brand New - < 1 day';
                        } else {
                          slotAgeDisplay = `(${slotAgeInDays} days-old)`;
                        }
                        return (
                          <Accordion
                            key={`group${groupIndex}`}
                            expanded={expanded === `group${groupIndex}`}
                            style={{ width: '100%' }}
                          >
                            <AccordionSummary
                              expandIcon={
                                slotGroup.length > 1 && (
                                  <ExpandMoreIcon
                                    onClick={() =>
                                      handleChange(
                                        `group${groupIndex}`,
                                        expanded
                                      )
                                    }
                                  />
                                )
                              }
                              aria-controls={`group${groupIndex}-content`}
                              id={`group${groupIndex}-header`}
                              style={{
                                width: '100%',
                                minHeight: '120px',
                              }}
                            >
                              {/* Display the first upcoming slot in the regular Accordion Summary */}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <Typography
                                    sx={{ flexShrink: 0, fontSize: '14px' }}
                                    onClick={() => {
                                      onSlotClickHadler(
                                        {
                                          ...slotGroup[groupIndex],
                                          start_date_time: moment(
                                            formatSlotDateTime(
                                              slotGroup[groupIndex]
                                                .start_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                          end_date_time: moment(
                                            formatSlotDateTime(
                                              slotGroup[groupIndex]
                                                .end_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                        },
                                        obj,
                                        title
                                      );
                                    }}
                                  >
                                    {formatSlotDateTime(
                                      firstSlot.start_date_time
                                    )}{' '}
                                    {/* Adjust the formatting based on your requirement */}
                                  </Typography>
                                  {isFirstSlotGroup && (
                                    <Typography
                                      style={{
                                        fontSize: '10px',
                                        color: 'grey',
                                      }}
                                    >
                                      {slotAgeDisplay}
                                    </Typography>
                                  )}

                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {firstSlot?.type === 'Open' &&
                                      firstSlot.note && (
                                        <IconButton
                                          onClick={(event) =>
                                            handleNoteClick(event, firstSlot.id)
                                          }
                                        >
                                          <NoteAltOutlinedIcon
                                            style={{
                                              width: '30px',
                                              height: '30px',
                                              marginTop: '5px',
                                            }}
                                            sx={{ color: 'blue' }}
                                          />
                                        </IconButton>
                                      )}

                                    {firstSlot?.type === 'Hold' && (
                                      <IconButton
                                        onClick={(event) =>
                                          handleHoldClick(event, firstSlot.id)
                                        }
                                      >
                                        <PanToolIcon
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            marginTop: '-3px',
                                          }}
                                          sx={{ color: 'red' }}
                                        />
                                      </IconButton>
                                    )}

                                    <Popover
                                      id={noteId}
                                      open={Boolean(
                                        popoverNoteAnchorEl[firstSlot.id]
                                      )}
                                      anchorEl={
                                        popoverNoteAnchorEl[firstSlot.id]
                                      }
                                      onClose={() =>
                                        handleNotePopoverClose(firstSlot.id)
                                      }
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      TransitionComponent={Fade}
                                      transitionDuration={350}
                                    >
                                      <Paper>
                                        <Typography sx={{ p: 4 }}>
                                          {firstSlot.note}
                                        </Typography>
                                      </Paper>
                                    </Popover>

                                    <Popover
                                      id={holdId}
                                      open={Boolean(
                                        popoverHoldAnchorEl[firstSlot.id]
                                      )}
                                      anchorEl={
                                        popoverHoldAnchorEl[firstSlot.id]
                                      }
                                      onClose={() =>
                                        handleHoldPopoverClose(firstSlot.id)
                                      }
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                      TransitionComponent={Fade}
                                      transitionDuration={350}
                                    >
                                      <Paper>
                                        <Typography sx={{ p: 4 }}>
                                          {firstSlot.note}
                                        </Typography>
                                      </Paper>
                                    </Popover>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div>
                                    {getFrequencyText(firstSlot.frequency) && (
                                      <span style={{ fontWeight: 'bold' }}>
                                        {getFrequencyText(firstSlot.frequency)}
                                      </span>
                                    )}
                                  </div>
                                  <div style={{ marginLeft: '10px' }}>
                                    {getLocationDetails(firstSlot).map(
                                      (locationDetail, index) => (
                                        <Box
                                          key={index}
                                          sx={{
                                            bgcolor:
                                              locationDetail.avatar.color,
                                            fontSize: '8px',
                                            width: 18,
                                            height: 18,
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                          aria-label={
                                            locationDetail.avatar.letter
                                          }
                                        >
                                          {locationDetail.avatar.letter}
                                        </Box>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height:
                                  expanded === `group${groupIndex}`
                                    ? 'auto'
                                    : '40px',
                                overflow: 'hidden',
                              }}
                            >
                              {/* Display the remaining slots in the Accordion Details */}
                              {slotGroup.slice(1).map((slot, slotIndex) => (
                                <div
                                  key={`slot${slotIndex}`}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: '80px',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      flexShrink: 0,
                                      fontSize: '14px',
                                      height: '30px',
                                    }}
                                    onClick={() => {
                                      onSlotClickHadler(
                                        {
                                          ...slot,
                                          start_date_time: moment(
                                            formatSlotDateTime(
                                              slot.start_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                          end_date_time: moment(
                                            formatSlotDateTime(
                                              slot.end_date_time
                                            ),
                                            'ddd M/DD/YY h:mmA'
                                          ).format('YYYY-MM-DDTHH:mm'),
                                        },
                                        obj,
                                        title
                                      );
                                    }}
                                  >
                                    <span style={{ cursor: 'pointer' }}>
                                      {formatSlotDateTime(slot.start_date_time)}{' '}
                                    </span>{' '}
                                    {/* Adjust the formatting based on your requirement */}
                                  </Typography>
                                </div>
                              ))}
                              {/* Add any additional slot details here */}
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    )}
                  </CardContent>
                </Collapse>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
