import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;
// const API_URL = "http://localhost:8080/api/";
// const API_URL_USER = "http://localhost:8080/api/";

const getPublicContent = () => {
  return axios.get(API_URL + 'all');
};

const getUserBoard = () => {
  return axios.get(API_URL + 'user', { headers: authHeader() });
};
// *** can delete this
const getModeratorBoard = () => {
  return axios.get(API_URL + 'mod', { headers: authHeader() });
};
// *** can delete this
const getAdminBoard = () => {
  return axios.get(API_URL + 'admin', { headers: authHeader() });
};

const getUsers = () => {
  return axios.get(API_URL + 'users', { headers: authHeader() });
};

const getAllUsers = () => {
  return axios.get(API_URL + 'users/all', { headers: authHeader() }); // Updated to use the new endpoint
};

const getUserByEmail = (email) => {
  // eslint-disable-next-line no-useless-concat
  return axios.get(API_URL + 'users' + `/${email}`, { headers: authHeader() });
};

const getDoctors = () =>
  axios.get(`${API_URL}doctors`, { headers: authHeader() });
const getDoctor = (id) =>
  axios.get(`${API_URL}doctors/${id}`, { headers: authHeader() });
// const getAppointments = id => axios.get(`${API_URL}users/${id}/appointments`, { headers: authHeader() });
const getAppointment = (userId, appointmentId) =>
  axios.get(`${API_URL}users/${userId}/appointments/${appointmentId}`, {
    headers: authHeader(),
  });
const postAppointment = (userId, doctorId, appointmentDate) =>
  axios.post(
    `${API_URL}users/${userId}/appointments`,
    { doctor_id: doctorId, appointment_date: appointmentDate },
    { headers: authHeader() }
  );
const deleteAppointment = (userId, appointmentId) =>
  axios.delete(
    `${API_URL}api/v1/users/${userId}/appointments/${appointmentId}`,
    { headers: authHeader() }
  );

const getProviders = (status = 'active') =>
  axios.get(`${API_URL}providers?status=${status}`, { headers: authHeader() });

const getAdmins = (status = 'active') =>
  axios.get(`${API_URL}admins?status=${status}`, { headers: authHeader() });

const getCareCoordinators = (status = 'active') =>
  axios.get(`${API_URL}careCoordinators?status=${status}`, {
    headers: authHeader(),
  });

const getProviderDetail = (id, email) =>
  axios.get(`${API_URL}provider/${id}?email=${email}`, {
    headers: authHeader(),
  });

const getAdminDetail = (id, email) =>
  axios.get(`${API_URL}admin/${id}?email=${email}`, {
    headers: authHeader(),
  });

const getMalpracticeInsuranceDetails = (providerId) => {
  return axios.get(`${API_URL}provider/${providerId}/malpractice-insurance`, {
    headers: authHeader(),
  });
};

const getCareCoordinatorDetail = (id, email) =>
  axios.get(`${API_URL}careCoordinator/${id}?email=${email}`, {
    headers: authHeader(),
  });

const updateProviderDetails = (id, body) =>
  axios.post(`${API_URL}provider/${id}`, body, { headers: authHeader() });

const updateAdminDetails = (id, body) =>
  axios.post(`${API_URL}admin/${id}`, body, { headers: authHeader() });

const updateCareCoordinatorDetails = (id, body) =>
  axios.post(`${API_URL}careCoordinator/${id}`, body, {
    headers: authHeader(),
  });

const updateTherapyConfig = (id, body) =>
  axios.post(`${API_URL}updateTherapyConfig/${id}`, body, {
    headers: authHeader(),
  });
const updateTestingConfig = (id, body) =>
  axios.post(`${API_URL}updateTestingConfig/${id}`, body, {
    headers: authHeader(),
  });
const updateLastAttestation = (id) =>
  axios.post(
    `${API_URL}updateLastAttestation/${id}`,
    {},
    { headers: authHeader() }
  );

const updateLastRosterSubmission = (id, body) =>
  axios.post(`${API_URL}updateLastRosterSubmission/${id}`, body, {
    headers: authHeader(),
  });

const getLastRosterSubmission = (providerId) => {
  return axios.get(`${API_URL}getLastRosterSubmission/${providerId}`, {
    headers: authHeader(),
  });
};

const getMarylandLicenseExpiration = (providerId) => {
  return axios.get(`${API_URL}getMarylandLicenseExpiration/${providerId}`, {
    headers: authHeader(), // This function should return the necessary authorization headers, e.g., with a JWT token
  });
};

const getOutofStateLicenseExpiration = (providerId) => {
  return axios.get(`${API_URL}getOutofStateLicenseExpiration/${providerId}`, {
    headers: authHeader(),
  });
};

const getPsyPactLicenseExpiration = (providerId) => {
  return axios.get(`${API_URL}getPsyPactLicenseExpiration/${providerId}`, {
    headers: authHeader(),
  });
};

const getCurrentLiabilityInsuranceExpiration = (providerId) => {
  return axios.get(
    `${API_URL}getCurrentLiabilityInsuranceExpiration/${providerId}`,
    {
      headers: authHeader(),
    }
  );
};

const getTherapyConfig = (id) =>
  axios.get(`${API_URL}therapyConfig/${id}`, { headers: authHeader() });

const getDIPStatus = (body) =>
  axios.post(`${API_URL}clients/DIPStatus`, body, {
    headers: authHeader(),
  });

const createUser = (body) =>
  axios.post(`${API_URL}users`, body, { headers: authHeader() });
const updateUser = (body) =>
  axios.put(`${API_URL}users`, body, { headers: authHeader() });
const deleteUser = (body) =>
  axios.post(`${API_URL}delete_user`, body, { headers: authHeader() });
const getTherapyConfigOptions = () => {
  return axios.get(`${API_URL}therapy_config_options`, {
    headers: authHeader(),
  });
};
const getAppointments = (id) =>
  axios.get(`${API_URL}appointments`, { headers: authHeader() });

const getClients = (
  includeInactive = false,
  page,
  rowsPerPage,
  clientStatus = true,
  searchAPICall = false
) => {
  const queryParams = [
    `page=${page}`,
    `rowsPerPage=${rowsPerPage}`,
    `clientStatus=${clientStatus}`,
    `searchAPICall=${searchAPICall}`,
  ];

  if (includeInactive) {
    queryParams.push('includeInactive=true');
  }

  const queryString = queryParams.join('&');
  const url = `${API_URL}clients?${queryString}`;

  return axios.get(url, { headers: authHeader() });
};

const getMyClients = () =>
  axios.get(`${API_URL}clients`, { headers: authHeader() });

const getClientsByProvider = (providerId) =>
  axios.get(`${API_URL}clients/provider/${providerId}`, {
    headers: authHeader(),
  });

const getClientsOfSupervisees = () => {
  return axios.get(API_URL + 'clients/supervisees', { headers: authHeader() });
};

const getAppointmentPreview = (id) =>
  axios.get(`${API_URL}getAppointmentPreview/${id}`, { headers: authHeader() });
const createAppointment = (body) =>
  axios.post(`${API_URL}appointments`, body, { headers: authHeader() });

const getProviderAppts = (
  step03FormValues,
  step05FormValues,
  step06FormValues
) =>
  axios.post(
    `${API_URL}getProviderAppts`,
    { step03FormValues, step05FormValues, step06FormValues },
    { headers: authHeader() }
  );

const addProviderAvailability = (id, body) =>
  axios.post(`${API_URL}addProviderAvailability/${id}`, body, {
    headers: authHeader(),
  });
const updateAppointment = (body) =>
  axios.post(`${API_URL}updateAppointment`, body, { headers: authHeader() });
const cancelAppointment = (body) =>
  axios.post(`${API_URL}cancelAppointment`, body, { headers: authHeader() });
const deleteEvent = (id, body) =>
  axios.post(`${API_URL}deleteEvent/${id}`, body, { headers: authHeader() });
const updateEvent = (id, body) =>
  axios.post(`${API_URL}updateEvent/${id}`, body, { headers: authHeader() });
const getProviderAvailabilityAndSlots = (id) =>
  axios.get(`${API_URL}getProviderAvailabilityAndSlots/${id}`, {
    headers: authHeader(),
  });

const updateClientStatus = (clientIds, newStatus) => {
  return axios.post(
    API_URL + 'updateClientStatus',
    { clientIds, newStatus },
    { headers: authHeader() }
  );
};

const updateClientColumns = (clientUpdates) => {
  return axios.put(`${API_URL}clients/updateClientColumns`, clientUpdates, {
    headers: authHeader(),
  });
};

const addAdminNameToClient = (clientUpdates) => {
  return axios.post(`${API_URL}clients/addAdminName`, clientUpdates, {
    headers: authHeader(),
  });
};

const addClientToStillAwol = (clientUpdates) => {
  return axios.post(`${API_URL}clients/addClientToStillAwol`, clientUpdates, {
    headers: authHeader(),
  });
};

const addClientToReturned = (clientUpdates) => {
  return axios.post(`${API_URL}clients/addClientToReturned`, clientUpdates, {
    headers: authHeader(),
  });
};

const cancelSurvey = (providerAvailabilityId) => {
  return axios.post(
    API_URL + 'cancelSurvey',
    { providerAvailabilityId },
    { headers: authHeader() }
  );
};

const createDockHealthTasks = (taskDetails) => {
  return axios.post(API_URL + 'dockHealth/createTask', taskDetails, {
    headers: authHeader(),
  });
};

const searchDockHealthTasks = (phone) =>
  axios.get(`${API_URL}searchDockHealthTasks/?phone=${phone}`, {
    headers: authHeader(),
  });
const getIncompleteDockHealthTasks = () => {
  const headers = authHeader();

  // Make request to your server-side API, not directly to DockHealth.
  return axios.get(`${API_URL}getIncompleteDockHealthTasks`, {
    headers: headers,
  });
};

const fetchClientsWithCriteria = (providerId, status = [], insurances = []) => {
  // Initialize URLSearchParams to handle query parameters
  let params = new URLSearchParams();
  // Check if a specific provider is selected and not "ALL"
  if (providerId && providerId !== '1001') {
    // Assuming '1001' represents "ALL"
    params.append('providerId', providerId);
  }
  // Handle status if provided; assuming status is an array
  status.forEach((s) => params.append('status', s));
  // Handle insurances if provided; assuming insurances is an array
  insurances.forEach((insurance) => params.append('insurance', insurance));
  // Construct the full URL with query parameters
  const fullUrl = `${API_URL}clients/criteria?${params.toString()}`;
  // Make the GET request with axios
  return axios.get(fullUrl, { headers: authHeader() });
};

const updateDriveDetail = (body) =>
  axios.post(`${API_URL}updateDriveDetails`, body, { headers: authHeader() });

const getDriveDetail = (body) => {
  const headers = authHeader();

  return axios.post(`${API_URL}getDriveDetails/${body?.provider_id}`, body, {
    headers: headers,
  });
};

const getProvidersWithServiceType = (serviceType) =>
  axios.get(`${API_URL}providers/${serviceType}`);

// userId is the id of the user for which I am checking here
// loginUserId is the id of the user login
const isSelectedUserSupervisee = async (userId) => {
  return await axios.get(`${API_URL}users/checkUserIsSupervisee/${userId}`, {
    headers: authHeader(),
  });
};

const updateUserImage = (userId, imageUrl) => {
  return axios.put(
    `${API_URL}users/updateImage`,
    { userId, imageUrl },
    { headers: authHeader() }
  );
};

// Function to get all admins with optional status filter
const getAllAdmins = (status) => {
  // Construct query parameters based on status
  const params = status ? { status } : {};

  return axios.get(`${API_URL}admins`, {
    headers: authHeader(),
    params,
  });
};

const getAllCareCoordinators = (status) => {
  const params = status ? { status } : {};

  return axios.get(`${API_URL}careCoordinators`, {
    headers: authHeader(),
    params,
  });
};

const getAllProviders = () =>
  axios.get(`${API_URL}users/providers/getAllProviders`, {
    headers: authHeader(),
  });

const getProspectiveClientDetails = (uniqueId) =>
  axios.get(`${API_URL}getProspectiveClientsDetails/${uniqueId}`);

const updateSentQuestionnaireDate = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateSentQuestionnaireDate/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const updateClientCompleteQuestionnaire = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateClientCompleteQuestionnaire/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const updateDiagnosisStatus = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateDiagnosisStatus/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const updateClientScore = (selectedClientUniqueKey, body) => {
  return axios.post(
    `${API_URL}clients/updateClientScore/${selectedClientUniqueKey}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const verifyUserActivity = (body) => {
  return axios.post(`${API_URL}users/getUserActivityStatus`, body, {
    headers: authHeader(),
  });
};

const getDIPAllClients = () => {
  return axios.get(`${API_URL}clients/getAllDIPClients`, {
    headers: authHeader(),
  });
};

const sendApprovalEmail = (body) => {
  return axios.post(`${API_URL}providers/sendApprovalEmail`, body, {
    headers: authHeader(),
  });
};

const updateCredentialingTaskCreatedForLiability = (body) => {
  return axios.post(
    `${API_URL}providers/updateCredentialingTaskCreatedForLiability`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const getTeamMembers = (body) => {
  return axios.post(`${API_URL}users/getTeamMembers`, body, {
    headers: authHeader(),
  });
};
// eslint-disable-next-line
export default {
  getTeamMembers,
  getDIPAllClients,
  verifyUserActivity,
  getDoctors,
  getProspectiveClientDetails,
  getDoctor,
  getDIPStatus,
  getAppointments,
  sendApprovalEmail,
  getAppointment,
  getPublicContent,
  postAppointment,
  deleteAppointment,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getProviders,
  getAllUsers,
  getUsers,
  getAllProviders,
  getAllCareCoordinators,
  getUserByEmail,
  getProviderDetail,
  getCareCoordinatorDetail,
  updateProviderDetails,
  updateCareCoordinatorDetails,
  getTherapyConfigOptions,
  createUser,
  updateUser,
  addAdminNameToClient,
  deleteUser,
  updateTherapyConfig,
  updateLastAttestation,
  getTherapyConfig,
  updateTestingConfig,
  getProviderAppts,
  addProviderAvailability,
  getProviderAvailabilityAndSlots,
  searchDockHealthTasks,
  getIncompleteDockHealthTasks,
  deleteEvent,
  updateEvent,
  createAppointment,
  getAppointmentPreview,
  updateAppointment,
  cancelAppointment,
  updateDriveDetail,
  getDriveDetail,
  getClients,
  getAdmins,
  getCareCoordinators,
  updateAdminDetails,
  getAdminDetail,
  getMyClients,
  getClientsByProvider,
  getClientsOfSupervisees,
  getLastRosterSubmission,
  updateLastRosterSubmission,
  updateClientStatus,
  addClientToStillAwol,
  addClientToReturned,
  updateClientColumns,
  createDockHealthTasks,
  getProvidersWithServiceType,
  updateUserImage,
  getAllAdmins,
  cancelSurvey,
  fetchClientsWithCriteria,
  updateSentQuestionnaireDate,
  updateClientCompleteQuestionnaire,
  updateClientScore,
  updateDiagnosisStatus,
  updateCredentialingTaskCreatedForLiability,
  isSelectedUserSupervisee,
  getMalpracticeInsuranceDetails,
  getMarylandLicenseExpiration,
  getOutofStateLicenseExpiration,
  getPsyPactLicenseExpiration,
  getCurrentLiabilityInsuranceExpiration,
};
