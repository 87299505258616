import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { memo, useRef } from 'react';
import { useSnackbar } from 'notistack';
import React from 'react';

const TestingConfigDialog = ({
  open,
  handleClose,
  setReason,
  reason,
  setFormValues,
  selectedField,
  formValues,
}) => {
  const inputRef = useRef(); // This Ref has been created for performance and to not allowing re-rendering
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose}>
      <DialogTitle>Note</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To opt to this option, please enter qualifying criteria here.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="note"
          label="Note"
          // type="note"
          fullWidth
          variant="standard"
          ref={inputRef}
          onChange={(event) => (inputRef.current.text = event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>

        <Button
          onClick={(event) => {
            if (
              inputRef?.current?.text &&
              inputRef?.current?.text?.length > 0
            ) {
              const fieldName = `${selectedField}_reason`;
              setFormValues({
                ...formValues,
                [fieldName]: inputRef.current.text,
              });
              handleClose(true);
            } else {
              enqueueSnackbar('Please fill out the reason', {
                variant: 'error',
                timeout: 2000,
              });
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(TestingConfigDialog);
