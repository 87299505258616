import React, { memo } from 'react';
import * as MUIv5 from '@mui/material';

const SnackBarSchedulingAlert = ({
  alertOpen,
  alertMessage,
  handleAlertClose,
}) => {
  return (
    <MUIv5.Snackbar open={alertOpen} onClose={handleAlertClose}>
      <MUIv5.Alert
        elevation={6}
        variant="filled"
        onClose={handleAlertClose}
        severity="error"
      >
        {alertMessage}
      </MUIv5.Alert>
    </MUIv5.Snackbar>
  );
};
export default memo(SnackBarSchedulingAlert);
