import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/calendar/`;

const listEvents = () => {
  return axios.get(API_URL + 'events', { headers: authHeader() });
};

const createEvent = (eventData) => {
  return axios.post(API_URL + 'createEvent', eventData, {
    headers: authHeader(),
  });
};

const deleteEvent = (eventId) => {
  return axios.delete(API_URL + `deleteEvent/${eventId}`, {
    headers: authHeader(),
  });
};

const updateEvent = (eventId, eventData) => {
  return axios.put(API_URL + `updateEvent/${eventId}`, eventData, {
    headers: authHeader(),
  });
};

// New method to find events by name

const findEventByName = (eventName) => {
  return axios
    .get(API_URL + 'events', {
      headers: authHeader(),
      params: { name: eventName },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error fetching events:', error);
      throw error;
    });
};

const findLicenseEventByName = (eventName) => {
  return axios
    .get(API_URL + 'events/license', {
      headers: authHeader(),
      params: { name: eventName },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error fetching events:', error);
      throw error;
    });
};

const deleteLicenseEvent = (eventId) => {
  return axios.delete(API_URL + `deleteEvent/license/${eventId}`, {
    headers: authHeader(),
  });
};

const createLicenseEvent = (eventData) => {
  return axios.post(API_URL + 'createEvent/license', eventData, {
    headers: authHeader(),
  });
};

const findAnniversaryEventByName = (eventName) => {
  return axios
    .get(API_URL + 'events/anniversary', {
      headers: authHeader(),
      params: { name: eventName },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error fetching events:', error);
      throw error;
    });
};

const deleteAnniversaryEvent = (eventId) => {
  return axios.delete(API_URL + `deleteEvent/anniversary/${eventId}`, {
    headers: authHeader(),
  });
};

const createAnniversaryEvent = (eventData) => {
  return axios.post(API_URL + 'createEvent/anniversary', eventData, {
    headers: authHeader(),
  });
};

// eslint-disable-next-line
export default {
  listEvents,
  createEvent,
  deleteEvent,
  updateEvent,
  findEventByName,
  // The below events are for License
  findLicenseEventByName,
  deleteLicenseEvent,
  createLicenseEvent,
  // The below events for Anniversary
  findAnniversaryEventByName,
  deleteAnniversaryEvent,
  createAnniversaryEvent,
};
