/* eslint-disable */
import { SAVE_FORM_DATA, CLEAR_FORM_DATA } from '../actions/types';

const initialState = {
  behaviorIssues: {
    tantrums: false,
    aggression: false,
    disruptiveBehaviors: false,
    toiletTrainingIssues: false,
    mildSelfInjury: false,
    elopement: false,
    sleepIssues: false,
    refusalToFollowInstructions: false,
    none: false,
  },
  exclusions: {
    complexMedicalConditions: false,
    significantSensoryImpairments: false,
    schoolIssuesFocus: false,
    depressionOrAnxietyFocus: false,
    none: false,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SAVE_FORM_DATA:
      return { ...state, formData: payload };
    case CLEAR_FORM_DATA:
      return {};

    default:
      return state;
  }
}
