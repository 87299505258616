import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import user from './user';
import loading from './loading';
import notification from './notification';
import clients from './clients';
import scheduling from './scheduling';
// The below is use to make the loader light
import light from './light';

export default combineReducers({
  auth,
  message,
  user,
  loading,
  notification,
  clients,
  scheduling,
  light,
});
