import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

const CustomTick = (props) => {
  const { x, y, payload } = props;

  const parenthIndex = payload.value.indexOf('(');
  const firstLine = payload.value.substring(0, parenthIndex);
  const secondLine = payload.value.substring(parenthIndex);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} textAnchor="middle" fill="#666">
        <tspan x={0} y="20">
          {firstLine}
        </tspan>{' '}
        {/* y is set to absolute position */}
        <tspan x={0} y="35">
          {secondLine}
        </tspan>{' '}
        {/* y is set to absolute position */}
      </text>
    </g>
  );
};

function BarCharts({
  firstBarColor,
  secondBarColor,
  thirdBarColor,
  forthBarColor,
  data,
  CustomTooltip,
  secondBar,
  thirdBar,
  forthBar,
  yAxisLabel,
  barWidth,
}) {
  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#ff80ed',
    '#065535',
    '#ffd700',
    '#ff7373',
    '#003366',
    '#8a2be2',
    '#800000',
    '#008000',
    '#ccff00',
    '#794044',
    '#cc0000',
    '#b4eeb4',
    '#800080',
  ];
  const calculatedWidth = (data?.length || 0) * (barWidth || 200); // calculate the width based on the data length and bar width

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <BarChart
        width={calculatedWidth} // use calculatedWidth here
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 20,
        }}
        barGap={-40} // add this line
        barCategoryGap={10} // and this line
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis
          dataKey="name"
          padding={{ left: 30, right: 30 }}
          tick={<CustomTick />}
        />
        <YAxis />
        <Tooltip content={CustomTooltip || null} />
        {/* <Legend /> */}
        <Bar dataKey="value" barSize={barWidth} fill="#8884d8">
          {yAxisLabel}
          {data?.length > 0 &&
            data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  firstBarColor ? firstBarColor : COLORS[index % COLORS.length]
                }
              />
            ))}
        </Bar>

        {secondBar && (
          <Bar dataKey="value1" barSize={barWidth} fill="#8884d8">
            {data?.length > 0 &&
              data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    secondBarColor
                      ? secondBarColor
                      : COLORS[index % COLORS.length]
                  }
                />
              ))}
          </Bar>
        )}

        {thirdBar && (
          <Bar dataKey="value2" barSize={barWidth} fill="#8884d8">
            {data?.length > 0 &&
              data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    thirdBarColor
                      ? thirdBarColor
                      : COLORS[index % COLORS.length]
                  }
                />
              ))}
          </Bar>
        )}
        {forthBar && (
          <Bar dataKey="value3" barSize={barWidth} fill="#8884d8">
            {data?.length > 0 &&
              data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    forthBarColor
                      ? forthBarColor
                      : COLORS[index % COLORS.length]
                  }
                />
              ))}
          </Bar>
        )}
      </BarChart>
    </div>
  );
}

export default BarCharts;
