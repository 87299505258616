import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/documents/`;

// Function to get all documents
const getAllDocuments = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

// Function to get a specific document by ID
const getDocument = (id) => {
  return axios.get(API_URL + id, { headers: authHeader() });
};

// Function to search documents by name
const searchDocuments = (name) => {
  return axios.get(API_URL + 'search', {
    params: { name },
    headers: authHeader(),
  });
};

// Function to create a new document
const createDocument = (docData) => {
  return axios.post(API_URL, docData, { headers: authHeader() });
};

// Function to update an existing document
const updateDocument = (id, docData) => {
  return axios.put(API_URL + id, docData, { headers: authHeader() });
};

// Function to delete a document
const deleteDocument = (id) => {
  return axios.delete(API_URL + id, { headers: authHeader() });
};

// eslint-disable-next-line
export default {
  getAllDocuments,
  getDocument,
  searchDocuments,
  createDocument,
  updateDocument,
  deleteDocument,
};
