import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import MuiDrawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import { Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import GroupIcon from '@mui/icons-material/Group';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import InboxIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MailIcon from '@mui/icons-material/Mail';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SpeedIcon from '@mui/icons-material/Speed';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box';
import { Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableChartIcon from '@mui/icons-material/TableChart';
import { logout } from '../actions/auth';
import { clearFormData } from '../actions/scheduling';
import UserManagement from './UserManagement';
import Logs from './Logs';
import Dashboard from './Dashboard';
import UpdateAvailability from './Availability/UpdateAvailability';
import Notifications from './Notifications';
import PersonalInfo from './PersonalInfo';
import AdminConfigHome from './Admin/AdminConfigHome';
import ProviderConfigHome from './Provider/ProviderConfigHome';
import CareCoordinatorConfigHome from './CareCoordinator/CareCoordinatorConfigHome.js';
import Scheduling from './Scheduling/Scheduling';
import Reminders from './Reminders';
import Appropriateness from './Approp/Approp';
import Providers from './Table/Providers';
import Admins from './Table/Admins';
import CareCoordinators from './Table/CareCoordinators';
import Payroll from './Payroll';
import CptRates from './Table/CPTRates';
import Docs from './Docs';
import Clients from './Client/Clients.js';
import OurTeam from './Team/OurTeam';
import Directory from './Table/DirectoryTable';
import TeamMetrics from './Table/TeamMetrics.js';
import {
  hasSuperAdminAccess,
  isCareCoordinator,
  isAdmin,
} from '../common/utility.js';
import '../styles/MaterialDrawer.css';

import { useLocation, useHistory } from 'react-router-dom';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'auto',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'auto',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const StyledRoot = styled('div')({
  display: 'flex',
});

const StyledAppBar = styled(AppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  // }),

  [theme.breakpoints.down('sm')]: {
    '&.MuiAppBar-root': {
      // Ensuring it targets the root of the AppBar
      height: '55px', // Custom height
    },
    '& .MuiToolbar-regular': {
      // Targeting the toolbar explicitly
      minHeight: '48px', // Ensure the toolbar matches the AppBar height
    },
    '& .MuiTypography-h6': {
      fontSize: '1rem',
      display: 'block',
      whiteSpace: 'nowrap',
    },
  },
  [theme.breakpoints.down('md')]: {
    height: 84, // Reduced height for mobile devices
    overflowX: 'auto', // Allow horizontal scrolling
    '& .MuiTypography-h6': {
      fontSize: '1rem', // Increase the font size for mobile screens
      display: 'none', // Ensure visibility on smaller screens
      whiteSpace: 'nowrap', // Prevent text from wrapping
    },
  },
}));

const StyledButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? '#3f51b5' : 'transparent', // Active tab background color
  color: active ? 'white' : 'gray', // Active text color for active, gray for inactive
  fontSize: '1.2rem',
  borderRadius: '20px',
  padding: '6px 20px',
  '&:hover': {
    backgroundColor: active ? '#303f9f' : '#eeeeee', // Darken the background color on hover for active, light color for inactive
  },
  margin: theme.spacing(1),
}));

const StyledTabButton = styled(Button)(({ theme, active }) => ({
  color: active ? 'white' : 'gray', // Active tab has white color, others gray
  backgroundColor: active ? '#3f51b5' : 'transparent', // Active tab has a specific background color
  fontSize: '1.2rem',
  borderRadius: '20px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: active ? '#303f9f' : '#eeeeee', // Change background on hover
  },
  margin: theme.spacing(1),
}));

const StyledToolbar = styled('div')(({ theme, isMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  ...(isMobile &&
    {
      // Add your mobile-specific styles here
      // Example: background: 'lightblue', // Just as an example
    }),
}));

const StyledContent = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  marginTop: '60px',
  padding: theme.spacing(0),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? `${drawerWidth}px` : `-30px`, // Ensure no margin when drawer is closed
}));

const StyledGrow = styled('div')({
  flexGrow: 1,
});

const StyledSectionDesktop = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const MiniDrawer = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { count } = useSelector((state) => state.notification);
  const [selectedTab, setSelectedTab] = useState('PsychCare');

  let menuOptions = [];

  if (hasSuperAdminAccess(currentUser)) {
    menuOptions = [
      'Home',
      'Our Team',
      'User Profiles',
      'Availability',
      'Scheduling',
      'Reminders',
      'Clients',
      'Provider Search',
      'Tables',
    ];
  } else if (isCareCoordinator(currentUser)) {
    menuOptions = [
      'Dashboard',
      'Home',
      'My Profile',
      'Our Team',
      'Provider Profiles',
      'Availability',
      'Scheduling',
      'Reminders',
      'Provider Search',
      'Clients',
      'Tables',
    ];
  } else if (isAdmin(currentUser)) {
    menuOptions = [
      'Dashboard',
      'Home',
      'Update Provider Config',
      'Update Admin Config',
      'Availability',
      'Scheduling',
      'Reminders',
      'Provider Search',
      'Clients',
      'Our Team',
      'Tables',
    ];
  } else if (currentUser?.roles.includes('ROLE_DOCTOR')) {
    if (currentUser.roles.includes('ROLE_EXTERN')) {
      // Combined menu for users who are both doctors and externs
      menuOptions = [
        'Dashboard', // Assuming you might want externs to have access to the dashboard
        'Home',
        'Update Provider Config',
        'Provider Search',
        'Clients',
        'Directory',
        // Additional options specific to externs could be added here if needed
      ];
    } else {
      // Menu for doctors who are not externs
      menuOptions = [
        'Home',
        'Update Provider Config',
        'Payroll',
        'Provider Search',
        'Clients',
        'Directory',
      ];
    }
  } else if (currentUser?.roles.includes('ROLE_EXTERN')) {
    // Menu for externs, which should never occur standalone as per your system rules
    menuOptions = [
      'Home',
      'Update Provider Config',
      'Provider Search',
      'Clients',
      'Directory',
    ];
  } else {
    // Default menu for users who don't match any special roles
    menuOptions = ['Home', 'Availability', 'Provider Search'];
  }

  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [selectedSubTab, setSelectedSubTab] = useState('Providers');
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { pathname } = useLocation();
  const [openTables, setOpenTables] = React.useState(false);

  useEffect(() => {
    // Automatically open the drawer if the pathname is '/personal_info'
    if (pathname === '/personal_info') {
      // Check the window width; if it's greater than 600px, open the drawer
      if (window.innerWidth > 600) {
        setOpen(true);
      } else {
        // For smaller screens like iPhones, keep the drawer closed
        setOpen(false);
      }
    }
  }, [pathname]); // dependency array to run the effect whenever pathname changes

  useEffect(() => {
    // Update selectedTab based on the current pathname
    if (pathname.startsWith('/dashboard')) {
      setSelectedTab('Dashboard');
      if (pathname.includes('/dashboard/appointments')) {
        setSelectedSubTab('Appointments');
      } else if (pathname.includes('/dashboard/call-logs')) {
        setSelectedSubTab('Call Logs');
      } else if (pathname.includes('/dashboard/team_members')) {
        setSelectedSubTab('Team Members');
      } else {
        setSelectedSubTab(''); // Default or reset
      }
    } else if (pathname.includes('/our-team')) {
      setSelectedTab('Our Team');
      if (pathname.includes('/our-team/providers')) {
        setSelectedSubTab('Providers');
      } else if (pathname.includes('/our-team/admins')) {
        setSelectedSubTab('Admins');
      } else if (pathname.includes('/our-team/care-coordinators')) {
        setSelectedSubTab('Care Coordinators');
      } else if (pathname.includes('/our-team/directory')) {
        setSelectedSubTab('Directory');
      } else {
        setSelectedSubTab(''); // Clear subtab if none of the paths match
      }
    } else if (pathname.startsWith('/user-profiles')) {
      setSelectedTab('User Profiles');
      if (pathname.includes('/user-profiles/providers')) {
        setSelectedSubTab('Provider Profiles');
      } else if (pathname.includes('/user-profiles/admins')) {
        setSelectedSubTab('Admin Profiles');
      } else if (pathname.includes('/user-profiles/care_coordinators')) {
        setSelectedSubTab('Care Coordinator Profiles');
      } else {
        setSelectedSubTab('');
      }
    } else if (pathname.startsWith('/personal_info')) {
      setSelectedTab('Home');
      setSelectedSubTab('');
    } else if (pathname.startsWith('/reminders')) {
      setSelectedTab('Reminders');
      setSelectedSubTab('');
    } else if (pathname.startsWith('/scheduling')) {
      setSelectedTab('Scheduling');
      setSelectedSubTab('');
    } else if (pathname.startsWith('/update_availability')) {
      setSelectedTab('Update Availability');
      setSelectedSubTab('');
    } else {
      setSelectedTab('PsychCare'); // Clear main tab if none of the paths match
      setSelectedSubTab(''); // Clear subtab
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentUser]);

  const history = useHistory();
  const handleClick = (event) => {
    history.push('/notifications');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    console.log('Drawer toggled:', !open); // Check the console to see if the state toggles as expected
  };

  // Handle subtab click, setting the selectedSubTab state and navigating
  const handleOurTeamSubTabClick = (subTabName) => {
    const urlFriendlySubTabName = subTabName.toLowerCase().replace(/\s+/g, '-');
    setSelectedSubTab(subTabName);
    history.push(`/our-team/${urlFriendlySubTabName}`);
  };

  const handleUserProfilesSubTabClick = (subTabName) => {
    let urlPath = '';
    switch (subTabName) {
      case 'Provider Profiles':
        urlPath = '/user-profiles/provider_config'; // URL for Provider Config
        break;
      case 'Admin Profiles':
        urlPath = '/user-profiles/admin_config'; // URL for Admin Config
        break;
      case 'Care Coordinator Profiles':
        urlPath = '/user-profiles/care_coordinator_config'; // URL for Care Coordinator Config
        break;
      default:
        urlPath = '/'; // Default path if no match
        break;
    }
    setSelectedSubTab(subTabName);
    history.push(urlPath);
  };

  const handleDashboardSubTabClick = (subTabName) => {
    let urlPath = '';
    switch (subTabName) {
      case 'Appointments':
        urlPath = '/dashboard/appointments'; // URL for Appointments
        break;
      case 'Call Logs':
        urlPath = '/dashboard/call-logs'; // URL for Call Logs
        break;
      case 'Team Members':
        urlPath = '/dashboard/team_members'; // URL for Providers
        break;
      default:
        console.error('Unexpected subTabName:', subTabName);
        urlPath = '/dashboard/appointments'; // Default or error handling route
        break;
    }
    setSelectedSubTab(subTabName);
    history.push(urlPath); // Navigate to the selected tab's URL
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileIconClick = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    props.history.push('/personal_info');
  };

  const handleDashboardIconClick = () => {
    console.log('Dashboard clicked');
    setAnchorEl(null);
    handleMobileMenuClose();
    history.push('/dashboard/appointments');
  };

  const handleUserMgtClick = () => {
    console.log('User Mgt. clicked');
    setAnchorEl(null);
    handleMobileMenuClose();
    handleDrawerClose();
    history.push('/user/management');
  };

  const handleActivityLogClick = () => {
    console.log('Logs clicked');
    setAnchorEl(null);
    handleMobileMenuClose();
    handleDrawerClose();
    history.push('/logs');
  };

  const handleLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    dispatch(clearFormData());
    dispatch(logout());
    props.history.push('/');
  };

  // AppBar content
  const renderAppBarContent = () => {
    if (selectedTab === 'Our Team') {
      return (
        <Box
          sx={{
            flexGrow: 1, // This will allow the box to take up available space
            display: 'flex',
            justifyContent: 'center', // This centers the children horizontally
            alignItems: 'center', // This centers the children vertically
          }}
        >
          {currentUser?.roles.includes('ROLE_DOCTOR')
            ? ['Directory'].map((subTabName) => (
                <StyledButton
                  key={subTabName}
                  onClick={() => handleOurTeamSubTabClick(subTabName)}
                  active={selectedSubTab === subTabName}
                >
                  {subTabName}
                </StyledButton>
              ))
            : ['Providers', 'Admins', 'Care Coordinators', 'Directory'].map(
                (subTabName) => (
                  <StyledTabButton
                    key={subTabName}
                    onClick={() => handleOurTeamSubTabClick(subTabName)}
                    active={selectedSubTab === subTabName}
                  >
                    {subTabName}
                  </StyledTabButton>
                )
              )}
        </Box>
      );
    } else if (selectedTab === 'User Profiles') {
      // Render tabs for 'User Profiles'
      return (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {[
            'Provider Profiles',
            'Admin Profiles',
            'Care Coordinator Profiles',
          ].map((subTabName) => (
            <StyledTabButton
              key={subTabName}
              onClick={() => handleUserProfilesSubTabClick(subTabName)}
              active={selectedSubTab === subTabName}
            >
              {subTabName}
            </StyledTabButton>
          ))}
        </Box>
      );
    } else if (selectedTab === 'Dashboard') {
      return (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {['Appointments', 'Call Logs', 'Team Members'].map((subTabName) => (
            <StyledTabButton
              key={subTabName}
              onClick={() => handleDashboardSubTabClick(subTabName)}
              active={selectedSubTab === subTabName}
            >
              {subTabName}
            </StyledTabButton>
          ))}
        </Box>
      );
    }

    return null;
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!hasSuperAdminAccess(currentUser) && (
        <MenuItem
          onClick={handleProfileIconClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </MenuItem>
      )}
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      {hasSuperAdminAccess(currentUser) && [
        <MenuItem
          key="dashboard"
          onClick={handleDashboardIconClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </MenuItem>,

        <MenuItem
          key="logs"
          onClick={handleActivityLogClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <ManageSearchIcon />
          </ListItemIcon>
          <ListItemText primary="Logs | User Activity" />
        </MenuItem>,

        <MenuItem
          key="user-mgt"
          onClick={handleUserMgtClick}
          style={{ justifyContent: 'flex-start' }}
        >
          <ListItemIcon style={{ minWidth: '35px' }}>
            <PersonAddAltIcon />
          </ListItemIcon>
          <ListItemText primary="User Mgt." />
        </MenuItem>,
      ]}

      <MenuItem onClick={handleLogout} style={{ justifyContent: 'flex-start' }}>
        <ListItemIcon style={{ minWidth: '35px' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit" size="large">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="show 11 new notifications"
          color="inherit"
          onClick={handleClick}
          size="large"
        >
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large"
        >
          {/* <AccountCircle /> */}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <StyledRoot>
      <CssBaseline />

      <StyledAppBar position="fixed" open={open}>
        <StyledToolbar isMobile={window.innerWidth <= 480}>
          <IconButton
            onClick={handleDrawerToggle}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            size="large"
            style={{ marginLeft: 5 }}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            sx={(theme) => ({
              flexGrow: 1,
              display: 'block',
              whiteSpace: 'nowrap', // Prevents the text from wrapping to a new line
              overflow: 'hidden', // Hides overflow text
              textOverflow: 'ellipsis', // Shows an ellipsis if the text overflows
              fontSize: {
                xs: 'calc(0.9rem + 1vw)', // Smaller on very small screens
                sm: 'calc(0.9rem + 1vw)', // Slightly larger for small devices
                md: 'calc(0.9rem+ 1vw)', // Default size for medium screens
                lg: 'calc(1rem + 1vw)', // Larger size for larger screens
                xl: 'calc(1rem + 1vw)', // Even larger for extra-large screens
              },
              // Ensure the padding is responsive to keep the text always visible
              px: {
                xs: 1, // Smaller padding on extra small devices
                sm: 2, // Moderate padding on small devices
                md: 3, // Default padding on medium devices
                lg: 4, // Larger padding on large screens
                xl: 5, // Extra padding on extra-large screens
              },
            })}
          >
            {selectedTab}
          </Typography>

          <StyledGrow />

          {renderAppBarContent()}
          <StyledGrow />

          <StyledSectionDesktop>
            <IconButton
              aria-label="show 18 new notifications"
              color="inherit"
              onClick={handleClick}
              size="large"
            >
              <Badge badgeContent={count} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large"
            >
              {hasSuperAdminAccess(currentUser) ? (
                <Typography
                  variant="body1"
                  component="div"
                  style={{ fontSize: 20, marginRight: 15 }}
                >
                  Admin
                </Typography>
              ) : (
                <AccountCircle />
              )}
            </IconButton>
          </StyledSectionDesktop>
        </StyledToolbar>
      </StyledAppBar>

      {renderMobileMenu}
      {renderMenu}
      <StyledDrawer
        variant="permanent"
        open={open}
        onClose={handleDrawerToggle}
      >
        <StyledToolbar isMobile={window.innerWidth <= 480}>
          <IconButton
            onClick={handleDrawerToggle}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            size="large"
          >
            {/* {open ? <ChevronLeftIcon /> : <MenuIcon />} */}
          </IconButton>
        </StyledToolbar>
        <Divider style={{ height: '1px', backgroundColor: '#e0e0e0' }} />

        <List>
          {menuOptions.map((text, index) => {
            if (text === 'Tables') {
              return (
                <React.Fragment key={text}>
                  <ListItemButton onClick={() => setOpenTables(!openTables)}>
                    <ListItemIcon>
                      <TableChartIcon /> {/* Icon for Tables */}
                    </ListItemIcon>
                    <ListItemText primary="Tables" />
                    {openTables ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  <Collapse in={openTables} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        onClick={() => {
                          history.push('/docs');
                          setSelectedTab('Docs');
                          handleDrawerClose();
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Docs" />
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          history.push('/cpt_rates');
                          setSelectedTab('CPT Rates');
                          handleDrawerClose();
                        }}
                      >
                        <ListItemIcon>
                          <CreditScoreIcon />
                        </ListItemIcon>
                        <ListItemText primary="CPT Rates" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }

            return (
              <ListItemButton
                key={text}
                onClick={(event) => {
                  switch (text) {
                    case 'Dashboard':
                      history.push('/dashboard/appointments');
                      setSelectedTab('Dashboard');
                      handleDrawerClose();
                      break;
                    case 'User Management':
                      history.push('/user/management');
                      setSelectedTab('User Management');
                      break;
                    case 'Home':
                      history.push('/personal_info');
                      setSelectedTab('Home');
                      break;
                    case 'Update Provider Config':
                      history.push('/user-profiles/provider_config');
                      setSelectedTab('Update Provider Config');
                      break;
                    case 'Update Admin Config':
                      history.push('/user-profiles/admin_config');
                      setSelectedTab('Update Admin Config');
                      break;
                    case 'Update Care Coordinator Config':
                      history.push('/user-profiles/care_coordinator_config');
                      setSelectedTab('Update Care Coordinator Config');
                      break;
                    case 'Availability':
                      history.push('/update_availability');
                      setSelectedTab('Update Availability');
                      handleDrawerClose();
                      break;
                    case 'Scheduling':
                      history.push('/scheduling');
                      setSelectedTab('Scheduling');
                      handleDrawerClose();
                      break;
                    case 'Reminders':
                      history.push('/reminders');
                      setSelectedTab('Reminders');
                      handleDrawerClose();
                      break;
                    case 'Provider Search':
                      history.push('/approp');
                      setSelectedTab('Approp');
                      break;
                    case 'Payroll':
                      history.push('/payroll');
                      setSelectedTab('Payroll');
                      break;
                    case 'Logs | User Activity':
                      history.push('/logs');
                      setSelectedTab('Logs | User Activity');
                      break;
                    case 'Clients':
                      history.push('/clients');
                      setSelectedTab('Clients');
                      handleDrawerClose();
                      break;
                    case 'Directory':
                      history.push('/our-team/directory');
                      setSelectedTab('Directory');
                      handleDrawerClose();
                      break;
                    case 'Providers':
                      history.push('/dashboard/metrics');
                      setSelectedTab('Providers');
                      handleDrawerClose();
                      break;
                    case 'Our Team':
                      history.push('/our-team/providers');
                      setSelectedTab('Our Team');
                      handleDrawerClose();
                      break;
                    case 'Care Coordinators':
                      history.push('/our-team/care-coordinators');
                      setSelectedTab('Our Team');
                      handleDrawerClose();
                      break;

                    case 'User Profiles':
                      history.push('/user-profiles/providers');
                      setSelectedTab('User Profiles');
                      handleDrawerClose();
                      break;

                    case 'Provider Profiles':
                      history.push('/user-profiles/providers');
                      setSelectedTab('Provider Profiles');
                      handleDrawerClose();
                      break;

                    case 'Admin Profiles':
                      history.push('/user-profiles/admins');
                      setSelectedTab('Admin Profiles');
                      handleDrawerClose();
                      break;

                    case 'Care Coordinator Profiles':
                      history.push('/user-profiles/care_coordinators');
                      setSelectedTab('Care Coordinator Profiles');
                      handleDrawerClose();
                      break;

                    case 'My Profile':
                      history.push('/care_coordinator_config');
                      setSelectedTab('My Profile');
                      handleDrawerClose();
                      break;

                    case 'CPT Rates':
                      history.push('/cpt_rates');
                      setSelectedTab('Cpt Rates');
                      handleDrawerClose();
                      break;
                    case 'Docs':
                      history.push('/docs');
                      setSelectedTab('Docs');
                      handleDrawerClose();
                      break;
                    default:
                      break;
                  }
                }}
              >
                <ListItemIcon>
                  {text === 'Dashboard' ? (
                    <SpeedIcon />
                  ) : text === 'User Management' ? (
                    <GroupIcon />
                  ) : text === 'Home' ? (
                    <HomeIcon />
                  ) : text === 'My Profile' ? (
                    <ManageAccountsIcon />
                  ) : text === 'Update Provider Config' ? (
                    <ManageAccountsIcon />
                  ) : text === 'Availability' ? (
                    <EventAvailableIcon />
                  ) : text === 'Scheduling' ? (
                    <ScheduleIcon />
                  ) : text === 'Provider Profiles' ? (
                    <ManageAccountsIcon />
                  ) : text === 'User Profiles' ? (
                    <ManageAccountsIcon />
                  ) : text === 'Reminders' ? (
                    <NotificationsActiveIcon />
                  ) : text === 'Provider Search' ? (
                    <PersonSearchIcon />
                  ) : text === 'Payroll' ? (
                    <PaidOutlinedIcon />
                  ) : text === 'Logs | User Activity' ? (
                    <ManageSearchIcon />
                  ) : text === 'Clients' ? (
                    <GroupIcon />
                  ) : text === 'Our Team' || text === 'Directory' ? (
                    <Diversity1Icon />
                  ) : (
                    <InboxIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    text === 'Our Team'
                      ? currentUser?.roles.includes('ROLE_DOCTOR')
                        ? 'Directory'
                        : 'Our Team'
                      : text
                  }
                />
              </ListItemButton>
            );
          })}
        </List>

        <Divider style={{ height: '1px', backgroundColor: '#e0e0e0' }} />

        <ListItemButton
          key="logout"
          onClick={(e) => {
            handleLogout();
          }}
        >
          <ListItemIcon>
            {' '}
            <LogoutIcon />{' '}
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </StyledDrawer>
      <StyledContent>
        <StyledToolbar>
          {(pathname === '/dashboard/appointments' ||
            pathname === '/dashboard/call-logs') && <Dashboard />}
          {pathname === '/user/management' && <UserManagement />}
          {pathname === '/personal_info' && <PersonalInfo />}
          {pathname === '/our-team/providers' && <Providers />}
          {pathname === '/our-team/admins' && <Admins />}
          {pathname === '/our-team/care-coordinators' && <CareCoordinators />}
          {pathname === '/our-team/directory' && <Directory />}
          {pathname === '/dashboard/team_members' && <TeamMetrics />}
          {pathname === '/user-profiles/providers' && <ProviderConfigHome />}
          {pathname === '/user-profiles/admins' && <AdminConfigHome />}
          {pathname === '/user-profiles/care_coordinators' && (
            <CareCoordinatorConfigHome />
          )}
          {pathname === '/user-profiles/care_coordinator_config' && (
            <CareCoordinatorConfigHome />
          )}
          {pathname === '/user-profiles/admin_config' && <AdminConfigHome />}
          {pathname === '/user-profiles/provider_config' && (
            <ProviderConfigHome />
          )}
          {pathname === '/care_coordinator_config' && (
            <CareCoordinatorConfigHome />
          )}
          {pathname === '/scheduling' && <Scheduling />}
          {pathname === '/reminders' && <Reminders />}
          {pathname === '/update_availability' && <UpdateAvailability />}
          {pathname === '/notifications' && <Notifications />}
          {pathname === '/approp' && <Appropriateness />}
          {pathname === '/payroll' && <Payroll />}
          {pathname === '/logs' && <Logs />}
          {pathname === '/clients' && <Clients />}
          {pathname === '/our_team/providers' && <OurTeam />}
          {pathname === '/cpt_rates' && <CptRates />}
          {pathname === '/docs' && <Docs />}
        </StyledToolbar>
      </StyledContent>
    </StyledRoot>
  );
};
export default MiniDrawer;
