import { useEffect } from 'react';

export const UserActivityLogger = () => {
  useEffect(() => {
    const handleActivity = () => {
      const currentTime = Date.now();
      localStorage.setItem('lastActivity', currentTime.toString());
    };

    const handleUserActivity = () => {
      handleActivity();
    };

    // Listen for user activity events (both mouse and touch)
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);
    window.addEventListener('touchmove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    // Initial activity record
    handleActivity();

    return () => {
      // Remove event listeners when component unmounts
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
      window.removeEventListener('touchmove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
    };
  }, []);

  return null;
};
