import React, { useEffect, useState, useCallback } from 'react';
import {
  Autocomplete,
  Box,
  Grid,
  Switch,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { clearLoading, setLoading } from '../../actions/loading';
import EventBus from '../../common/EventBus';
import { useSelector, useDispatch } from 'react-redux';
import AdminPersonalInfo from '../Input/AdminPersonalInfo';
import AdminSystemInfo from '../Input/AdminSystemInfo';
import AdminPracticeInfo from '../Input/AdminPracticeInfo';
import UserService from '../../services/user.service';
import {
  isDoctor,
  isDoctorAdminSupervisor,
  isSupervisee,
} from '../../common/utility';
import '../../styles/ProviderConfigHomeStyles.css';

export default function AdminConfigHome() {
  const [value, setValue] = React.useState('personal_info');
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [selectedUserDetail, setSelectedUserDetail] = React.useState();
  const [showActiveAdminOnly, setShowActiveAdminOnly] = useState(true);  // Default to true
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllAdmins = async (status) => {
    UserService.getAllAdmins(status).then(
      (response) => {
        let admins = response?.data ?? [];
        // just sorting needed here no filtering on admin_name
        admins = admins.sort((admin1, admin2) => {
          const name1 = admin1?.admin_name?.split(' ')[1] ?? '';
          const name2 = admin2?.admin_name?.split(' ')[1] ?? '';
          return name1.localeCompare(name2);
        });

        if (isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser)) {
          admins = admins.filter((admin) => {
            return admin.id === currentUser.id;
          });
        }
        setUsers(admins);
        hideLoading();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  useEffect(() => {
    /*
    The below env key is same for both the providers and the admin
    */
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();
    if (showActiveAdminOnly) {
      getAllAdmins('active');
    } else {
      getAllAdmins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveAdminOnly]);

  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      const adminDetail = await UserService.getAdminDetail(
        enObj.id,
        enObj.email
      );
      if (adminDetail && adminDetail.data) {
        adminDetail.data.admin.username = enObj.username;
        setSelectedUserDetail(adminDetail?.data?.admin);
      }
    }
  };
  const updateAdminDetail = async () => {
    const adminDetail = await UserService.getAdminDetail(
      selectedUser.id,
      selectedUser.email
    );
    if (adminDetail && adminDetail.data) {
      setSelectedUserDetail(adminDetail?.data?.admin);
    }
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {(!isDoctor(currentUser) ||
              isDoctorAdminSupervisor(currentUser)) && (
              <Autocomplete
                disablePortal
                defaultValue={selectedUser}
                id="admin"
                options={users}
                onChange={onTagsChange}
                renderInput={(params) => (
                  <TextField
                    className="widerProviderDropdown"
                    variant="outlined"
                    {...params}
                    name="admin_"
                    label="Admin"
                  />
                )}
                getOptionLabel={(option) => option.admin_name || ''}
              />
            )}
            {isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser) && (
              <>
                <h3>{currentUser.username}</h3>
                <h5>{currentUser.email}</h5>
              </>
            )}
          </Grid>
        </Grid>
        {selectedUser && (
          <Box className="tab-container">
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where selection follows focus"
              selectionFollowsFocus
            >
              <Tab value="personal_info" label="Personal Info" />
              <Tab value="provider_systems" label="Systems" />
              <Tab value="practice_info" label="PRACTICE INFO" />
            </Tabs>

            {value === 'personal_info' && (
              <AdminPersonalInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)} // Add this line
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateAdminDetail={updateAdminDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {value === 'provider_systems' && (
              <AdminSystemInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {value === 'practice_info' && (
              <AdminPracticeInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                // updateProviderDetail={updateProviderDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}
          </Box>
        )}
        {!selectedUser && <div>Please select a Admin.</div>}

        {shouldShow && !selectedUser && (
          <div
            style={{
              flexGrow: 1,
              flexBasis: 'calc(33.333% - 24px)',
              maxWidth: 'calc(33.333% - 24px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
           <Switch
            checked={showActiveAdminOnly}
            onChange={(e) => setShowActiveAdminOnly(e.target.checked)}
          />
          <span
            style={{
              fontSize: '1.1rem',
              fontWeight: 'bold',
            }}
          >
            {showActiveAdminOnly ? (
              <span>Show Active Admins</span>  // Active when checked
            ) : (
              <span>Show All Admins</span>  // Default view when not checked
            )}
          </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
