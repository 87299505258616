import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const getSheetDetail = () => {
  return axios.get(API_URL + `sheet/detail`, { headers: authHeader() });
};

// eslint-disable-next-line
export default {
  getSheetDetail,
};
