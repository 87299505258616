import React, { useState, useEffect, useCallback } from 'react';
import { clearLoading, setLoading } from '../../actions/loading';
import { SET_MESSAGE } from '../../actions/types';
import { logout } from '../../actions/auth';
import EventBus from '../../common/EventBus';
import { isAdminOrCareCoordinator } from '../../common/utility';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TablePagination,
} from '@mui/material';

import { ClientSidebar } from './ClientSideBar';
import DIP from './DIP';
import Rosters from './Rosters';
import Questionaires from './Questionaires';
import TabLabelWithBadge from '../UI/TabLabelWithBadge';
import UserService from '../../services/user.service';

import '../../styles/Clients.css';

const TabLabelWithDescription = ({ title, description }) => (
  <div style={{ textAlign: 'center' }}>
    <div style={{ fontSize: '1rem' }}>{title}</div>{' '}
    {/* Larger font size for title */}
    <div className="tabDescription">{description}</div>{' '}
    {/* Smaller font size for description */}
  </div>
);

export default function Clients() {
  const [sortColumn, setSortColumn] = useState('Provider');
  const [sortDirection, setSortDirection] = useState('asc');
  const [activeButton, setActiveButton] = useState('All Clients');
  const [clients, setClients] = useState([]);
  const [LocalLoading, setLocalLoading] = useState(true);
  const [selectedFields, setSelectedFields] = useState([]);
  const [displayedClients, setDisplayedClients] = useState(clients);
  const [expandedRows, setExpandedRows] = useState({});

  const [showActiveAndNewOnly, setShowActiveAndNewOnly] = useState(true);
  const [selectedStatus] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [confirmationPopupContent, setConfirmationPopupContent] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showClientsFromAllTherapists, setShowClientsFromAllTherapists] =
    useState(false);
  const [totalCount, setTotalCount] = useState();
  const [uniqueClientCount, setUniqueClientCount] = useState();
  const [searchAPICall, setSearchAPICall] = useState(false);

  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };
  const isDoctor = (user) => user.roles.includes('ROLE_DOCTOR');

  const [users, setUsers] = React.useState([]);
  const [, setSelectedUser] = React.useState();
  const [, setSelectedUserDetail] = React.useState();
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalSelection, setModalSelection] = useState('');
  const [, setShowConfirmationText] = useState(false);
  const [allProviders, setAllProviders] = useState();

  const handleTabChange = (event, newValue) => {
    localStorage.removeItem('activeTab');

    setActiveTab(newValue);

    // Assuming the sidebar is only meant to be visible on the first tab (index 0)
    if (newValue !== 0) {
      // Hide the sidebar and remove the class when switching away from the first tab
      setSidebarVisible(false);
      document.body.classList.remove('sidebar-expanded');
    }
  };

  const { user: currentUser } = useSelector((state) => state.auth);
  const toggleClientDetail = (mrn) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [mrn]: !prevExpandedRows[mrn],
    })); // Added missing parenthesis here
  };

  const sortClients = (clients) => {
    if (!Array.isArray(clients)) return [];

    return [...clients].sort((a, b) => {
      let primaryValA, primaryValB, secondaryValA, secondaryValB;

      switch (sortColumn) {
        case 'Provider':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2]; // Sorting by last name
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          secondaryValA = `${a.LASTNAME} ${a.FIRSTNAME}`;
          secondaryValB = `${b.LASTNAME} ${b.FIRSTNAME}`;
          break;
        case 'Name':
          primaryValA = a.LASTNAME;
          primaryValB = b.LASTNAME;
          secondaryValA = a.FIRSTNAME;
          secondaryValB = b.FIRSTNAME;
          break;
        case 'Date of Birth':
          primaryValA = new Date(a.DOB);
          primaryValB = new Date(b.DOB);
          break;
        case 'Status':
          primaryValA = a.STATUS;
          primaryValB = b.STATUS;
          break;
        case 'Therapist':
          primaryValA = a.PROVIDER.split(' ')[a.PROVIDER.split(' ').length - 2];
          primaryValB = b.PROVIDER.split(' ')[b.PROVIDER.split(' ').length - 2];
          break;
        default:
          return 0;
      }

      if (primaryValA < primaryValB) return sortDirection === 'asc' ? -1 : 1;
      if (primaryValA > primaryValB) return sortDirection === 'asc' ? 1 : -1;

      // Secondary sort logic (for Provider and Name)
      if (secondaryValA < secondaryValB) return -1;
      if (secondaryValA > secondaryValB) return 1;

      return 0;
    });
  };

  const handleSelectFields = (event) => {
    // Directly use the value from the event for Material-UI Select
    setSelectedFields(event.target.value);
  };

  const handleRadioClick = (client) => {
    setSelectedClientId(client.ta_mrn); // Set the selected client
    setSelectedClientData(client); // Store the entire client object
    setShowModal(true); // Open the modal
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleModalSubmit = async (client) => {
    showLoading();
    if (!client || !client.ta_mrn) {
      console.error('No client selected or client data is incomplete');
      // Handle this scenario, maybe alert the user or disable the submit button
      return;
    }
    try {
      let taskDetails = {};

      let clientUpdate = {
        clientId: client.ta_mrn,
        hrnsClient: false,
        dischargedByLetterClient: false,
        changeStatusToActive: false,
        changeStatusToInactive: false,
        providerId: client.provider_id,
      };

      if (modalSelection === 'HRNS') {
        taskDetails = {
          type: 'HRNS',
          client: {
            PROVIDER: client.PROVIDER,
            LASTNAME: client.LASTNAME,
            FIRSTNAME: client.FIRSTNAME,
            ta_mrn: client.ta_mrn,
          },
          providerId: client.provider_id,
        };
        clientUpdate.hrnsClient = true;
        clientUpdate.changeStatusToActive = true; // Additional logic for updating client status to 'Active'
      } else if (modalSelection === 'DischargeLetter') {
        taskDetails = {
          type: 'DischargeLetter',
          client: {
            PROVIDER: client.PROVIDER,
            LASTNAME: client.LASTNAME,
            FIRSTNAME: client.FIRSTNAME,
            ta_mrn: client.ta_mrn,
          },
          providerId: client.provider_id,
        };
        clientUpdate.dischargedByLetterClient = true;
        clientUpdate.changeStatusToInactive = true; // Additional logic for updating client status to 'Inactive'
      } else {
        // Handle case where no valid option is selected, if necessary
        return;
      }

      // Call the task creation function
      console.log('Data sent to backend in Clients.js:', taskDetails);
      const response = await UserService.createDockHealthTasks(taskDetails);
      console.log('Task created successfully:', response.data);

      // Call the function to update client columns
      console.log('Client update data:', clientUpdate);
      const updateResponse = await UserService.updateClientColumns([
        clientUpdate,
      ]);
      console.log('Client updated successfully:', updateResponse.data);

      // Close the modal and reset modal selection
      setShowModal(false);
      setModalSelection('');

      window.location.reload();
    } catch (error) {
      console.error('Error creating task or updating client:', error);
      // Handle error (e.g., show error message)
      // Optionally, you might want to keep the modal open or take other actions in case of error
    } finally {
      hideLoading();
    }
  };

  const handleChange = (event) => {
    setActiveTab(event.target.value);
  };

  const handleConfirmSubmit = () => {
    // Here you would handle the actual submission to the backend
    alert('Thank you for confirming the changes.');
    setConfirmationModalOpen(false); // Close confirmation modal
    setSelectedClientId(null); // Deselect any selected radio buttons
    // Reset the state as if the page was refreshed
  };

  const handleCancelConfirmation = () => {
    setConfirmationModalOpen(false); // Close confirmation modal
    setSelectedClientId(null); // Deselect any selected radio buttons
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page change
  };

  const handleRadioButtonChange = (client, selection) => {
    setSelectedClientId(client.ta_mrn);
    setSelectedClientData(client);
    setModalSelection(selection);

    let confirmationMessage = '';
    if (selection === 'HRNS') {
      confirmationMessage = `
    
    <ul>
      <li>A task will be sent to the admin that handles your client accounts to notify the admin that this client now has a status of a HRNS.</li>
      <li>The admin will then contact the client prior to every scheduled appointment to confirm the appointment (versus just an automated message).</li>
      <li>You will notice a small subheader under the client's name in the app will show in red font indicating that the client has a status as an HRNS client.</li>
    </ul>
  `;
    } else if (selection === 'DischargeLetter') {
      confirmationMessage = `<b> When To Make This Selection </b> Selecting the Send Discharge Letter is for clients that due to clinical or ethical concerns need to be discharged from the practice (e.g., needs higher level of care). 
    </br> <b>What Happens After You Make This Selection:</b> A task will be created in our admin task system for the admin to mail the discharge letter to your client that you have uploaded to the client's TA document section.  
   
    <b> Prior To Selecting This Option Please Make Sure That You Have: </b>
    <ul style="line-height: 1; margin-bottom: 5px;">
   <li>Written a termination letter for this client and uploaded the letter in the client's TA document section.</li>
    <li>Changed the status of this client in TA to Inactive.</li> </ul>
    <b> Helpful links: </b>
    <ul style="line-height: 1; margin-bottom: 5px;">
    <li><a href="www.google.com" target="_blank" rel="noopener noreferrer">Sample discharge letter that you can use as a template.</a></li>
    <li><a href="www.google.com" target="_blank" rel="noopener noreferrer">How to upload a document to the document section in TA.</a></li>
    <li> <a href="www.google.com" target="_blank" rel="noopener noreferrer">How to change the status of your client to Inactive in TA.</a></li>
      </ul>
`;
    }

    setConfirmationPopupContent(confirmationMessage);
    setShowConfirmationText(true);
  };

  // The below useEffect is being added for allowing the user to be login through questionnaire
  useEffect(() => {
    if (localStorage.getItem('isQuestionnaire')?.toString() === 'true') {
      setActiveTab(3);
    }
    return () => {
      localStorage.removeItem('isQuestionnaire');
    };
  }, []);

  useEffect(() => {
    if (Number(localStorage.getItem('activeTab'))) {
      setActiveTab(Number(localStorage.getItem('activeTab')));
    }
  }, []);

  // This useEffect is responsible for getting all the providers.These providers will be passed to the sidebar
  useEffect(() => {
    UserService.getAllProviders().then(
      (response) => {
        let users = response?.data?.users ?? [];
        let providers = users.filter((user) => {
          return user.roleId === 5; // filtering provider from users
        });
        // Filtering provider for active and inactive flags
        if (showClientsFromAllTherapists) {
          setAllProviders([...providers]);
        } else {
          setAllProviders([
            ...providers.filter(
              (provider) => provider.providerActiveStatus === 1
            ),
          ]);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  }, [showClientsFromAllTherapists]);

  useEffect(() => {
    setPage(0); // Reset to the first page whenever the active/new status filter changes
  }, [showActiveAndNewOnly]);

  useEffect(() => {
    // Setting the loading state to true at the start of data fetching
    setLocalLoading(true);

    let fetchClients;
    // why are clients not showing in the Client List that are showing in the Rosters list?
    if (activeButton === 'All Clients') {
      // If the switch 'showClientsFromAllTherapists' is on, fetch all clients including those of inactive therapists.
      // Otherwise, fetch only clients of active therapists.
      // This assumes 'UserService.getClients' can accept an argument to differentiate between the two cases.
      fetchClients = showClientsFromAllTherapists
        ? UserService.getClients(
            'all',
            page,
            rowsPerPage,
            showActiveAndNewOnly,
            searchAPICall
          )
        : UserService.getClients(
            false,
            page,
            rowsPerPage,
            showActiveAndNewOnly,
            searchAPICall
          );
    } else if (activeButton === 'My Clients') {
      // Fetch the clients that are associated with the current user
      fetchClients = UserService.getMyClients();
    } else if (activeButton === 'Clients of Supervisees') {
      // Fetch the clients that are associated with the supervisees of the current user
      fetchClients = UserService.getClientsOfSupervisees();
    }

    if (fetchClients) {
      fetchClients
        .then((response) => {
          // Setting the state with the fetched client data
          setTotalCount(response.data.totalCount);
          setUniqueClientCount(response.data.uniqueClients);
          setClients(response.data.clients);
          setDisplayedClients(response.data.clients);
        })
        .catch((error) => {
          // Logging and handling errors during the fetching process
          console.error(
            `There was an error fetching clients based on the active button state (${activeButton}):`,
            error
          );
          // here have to make user logout of the application
          dispatch({
            type: SET_MESSAGE,
            payload:
              'You have been logged out something went wrong. Please click on login button to login back!',
          });
          dispatch(logout(currentUser));
        })
        .finally(() => {
          // Setting the loading state to false once the data fetching is complete or if an error occurs
          setLocalLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeButton,
    showClientsFromAllTherapists,
    page,
    rowsPerPage,
    showActiveAndNewOnly,
    searchAPICall,
  ]); // Dependencies for useEffect: activeButton and showClientsFromAllTherapists

  useEffect(() => {
    UserService.getUsers().then(
      (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 5; // filtering provider from users
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers.

        if (isDoctor(currentUser)) {
          providers = providers.filter((provider) => {
            return provider.id === currentUser.id;
          });
        }
        setUsers(providers);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveAndNewOnly]);

  useEffect(() => {
    if (users.length > 1) {
      // not a doctor. so return
      return;
    }
    if (currentUser.roles.indexOf('ROLE_DOCTOR') > -1) {
      onTagsChange(null, users[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      const providerDetail = await UserService.getProviderDetail(
        enObj.id,
        enObj.email
      );
      if (providerDetail && providerDetail.data) {
        providerDetail.data.provider.username = enObj.username;
        setSelectedUserDetail(providerDetail?.data?.provider);
      }
    }
  };

  const toggleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return (
    <div>
      <h1>Clients</h1>
      <div

        className={`clients-container ${
          sidebarVisible ? 'sidebar-expanded' : 'sidebar-collapsed'
        }`}
      ></div>

      <div>
        {/* Mobile Dropdown */}
        <div className="mobile-dropdown">
          <Select value={activeTab} onChange={handleChange} fullWidth>
            <MenuItem value={0}>Client List</MenuItem>
            <MenuItem value={1}>Rosters</MenuItem>
            <MenuItem value={2}>DIP</MenuItem>
            <MenuItem value={3}>Questionaires</MenuItem>
            {/* More options as needed */}
          </Select>
        </div>

        {/* Tabs Component */}
        <div className="tabs-container">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="client tabs"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              borderBottom: '2px solid #E0E0E0',
              background: '#F5F5F5',
              marginBottom: '20px',
            }}
          >
            <Tab
              label={
                <TabLabelWithDescription
                  title="Client List"
                  description="View All Clients & Make Individual Updates"
                />
              }
              className={activeTab === 0 ? 'customTabSelected' : ''}
              classes={{ root: 'customTabRoot' }}
            />
            <Tab
              label={
                <TabLabelWithDescription
                  title="Rosters"
                  description="View and Update Your Monthly Roster"
                />
              }
              className={activeTab === 1 ? 'customTabSelected' : ''}
              classes={{ root: 'customTabRoot' }}
            />
            <Tab
              label={
                <TabLabelWithBadge
                  title="DIP"
                  description="Discharge Clients in Process"
                />
              }
              className={activeTab === 2 ? 'customTabSelected' : ''}
              classes={{ root: 'customTabRoot' }}
            />

            <Tab
              label={
                <TabLabelWithDescription
                  title="Questionnaires"
                  description="Questionnaires Sent to Selective Clients Every 60 Days"
                />
              }
              className={activeTab === 3 ? 'customTabSelected' : ''}
              classes={{ root: 'customTabRoot' }}
            />
          </Tabs>
        </div>

        {/* Conditional Rendering Based on Active Tab */}
        {activeTab === 0 && (
          <>
            <ClientSidebar
              clients={clients}
              setDisplayedClients={setDisplayedClients}
              activeButton={activeButton}
              sidebarVisible={sidebarVisible}
              setSidebarVisible={setSidebarVisible}
              searchAPICall={searchAPICall}
              setSearchAPICall={setSearchAPICall}
              LocalLoading={LocalLoading}
              allProviders={allProviders}
            />

            {/* <Grid container spacing={3} style={{ marginBottom: '20px' }}> */}

            <div
              className="flex-container"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px',
                flexWrap: 'wrap',
                gap: '24px',
              }}
            >
              {/* Button Group */}
              <div
                style={{
                  flexGrow: 1,
                  flexBasis: 'calc(33.333% - 24px)',
                  maxWidth: 'calc(33.333% - 24px)',
                }}
              >
                {currentUser.roles.includes('ROLE_DOCTOR') &&
                  currentUser.roles.includes('ROLE_SUPERVISOR') && (
                    <ButtonGroup fullWidth aria-label="client button group">
                      {(!currentUser.roles.includes('ROLE_ADMIN') ||
                        currentUser.roles.includes('ROLE_ADMIN')) && (
                        <Button
                          variant={
                            activeButton === 'My Clients'
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={() => setActiveButton('My Clients')}
                        >
                          My Clients
                        </Button>
                      )}
                      {currentUser.roles.includes('ROLE_ADMIN') && (
                        <Button
                          variant={
                            activeButton === 'All Clients'
                              ? 'contained'
                              : 'outlined'
                          }
                          onClick={() => setActiveButton('All Clients')}
                        >
                          All Clients
                        </Button>
                      )}
                      <Button
                        variant={
                          activeButton === 'Clients of Supervisees'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setActiveButton('Clients of Supervisees')
                        }
                      >
                        Clients of Supervisees
                      </Button>
                    </ButtonGroup>
                  )}
              </div>

              {/* Existing Switch */}

              <div className="switch-container">
                <Switch
                  checked={showActiveAndNewOnly}
                  onChange={(e) => setShowActiveAndNewOnly(e.target.checked)}
                />
                <span>
                  {showActiveAndNewOnly
                    ? 'Show Active & New Clients Only'
                    : 'Show All Statuses'}
                </span>
              </div>

              {/* New Conditional Switch */}
              {!showActiveAndNewOnly &&
                isAdminOrCareCoordinator(currentUser) && (
                  <div className="conditional-switch-container">
                    <div className="switch-container">
                      <Switch
                        checked={showClientsFromAllTherapists}
                        onChange={(e) =>
                          setShowClientsFromAllTherapists(e.target.checked)
                        }
                      />
                      <span className="switch-label">
                        {showClientsFromAllTherapists
                          ? 'Show Clients of All Therapists'
                          : 'Show Clients of Active Therapists Only'}
                      </span>
                    </div>
                  </div>
                )}

              {/* Select Additional Fields */}
              <div
                style={{
                  flexGrow: 1,
                  flexBasis: 'calc(33.333% - 24px)',
                  maxWidth: 'calc(33.333% - 24px)',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div className="dropdown-container">
                  <FormControl
                    variant="outlined"
                    style={{
                      width: '100%',
                      minWidth: 120,
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                      padding: '5px',
                    }}
                  >
                    <InputLabel id="multiple-select-label">
                      Select additional fields
                    </InputLabel>
                    <Select
                      labelId="multiple-select-label"
                      multiple
                      value={selectedFields}
                      onChange={handleSelectFields}
                      label="Select additional fields"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: '300px',
                          },
                        },
                      }}
                    >
                      <MenuItem value="Marital Status">Marital Status</MenuItem>
                      <MenuItem value="Gender">Gender</MenuItem>
                      <MenuItem value="Insurance - Primary">
                        Insurance - Primary
                      </MenuItem>
                      <MenuItem value="Insurance - Secondary">
                        Insurance - Secondary
                      </MenuItem>
                      <MenuItem value="Questionnaire Cancel Details">
                        Questionnaire Cancel Details
                      </MenuItem>
                      {/* Include other fields as necessary */}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {LocalLoading ? (
              <p>Loading...</p>
            ) : (
              <table
                className={
                  sidebarVisible
                    ? 'table-sidebar-expanded'
                    : 'table-sidebar-collapsed'
                }
                border="1"
              >
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Select</th>

                    <th
                      style={{ textAlign: 'center' }}
                      onClick={() => toggleSort('Name')}
                    >
                      Name
                      <ArrowUpward
                        style={{
                          color:
                            sortColumn === 'Name' && sortDirection === 'asc'
                              ? 'black'
                              : 'lightgray',
                        }}
                      />
                      <ArrowDownward
                        style={{
                          color:
                            sortColumn === 'Name' && sortDirection === 'desc'
                              ? 'black'
                              : 'lightgray',
                        }}
                      />
                    </th>

                    <th style={{ textAlign: 'center' }}>Email</th>
                    <th style={{ textAlign: 'center' }}>Phone</th>
                    <th style={{ textAlign: 'center' }}>Meet Link</th>
                    <th
                      style={{ textAlign: 'center' }}
                      onClick={() => toggleSort('Date of Birth')}
                    >
                      Date of Birth
                      <ArrowUpward
                        style={{
                          color:
                            sortColumn === 'Date of Birth' &&
                            sortDirection === 'asc'
                              ? 'black'
                              : 'lightgray',
                        }}
                      />
                      <ArrowDownward
                        style={{
                          color:
                            sortColumn === 'Date of Birth' &&
                            sortDirection === 'desc'
                              ? 'black'
                              : 'lightgray',
                        }}
                      />
                    </th>

                    <th style={{ textAlign: 'center' }}>Account # </th>

                    {activeButton !== 'My Clients' && (
                      <th
                        style={{ textAlign: 'center' }}
                        onClick={() => toggleSort('Therapist')}
                      >
                        Therapist
                        <ArrowUpward
                          style={{
                            color:
                              sortColumn === 'Therapist' &&
                              sortDirection === 'asc'
                                ? 'black'
                                : 'lightgray',
                          }}
                        />
                        <ArrowDownward
                          style={{
                            color:
                              sortColumn === 'Therapist' &&
                              sortDirection === 'desc'
                                ? 'black'
                                : 'lightgray',
                          }}
                        />
                      </th>
                    )}

                    <th
                      style={{ textAlign: 'center' }}
                      onClick={() => toggleSort('Status')}
                    >
                      Status
                      <ArrowUpward
                        style={{
                          color:
                            sortColumn === 'Status' && sortDirection === 'asc'
                              ? 'black'
                              : 'lightgray',
                        }}
                      />
                      <ArrowDownward
                        style={{
                          color:
                            sortColumn === 'Status' && sortDirection === 'desc'
                              ? 'black'
                              : 'lightgray',
                        }}
                      />
                    </th>

                    {selectedFields.includes('Marital Status') && (
                      <th style={{ textAlign: 'center' }}> Marital Status</th>
                    )}
                    {selectedFields.includes('Gender') && (
                      <th style={{ textAlign: 'center' }}> Gender</th>
                    )}
                    {selectedFields.includes('Insurance - Primary') && (
                      <th style={{ textAlign: 'center' }}>
                        {' '}
                        Insurance - Primary
                      </th>
                    )}
                    {selectedFields.includes('Insurance - Secondary') && (
                      <th style={{ textAlign: 'center' }}>
                        {' '}
                        Insurance - Secondary
                      </th>
                    )}
                    {selectedFields.includes(
                      'Questionnaire Cancel Details'
                    ) && (
                      <th style={{ textAlign: 'center' }}>
                        {' '}
                        Questionnaire Cancel Details{' '}
                      </th>
                    )}

                    {/* Add more conditional columns here */}
                  </tr>
                </thead>

                <tbody>
                  {sortClients(displayedClients).map((client, index) => {
                    // Define cancellationDetails
                    const cancellationDetails =
                      client.cancellingQuestionnaireDetails
                        ? `Date: ${new Date(
                            client.cancellingQuestionnaireDetails.submittedAt
                          ).toLocaleDateString()} | By: ${
                            client.cancellingQuestionnaireDetails.submittedBy
                          } | Event: ${
                            client.cancellingQuestionnaireDetails.actionDetails
                              .newInsurance
                              ? `Change Insurance to ${client.cancellingQuestionnaireDetails.actionDetails.newInsurance}`
                              : 'Client marked as Inactive'
                          }`
                        : 'N/A';

                    return (
                      <tr
                        key={client.ta_mrn + client.provider_id} // Assuming ta_mrn is the unique identifier
                        className={
                          selectedClientId === client.ta_mrn
                            ? 'selected-row'
                            : ''
                        }
                        // onClick={() => handleRadioClick(client.ta_mrn)}
                      >
                        <td data-label="Select" className="centered-radio">
                          <input
                            type="radio"
                            name="clientSelection"
                            checked={selectedClientId === client.ta_mrn}
                            onChange={() => handleRadioClick(client)}
                            onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
                          />
                        </td>
                        <td data-label="Client Name" className="expandable">
                          <div
                            onClick={() => toggleClientDetail(client.ta_mrn)}
                            className="client-name"
                            style={{ cursor: 'pointer', textAlign: 'center' }}
                          >
                            {`${client.FIRSTNAME} ${client.LASTNAME}`}
                          </div>

                          <div
                            className={`client-details ${
                              expandedRows[client.ta_mrn] ? 'show' : 'hide'
                            }`}
                            style={{ textAlign: 'center' }}
                          >
                            <div style={{ fontSize: 'smaller', color: 'red' }}>
                              {/* Check for HRNS status and render label if necessary */}
                              {(client.STATUS === 'Active' ||
                                client.STATUS === 'New') &&
                                client.HRNS_CLIENT === 1 && <div>HRNS</div>}
                              {/* Check for AWOL status and render label if necessary */}
                              {client.STATUS === 'Active' &&
                                client.AWOL_CLIENT === 1 && <div>AWOL</div>}
                              {/* Check for NEVER SHOWED status and render label if necessary */}
                              {client.STATUS === 'Inactive' &&
                                client.NEVER_SHOWED_CLIENT === 1 && (
                                  <div>NEVER SHOWED</div>
                                )}{' '}
                              {/* Check for REMAINED AWOL status and render label if necessary */}
                              {client.STATUS === 'Inactive' &&
                                client.REMAINED_AWOL_CLIENT === 1 && (
                                  <div>REMAINED AWOL</div>
                                )}
                              {/* Check for DISCHARGED BY LETTER status and render label if necessary */}
                              {client.STATUS === 'Inactive' &&
                                client.DISCHARGED_BY_LETTER === 1 && (
                                  <div>DISCHARGED BY LETTER</div>
                                )}
                              {/* Check for AGREED TERMINATION status and render label if necessary */}
                              {client.STATUS === 'Inactive' &&
                                client.AGREED_TERMINATION_CLIENT === 1 && (
                                  <div>
                                    <div>AGREED TERMINATION</div>
                                    <div>
                                      -Survey Sent{' '}
                                      {client.AGREED_TERMINATION_DATE_MAILED &&
                                        formatDate(
                                          client.AGREED_TERMINATION_DATE_MAILED
                                        )}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </td>
                        <td data-label="Email" style={{ textAlign: 'center' }}>
                          {client.EMAIL}
                        </td>
                        <td data-label="Phone" style={{ textAlign: 'center' }}>
                          {client.PHONE1}
                        </td>
                        <td
                          data-label="Session Link"
                          style={{ textAlign: 'center' }}
                        >
                          {client.meet_link &&
                          client.meet_link.startsWith(
                            'https://meet.google.com/'
                          ) ? (
                            <a
                              href={client.meet_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: 'blue',
                                textDecoration: 'underline',
                              }}
                            >
                              Start Session
                            </a>
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td data-label="DOB" style={{ textAlign: 'center' }}>
                          {formatDate(client.DOB)}
                        </td>
                        <td data-label="MRN" style={{ textAlign: 'center' }}>
                          {client.ta_mrn}
                        </td>
                        {activeButton !== 'My Clients' && (
                          <td
                            data-label="Provider"
                            style={{ textAlign: 'center' }}
                          >
                            {client.PROVIDER}
                          </td>
                        )}
                        <td data-label="Status" style={{ textAlign: 'center' }}>
                          {client.STATUS}
                        </td>
                        {selectedFields.includes('Marital Status') && (
                          <td style={{ textAlign: 'center' }}>
                            {client.MARITAL_STATUS}
                          </td>
                        )}
                        {selectedFields.includes('Gender') && (
                          <td
                            data-label="Gender"
                            style={{ textAlign: 'center' }}
                          >
                            {client.GENDER}
                          </td>
                        )}
                        {selectedFields.includes('Insurance - Primary') && (
                          <td
                            data-label="Insurance - Primary"
                            style={{ textAlign: 'center' }}
                          >
                            {client.PRIMINSURANCE}
                          </td>
                        )}
                        {selectedFields.includes('Insurance - Secondary') && (
                          <td
                            data-label="Insurance - Secondary"
                            style={{ textAlign: 'center' }}
                          >
                            {client.SECINSURANCE}
                          </td>
                        )}
                        {selectedFields.includes(
                          'Questionnaire Cancel Details'
                        ) && (
                          <td
                            data-label="Questionnaire Cancel Details"
                            style={{ textAlign: 'center' }}
                          >
                            {cancellationDetails}
                          </td>
                        )}{' '}
                        {/* Add more conditional columns here */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            <div className="mobile-client-list">
              {displayedClients.map((client) => (
                <div
                  key={client.ta_mrn + client.provider_id}
                  className="mobile-client-card"
                >
                  {/* Client Header Section */}
                  <div
                    onClick={() => toggleClientDetail(client.ta_mrn)}
                    className="client-name"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>
                      {client.FIRSTNAME} {client.LASTNAME}
                    </span>
                    {expandedRows[client.ta_mrn] && (
                      <div
                        className="centered-radio"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          type="radio"
                          name="clientSelection"
                          checked={selectedClientId === client.ta_mrn}
                          onChange={() => handleRadioClick(client)}
                          onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                        />
                      </div>
                    )}
                  </div>

                  {/* Expandable Client Details Section */}
                  <div
                    className={`client-details ${
                      expandedRows[client.ta_mrn] ? '' : 'hide'
                    }`}
                  >
                    <p>Provider: {client.PROVIDER}</p>
                    <p>Email: {client.EMAIL}</p>
                    <p>Phone: {client.PHONE1}</p>
                    {/* Include additional details as needed */}
                  </div>
                </div>
              ))}
            </div>

            {showModal && (
              <div className="modal">
                <div className="modal-content">
                  {/* Section for the intro text and radio buttons */}
                  <div className="scrollable-content">
                    {' '}
                    {/* This div will contain the scrollable content */}
                    <p className="intro-text">Please select an option below:</p>
                    <form>
                      {selectedClientData &&
                        selectedClientData.HRNS_CLIENT !== 1 &&
                        selectedClientData.STATUS !== 'Inactive' && (
                          <label
                            className={`radio-label ${
                              modalSelection === 'HRNS'
                                ? 'selected'
                                : modalSelection === ''
                                ? ''
                                : 'not-selected'
                            }`}
                          >
                            <input
                              className="radio-button"
                              type="radio"
                              value="HRNS"
                              checked={modalSelection === 'HRNS'}
                              onChange={() =>
                                handleRadioButtonChange(
                                  selectedClientData,
                                  'HRNS'
                                )
                              }
                            />
                            High Risk No Show (HRNS)
                          </label>
                        )}
                      {selectedClientData &&
                        selectedClientData.DISCHARGED_BY_LETTER !== 1 &&
                        selectedClientData.STATUS !== 'Inactive' && (
                          <label
                            className={`radio-label ${
                              modalSelection === 'DischargeLetter'
                                ? 'selected'
                                : modalSelection === ''
                                ? ''
                                : 'not-selected'
                            }`}
                          >
                            <input
                              className="radio-button"
                              type="radio"
                              value="DischargeLetter"
                              checked={modalSelection === 'DischargeLetter'}
                              onChange={() =>
                                handleRadioButtonChange(
                                  selectedClientData,
                                  'DischargeLetter'
                                )
                              }
                            />
                            Send Discharge Letter (Not Related to AWOL)
                          </label>
                        )}
                    </form>
                    {modalSelection === 'HRNS' && (
                      <div className="confirmation-section">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: confirmationPopupContent.replace(
                              /\n/g,
                              '<br>'
                            ),
                          }}
                        />
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            id="hrnsCheckbox"
                            name="hrnsConfirmation"
                            value="confirmed"
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="hrnsCheckbox">
                            I have read the disclosure, and I am aware of the
                            implications of making this request.
                          </label>
                        </div>
                      </div>
                    )}
                    {modalSelection === 'DischargeLetter' && (
                      <div className="confirmation-section">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: confirmationPopupContent.replace(
                              /\n/g,
                              '<br>'
                            ),
                          }}
                        />
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            id="dischargeCheckbox"
                            name="dischargeConfirmation"
                            value="confirmed"
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="dischargeCheckbox">
                            I have read the disclosure, and I have completed the
                            items listed above that need to be done prior to
                            submission and I am aware of the implications of
                            making this request.
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Section for the buttons */}
                  <div className="modal-button-container">
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false); // This hides the modal
                        setSelectedClientId(null); // This resets the selected radio button
                      }}
                      className="modal-button go-back-button"
                    >
                      Go Back
                    </button>

                    {isCheckboxChecked && (
                      <button
                        type="button"
                        onClick={() => handleModalSubmit(selectedClientData)}
                        className="modal-button submit-button"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {!LocalLoading && !searchAPICall && (
              <>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  // count={displayedClients.length} // Adjust this to the length of your filtered/sorted data
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className="container-total-clients">
                  Unique Clients: {uniqueClientCount}{' '}
                </div>
              </>
            )}

            {confirmationModalOpen && (
              <div className="confirmation-modal-backdrop">
                <div className="confirmation-modal">
                  <p>
                    In order to make this client "{selectedStatus}" please
                    confirm that you have done the following:
                  </p>
                  <ul>
                    <li>Written a closing summary in TA as a quick note</li>
                    <li>Closed the status in TA to {selectedStatus}</li>
                  </ul>
                  <button
                    onClick={handleConfirmSubmit}
                    className="modal-button submit-button"
                  >
                    I ACKNOWLEDGE I COMPLETED THESE STEPS
                  </button>
                  <button
                    onClick={handleCancelConfirmation}
                    className="modal-button cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {/* Rosters Tab Content */}
        {activeTab === 1 && <Rosters key={'rosters'} />}

        {/* DIP Tab Content */}
        {activeTab === 2 && <DIP key={'dip'} />}

        {activeTab === 3 && <Questionaires key={'questionnaires'} />}
      </div>
    </div>
  );
}
