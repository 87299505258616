import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;
// const API_URL = "http://localhost:8080/api/";
// const API_URL_USER = "http://localhost:8080/api/";
const createNotification = (body) =>
  axios.post(`${API_URL}notifications`, body, { headers: authHeader() });

const getNotifications = () => {
  return axios.get(API_URL + 'notifications', { headers: authHeader() });
};

const getNotificationsCount = () => {
  return axios.get(API_URL + 'notificationsCount', { headers: authHeader() });
};

const markRead = (notification_id) => {
  return axios.post(
    API_URL + 'notifications/markRead',
    { notification_id },
    { headers: authHeader() }
  );
};

const deleteNotification = (notification_id) => {
  return axios.post(
    API_URL + 'notifications/delete',
    { notification_id },
    { headers: authHeader() }
  );
};

// eslint-disable-next-line
export default {
  createNotification,
  getNotifications,
  markRead,
  deleteNotification,
  getNotificationsCount,
};
