import React, { useState, useEffect, useCallback, memo } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  Typography,
  Slider,
} from '@mui/material';
import { clearLoading, setLoading } from '../../actions/loading';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { getUserRoleInfo } from '../../common/utility';
import '../../styles/ProviderMetrics.css';
import userService from '../../services/user.service';

const TabLabelWithDescription = ({ title, description }) => (
  <div
    style={{
      textAlign: 'center',
      paddingLeft: '20px', // Padding on the left
      paddingRight: '20px', // Padding on the right
    }}
  >
    <div
      style={{
        fontSize: '1rem',
        fontWeight: 'bold',
        marginTop: '20px',
      }}
    >
      {title}
    </div>
    <div
      style={{
        fontFamily: 'Cursive',
        fontSize: '0.9rem',
        fontWeight: 'bold',
      }}
      className="tabDescription"
    >
      {description}
    </div>
  </div>
);

const TeamMetrics = () => {
  const [filter, setFilter] = useState('All'); // Filter state
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [showActiveUsers, setShowActiveUsers] = useState(true);
  const [viewBoth, setViewBoth] = useState(false);
  const [yearRange, setYearRange] = useState([2013, new Date().getFullYear()]);
  // The below have introduced a new debouncedYearRange
  const [debouncedYearRange, setDebouncedYearRange] = useState(yearRange);
  const [providerFilter, setProviderFilter] = useState('AllProviders');
  const [teamMembers, setTeamMembers] = useState();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const [counts, setCounts] = useState({
    total: 0,
    providers: 0,
    admins: 0,
    careCoordinators: 0,
  });

  function calculateFontSize(name) {
    const baseSize = 18; // Start with a slightly smaller base size
    const maxLength = 18; // Reduce the length threshold
    const minSize = 14; // Set a smaller minimum font size
    return name?.length > maxLength
      ? Math.max((baseSize * maxLength) / name?.length, minSize)
      : baseSize;
  }

  const displayCounts = () => {
    switch (filter) {
      case 'Admin': // Admin
        return `Admin: ${counts.admins}`;
      case 'Provider': // Providers
        return `Providers: ${counts.providers}`;
      case 'CareCoordinator': // Care Coordinators
        return `Care Coordinators: ${counts.careCoordinators}`;
      default: // All
        return `Total: ${counts.total} | Providers: ${counts.providers} | Admins: ${counts.admins} | Care Coordinators: ${counts.careCoordinators}`;
    }
  };

  const toggleViewBoth = () => {
    setViewBoth((prev) => !prev);
  };

  const NameTypography = ({ name }) => {
    const fontSize = calculateFontSize(name);
    return (
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        style={{ textAlign: 'center', fontSize: `${fontSize}px` }}
      >
        {name}
      </Typography>
    );
  };

  const RoleAvatar = ({ user }) => {
    const { label, color } = getUserRoleInfo(user);

    return (
      <Avatar
        style={{
          backgroundColor: color,
          color: 'white',
          fontSize: '0.75rem',
          width: 30,
          height: 30,
        }}
      >
        {label ? label[0] : 'U'}
      </Avatar>
    );
  };

  const handleProviderFilterChange = (event) => {
    setProviderFilter(event.target.value);
  };

  const handleSliderChange = (event, newValue) => {
    setYearRange(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getAdjustedTabIndex = (baseIndex) => {
    if (!showActiveUsers && baseIndex >= 2) {
      // If the "Professional Upkeep" tab is usually at index 2 and it's not shown,
      // decrease the index of all tabs originally after it by 1.
      return baseIndex - 1;
    }
    return baseIndex;
  };

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedYearRange(yearRange);
    }, 500); // Adjust the delay as needed

    handler();

    // Cleanup function to cancel debounce if the component re-renders before delay
    return () => {
      handler.cancel();
    };
  }, [yearRange]);

  // Trigger API call based on debouncedYearRange (only when it updates)
  useEffect(() => {
    try {
      showLoading();
      userService
        .getTeamMembers({
          showActive: showActiveUsers,
          showBoth: viewBoth,
          providerFilter: providerFilter,
          roleFilter: filter, // Passing here the role filter
          yearRange: debouncedYearRange,
        })
        .then((response) => {
          setTeamMembers([...response?.data?.teamMembers]);
          setCounts({
            total: response?.data?.counts?.total,
            providers: response?.data?.counts?.providers,
            admins: response?.data?.counts?.admins,
            careCoordinators: response?.data?.counts?.careCoordinators,
          });
          hideLoading();
        });
    } catch (error) {
      console.log('error:', error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedYearRange, showActiveUsers, filter, viewBoth, providerFilter]);

  const handleFilterChange = (event) => {
    const newFilter = event.target.value;
    setFilter(newFilter); // Update the filter state with the new value
    setProviderFilter(undefined); // setting provider filter here undefined
  };

  return (
    <>
      <React.Fragment>
        <div className="mainContainer">
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'sticky',
              top: 40,
              zIndex: 1100,
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable auto tabs example"
              style={{
                paddingTop: '1px',
                paddingBottom: '1px',
                background: '#F5F5F5',
                display: 'flex', // Ensure the tabs are aligned as you want (optional)
                justifyContent: 'center', // Center the tabs in the tab bar (optional)
                marginTop: '-80px',
              }}
            >
              <Tab
                label={
                  <TabLabelWithDescription
                    title="TimeSpan"
                    description="View PsychCare Team Members Over the Years"
                  />
                }
                className={
                  activeTab === getAdjustedTabIndex(0)
                    ? 'customTabSelected'
                    : ''
                }
                classes={{ root: 'customTabRoot' }}
              />

              <Tab
                label={<TabLabelWithDescription title="" description="" />}
                className={
                  activeTab === getAdjustedTabIndex(1)
                    ? 'customTabSelected'
                    : ''
                }
                v
                classes={{ root: 'customTabRoot' }}
              />

              <Tab
                label={<TabLabelWithDescription title="" description="" />}
                className={
                  activeTab === getAdjustedTabIndex(2)
                    ? 'customTabSelected'
                    : ''
                }
                classes={{ root: 'customTabRoot' }}
              />

              <Tab
                label={<TabLabelWithDescription title="" description="" />}
                className={
                  activeTab === getAdjustedTabIndex(3)
                    ? 'customTabSelected'
                    : ''
                }
                classes={{ root: 'customTabRoot' }}
              />

              <Tab
                label={<TabLabelWithDescription title=" " description="" />}
                className={
                  activeTab === getAdjustedTabIndex(4)
                    ? 'customTabSelected'
                    : ''
                }
                classes={{ root: 'customTabRoot' }}
              />
            </Tabs>
          </Box>

          <div
            style={{ display: 'flex', alignItems: 'center', margin: '20px' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginRight: '20px',
              }}
            >
              {/* Role Key Legend */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '20px',
                }}
              >
                <Typography variant="caption" display="block" gutterBottom>
                  Role Key:
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: '#f44336',
                      color: 'white',
                      fontSize: '0.75rem',
                      width: 20,
                      height: 20,
                      marginRight: '5px',
                    }}
                  >
                    A
                  </Avatar>
                  Admin
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: '#4caf50',
                      color: 'white',
                      fontSize: '0.75rem',
                      width: 20,
                      height: 20,
                      marginRight: '5px',
                    }}
                  >
                    P
                  </Avatar>
                  Provider
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: '#2196f3',
                      color: 'white',
                      fontSize: '0.75rem',
                      width: 20,
                      height: 20,
                      marginRight: '5px',
                    }}
                  >
                    C
                  </Avatar>
                  Care Coordinator
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {/* The below is the case for both active and non Active*/}
                <FormControlLabel
                  control={
                    <Switch
                      checked={viewBoth}
                      onChange={toggleViewBoth}
                      name="viewBoth"
                    />
                  }
                  label={
                    viewBoth ? (
                      'View BOTH Active & Inactive Team Members'
                    ) : (
                      <span>
                        View Active{' '}
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                          OR
                        </span>{' '}
                        Inactive Team Members
                      </span>
                    )
                  }
                  style={{ marginBottom: '1px', marginLeft: '4px' }} // Adjusted margins for alignment and spacing
                />

                {!viewBoth && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showActiveUsers}
                        onChange={() =>
                          setShowActiveUsers(
                            (activeProviders) => !activeProviders
                          )
                        }
                        name="showActiveProviders"
                      />
                    }
                    label={
                      showActiveUsers
                        ? 'Active Team Members'
                        : 'Inactive Team Members'
                    }
                    style={{ margin: 0 }}
                  />
                )}
              </div>

              {/* Right Column: Role Filter Dropdown */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginRight: '20px',
                }}
              >
                <FormControl style={{ width: 200, marginRight: '20px' }}>
                  <InputLabel id="role-select-label">Filter by Role</InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={filter}
                    label="Filter by Role"
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="All">All Team Members</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Provider">Providers</MenuItem>
                    <MenuItem value="CareCoordinator">
                      Care Coordinators
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* Conditional Provider Role Filter Dropdown */}
                {filter === 'Provider' && (
                  <FormControl style={{ width: 200 }}>
                    <InputLabel id="provider-role-select-label">
                      Provider Role
                    </InputLabel>
                    <Select
                      labelId="provider-role-select-label"
                      id="provider-role-select"
                      value={providerFilter}
                      label="Provider Role"
                      onChange={handleProviderFilterChange}
                    >
                      <MenuItem value="AllProviders">All Providers</MenuItem>
                      <MenuItem value="NonSupervisees">
                        Non-Supervisees
                      </MenuItem>
                      <MenuItem value="Supervisees">Supervisees</MenuItem>
                      <MenuItem value="Supervisors">Supervisors</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </div>

              {/* Counts display */}

              <div>
                <div>
                  <Typography
                    variant="body2"
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {displayCounts(filter)}
                  </Typography>
                </div>
              </div>
            </div>

            <Box sx={{ marginLeft: 'auto', width: '300px' }}>
              <Typography gutterBottom>
                Filter by Team Member Start Year
              </Typography>
              <Slider
                value={yearRange}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                min={2013}
                max={new Date().getFullYear()}
                marks={[
                  { value: 2013, label: '2013' },
                  { value: new Date().getFullYear(), label: 'Current' },
                ]}
                sx={{ width: '300px' }} // You can adjust the width as needed
              />
            </Box>
          </div>

          <Grid container spacing={2}>
            {teamMembers?.map((user, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
                xl={2}
                key={`${user.id}-${index}`}
              >
                <Card style={{ position: 'relative' }}>
                  <div style={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={
                        user.imageUrl ||
                        'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg'
                      }
                      alt="User Image"
                      style={{
                        width: '100%',
                        height: 'auto',
                        minHeight: '140px', // Set minimum height
                        maxHeight: '200px', // Set maximum height
                      }}
                      onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop in case the default image fails as well
                        e.target.src =
                          'https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg';
                      }}
                    />

                    <div
                      style={{
                        position: 'absolute',
                        top: 8,
                        left: 8,
                        width: 30,
                        height: 30,
                      }}
                    >
                      {' '}
                      {/* Adjust position as needed */}
                      <RoleAvatar user={user} />
                    </div>
                  </div>
                  <CardContent>
                    <NameTypography name={user.username} />
                  </CardContent>
                </Card>
              </Grid>
            ))}

            {/* If there are no team members then */}
            {teamMembers?.length === 0 && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{ textAlign: 'center', marginTop: '20px' }}
                >
                  No results found based on current filters.
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      </React.Fragment>
    </>
  );
};
export default memo(TeamMetrics);
