import React, { memo } from 'react';
import * as MUIv5 from '@mui/material';
import { Col, Row } from 'reactstrap';
import { isUserCareCoordinator } from '../../../common/utility.js'; // Import UserService

const MenuProps = { PaperProps: { className: 'customMenuPaper' } };

const resultOptions = ['Information Only Inquiry', 'Referred Out'];
const reasonOptions = [
  'Cost',
  'Fully-Licensed Only',
  'In-Office Only Request',
  'Insurance - Related',
  'Not Appropriate for VS',
  'Psychologist Only',
  'Speciality - Not Offering',
  'Time - No Availability',
  'Time - Declined Times Offered',
  'Other',
];

const InQuirySchedulingModal = ({
  openLogNonScheduling,
  handleCloseOpenLogNonScheduling,
  selectedProviders,
  handleChangeResult,
  handleChange,
  providersOptions,
  selectedResult,
  selectedReason,
  handleChangeReason,
  createLogNonScheduling,
  reasonValue,
  setReasonValue,
  selectedProvidersNonScheduling,
  handleChangeNonScheduling,
  users,
  setOpenLogNonScheduling,
}) => {
  return (
    <MUIv5.Modal
      open={openLogNonScheduling}
      onClose={handleCloseOpenLogNonScheduling}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MUIv5.Box className="modalStyle">
        <MUIv5.Typography id="modal-modal-title" variant="h6" component="h2">
          What were the result of the inquiry?
        </MUIv5.Typography>

        <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
          <MUIv5.InputLabel id="demo-multiple-checkbox-label">
            Care Coordinator
          </MUIv5.InputLabel>
          <MUIv5.Select
            labelId="demo-multiple-checkbox-label"
            name="care_coordinators"
            value={selectedProviders}
            onChange={handleChange}
            input={<MUIv5.OutlinedInput label="Care Coordinators" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {providersOptions
              .filter((obj) => isUserCareCoordinator(obj))
              .map((obj) => (
                <MUIv5.MenuItem key={obj.username} value={obj.username}>
                  <MUIv5.Checkbox
                    checked={selectedProviders.includes(obj.username)}
                  />
                  <MUIv5.ListItemText primary={obj.username} />
                </MUIv5.MenuItem>
              ))}
          </MUIv5.Select>
        </MUIv5.FormControl>

        <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
          <MUIv5.InputLabel id="demo-multiple-checkbox-label">
            Result
          </MUIv5.InputLabel>
          <MUIv5.Select
            labelId="demo-multiple-checkbox-label"
            name="apptTypes"
            value={selectedResult}
            onChange={handleChangeResult}
            input={<MUIv5.OutlinedInput label="apptTypes" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {resultOptions.map((masterObj) => (
              <MUIv5.MenuItem key={masterObj} value={masterObj}>
                <MUIv5.Checkbox
                  checked={selectedResult.indexOf(masterObj) > -1}
                />
                <MUIv5.ListItemText primary={masterObj} />
              </MUIv5.MenuItem>
            ))}
          </MUIv5.Select>
        </MUIv5.FormControl>

        {selectedResult.length > 0 &&
          selectedResult[0] !== 'Information Only Inquiry' && (
            <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
              <MUIv5.InputLabel id="demo-multiple-checkbox-label">
                Reason
              </MUIv5.InputLabel>
              <MUIv5.Select
                labelId="demo-multiple-checkbox-label"
                name="apptTypes"
                value={selectedReason}
                onChange={handleChangeReason}
                input={<MUIv5.OutlinedInput label="apptTypes" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {reasonOptions.map((masterObj) => (
                  <MUIv5.MenuItem key={masterObj} value={masterObj}>
                    <MUIv5.Checkbox
                      checked={selectedReason.indexOf(masterObj) > -1}
                    />
                    <MUIv5.ListItemText primary={masterObj} />
                  </MUIv5.MenuItem>
                ))}
              </MUIv5.Select>
            </MUIv5.FormControl>
          )}

        {selectedReason.length > 0 && selectedReason[0] === 'Other' && (
          <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
            <MUIv5.TextField
              fullWidth
              autoComplete="off"
              variant="outlined"
              id="static_doxy_link"
              label="Please specify..."
              defaultValue={reasonValue}
              onChange={(e) => {
                setReasonValue(e.target.value);
              }}
            />
          </MUIv5.FormControl>
        )}

        {
          <MUIv5.FormControl sx={{ width: 320, margin: 2 }}>
            <MUIv5.InputLabel id="demo-multiple-checkbox-label">
              What provider(s) did the client ask for?
            </MUIv5.InputLabel>
            <MUIv5.Select
              multiple
              labelId="demo-multiple-checkbox-label"
              name="providers"
              value={selectedProvidersNonScheduling}
              onChange={handleChangeNonScheduling}
              input={<MUIv5.OutlinedInput label="Providers" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {users.map((obj) => (
                <MUIv5.MenuItem key={obj.username} value={obj.username}>
                  <MUIv5.Checkbox
                    checked={
                      selectedProvidersNonScheduling.findIndex((masterObj) => {
                        return masterObj === obj.username;
                      }) > -1
                    }
                  />
                  <MUIv5.ListItemText primary={obj.username} />
                </MUIv5.MenuItem>
              ))}
            </MUIv5.Select>
          </MUIv5.FormControl>
        }

        <Row xs="12" sx={{ margin: 2 }}>
          <Col xs="6"></Col>
          <Col xs="6">
            <Row>
              <Col>
                <MUIv5.Button
                  variant="contained"
                  onClick={async (e) => {
                    setOpenLogNonScheduling(false);
                  }}
                >
                  CANCEL
                </MUIv5.Button>
              </Col>
              <Col>
                <MUIv5.Button
                  variant="contained"
                  disabled={
                    !selectedResult.length || // Ensures a selection has been made in selectedResult
                    (selectedResult[0] === 'Information Only Inquiry' &&
                      selectedProviders.length === 0) || // For 'Information Inquiry Only', ensures a care coordinator is selected
                    (selectedResult[0] !== 'Information Only Inquiry' &&
                      !selectedReason.length) || // For other selections, ensures selectedReason is not empty
                    (selectedReason[0] === 'Other' && !reasonValue) // For 'Other' reason, ensures reasonValue is not empty
                  }
                  onClick={async (e) => {
                    createLogNonScheduling();
                  }}
                >
                  SUBMIT
                </MUIv5.Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </MUIv5.Box>
    </MUIv5.Modal>
  );
};

export default memo(InQuirySchedulingModal);
