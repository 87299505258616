import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/rates/`;

// Function to get all billing rates
const getAllBillingRates = () => {
  return axios.get(API_URL, { headers: authHeader() });
};

// Function to get fee ranges based on selections
const getFeeRanges = (filters) => {
  return axios.post(API_URL + 'feeranges', filters, { headers: authHeader() });
};

// Function to create a new billing rate
const createBillingRate = (rateData) => {
  return axios.post(API_URL, rateData, { headers: authHeader() });
};

// Function to update an existing billing rate
const updateBillingRate = (id, rateData) => {
  return axios.put(API_URL + id, rateData, { headers: authHeader() });
};

// Function to delete a billing rate
const deleteBillingRate = (id) => {
  return axios.delete(API_URL + id, { headers: authHeader() });
};

// eslint-disable-next-line
export default {
  getAllBillingRates,
  getFeeRanges,
  createBillingRate,
  updateBillingRate,
  deleteBillingRate,
};
