import React, { memo } from 'react';

const CustomAlert = ({ setShowCustomAlert }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <p style={{ color: 'red', fontSize: '1.5rem' }}>
          Appointment already exists for this provider at the specified date and
          time.
        </p>
        <button
          onClick={() => setShowCustomAlert(false)}
          style={{
            padding: '10px 20px',
            fontSize: '1rem',
            margin: '10px auto',
            display: 'block',
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default memo(CustomAlert);
