import React, { useEffect, useState } from 'react';
import sheetServices from '../services/sheet.services';

const Payroll = () => {
  const [googleSheetEmbedUrl, setGoogleSheetEmbedUrl] = useState('');
  useEffect(() => {
    sheetServices.getSheetDetail().then((res) => {
      if (res?.data?.sheetDetail?.sheet_id_payroll) {
        setGoogleSheetEmbedUrl(
          `https://docs.google.com/spreadsheets/d/${res?.data?.sheetDetail?.sheet_id_payroll}/edit?usp=sharing`
        );
      }
    });
  }, []);
  // Replace the 'src' attribute with the embed URL of your Google Sheet

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // This sets the height of the container to the full viewport height
      width: '100%' // This ensures the container stretches across the full width
    }}>

      <iframe
        title="Payroll Google Sheet"
        src={googleSheetEmbedUrl}
        style={{
          width: '100%', // Takes the full width of the container
          height: '90vh', // Adjust this value to expand the height of the iframe to fit your screen better
          border: 'none' // Removes the border around the iframe
        }}
      ></iframe>
    </div>
  );
};

export default Payroll;
