import React, { memo } from 'react';

function Input({ label, type, name, id, value, onChange, placeholder }) {
  return (
    <div className="">
      <label className="block text-sm font-normal leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-0.5">
        <input
          type={type}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          className="h-10  block w-full border border-gray-300  hover:border-sky-200 focus:border-sky-500 py-1.5 px-1 text-gray-900 shadow-sm ring-0 placeholder:text-gray-400  sm:text-sm sm:leading-6 outline-none	"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

export default memo(Input);
