// TabLabelWithBadge.js

import React from 'react';
import { useSelector } from 'react-redux';
import '../../styles/TabLabelWithBadge.css'; // Make sure the path to your CSS file is correct

const TabLabelWithBadge = ({ title, description }) => {
  // Access the badge numbers from the Redux store
  const { blackBadgeNumber, redBadgeNumber } = useSelector(
    (state) => state.clients.dipBadgeNumbers
  );

  return (
    <div style={{ textAlign: 'center' }}>
      <div className="badge-container">
        <span className="badge black-badge">{blackBadgeNumber}</span>
        <span className="badge red-badge">{redBadgeNumber}</span>
      </div>
      <div style={{ fontSize: '1rem' }}>{title}</div>
      <div className="tabDescription">{description}</div>
    </div>
  );
};

export default TabLabelWithBadge;
