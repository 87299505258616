import React, { memo } from 'react';
import {
  additionalLanguagesSpokenOptions,
  licensedInOtherStatesOptions,
} from '../../common/utility';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const options = [
  { title: 'Any' },
  { title: 'Fully Licensed' },
  { title: 'Non-Fully Licensed' },
  { title: 'Doctoral Level' },
  { title: 'Master Level' },
];

const SchedulingStep05 = (props) => {
  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const clone = { ...props?.step05FormValues };
    clone[name] = value;
    props.setStep05FormValues(clone);
  };

  const onChangeLicense = (event, values) => {
    // Check if 'Any' is in the selected values
    const isAnyFound = values.findIndex((obj) => obj.title === 'Any');

    // If 'Any' is found and there are more values, remove 'Any'
    if (isAnyFound !== -1 && values.length > 1) {
      values = values.filter((item) => item.title !== 'Any');
    }

    const propsClone = { ...props.step05FormValues };
    propsClone.license = values;

    props.setStep05FormValues(propsClone);
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid 
        container spacing={3}
        style={{ marginTop: '-115px'}}
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Provider Gender
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="gender"
                value={props?.step05FormValues?.gender}
                label="How did they hear about us?"
                onChange={handleChange}
              >
                <MenuItem value={'Any'}>Any</MenuItem>
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Female'}>Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Provider Age
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="provider_age"
                value={props?.step05FormValues?.provider_age}
                label="Provider Age"
                onChange={handleChange}
              >
                <MenuItem value={'Any'}>Any</MenuItem>
                <MenuItem value={'Under 30'}>Under 30</MenuItem>
                <MenuItem value={'30-49'}>30-49</MenuItem>
                <MenuItem value={'Over 50'}>Over 50</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Provider Race
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="provider_race"
                value={props?.step05FormValues?.provider_race}
                label="Provider Race"
                onChange={handleChange}
              >
                <MenuItem value={'Any'}>Any</MenuItem>
                <MenuItem value={'White'}>White</MenuItem>
                <MenuItem value={'African American'}>African American</MenuItem>
                <MenuItem value={'Asian'}>Asian</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Provider Language
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="provider_language"
                value={props?.step05FormValues?.provider_language}
                label="Provider Language"
                onChange={handleChange}
              >
                <MenuItem value={'Any'}>Any</MenuItem>
                {additionalLanguagesSpokenOptions.map((l, index) => (
                  <MenuItem value={l} key={l + index}>
                    {l}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="psyPact-provider-label">
                PsyPact Provider
              </InputLabel>
              <Select
                labelId="psyPact-provider-label"
                name="psyPactProvider"
                value={props?.step05FormValues?.psyPactProvider || ''}
                label="PsyPact Provider"
                onChange={handleChange}
              >
                <MenuItem value="">
                  {' '}
                  {/* Blank option */}
                  <em>N/A</em>
                </MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Provider Other Licensed State
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="provider_other_licensed_state"
                value={props?.step05FormValues?.provider_other_licensed_state}
                label="Provider Other Licensed State"
                onChange={handleChange}
              >
                {['MD Only', ...licensedInOtherStatesOptions].map(
                  (obj, index) => {
                    return (
                      <MenuItem key={index} value={obj}>
                        {obj}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={options}
              getOptionLabel={(option) => option.title}
              value={props?.step05FormValues?.license ?? [{ title: 'Any' }]}
              onChange={onChangeLicense}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="License | Degree"
                  ref={props.licenseRef} // Add ref here
                  // error={props.step06FormValues.services ? false : true}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default memo(SchedulingStep05);
