import React, { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from 'reactstrap';

function DialogComponent({ open, setOpen, maxWidth, content, title, buttons }) {
  return (
    <>
      {open && (
        <Dialog
          maxWidth={maxWidth || 'sm'}
          fullWidth
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            {buttons?.map((v, k) => (
              <Button onClick={v?.onClick} color="primary">
                {v?.label}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default memo(DialogComponent);
