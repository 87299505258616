import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ErrorPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <ErrorIcon
        sx={{
          color: 'error.main',
          fontSize: 100,
        }}
      />
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          color: 'rgb(46, 109, 164)',
        }}
      >
        We Apologize for the Inconvenience
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: 1,
          textAlign: 'center',
          color: 'rgb(46, 109, 164)',
        }}
      >
        We’re sorry, but our appointment request form is currently experiencing
        difficulties. Your well-being is important to us, so please don’t
        hesitate to reach out directly.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: 1,
          textAlign: 'center',
          color: 'rgb(46, 109, 164)',
        }}
      >
        You can call our office at <strong>410-343-9756, Extension 1</strong>.
        Our care coordinators are available Monday through Friday, from 9 AM to
        4:45 PM, and you can also leave a voicemail after hours.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: 1,
          textAlign: 'center',
          color: 'rgb(46, 109, 164)',
        }}
      >
        Thank you for your understanding, and we look forward to assisting you.
      </Typography>
    </Box>
  );
};

export default ErrorPage;
