import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

// The below loading dialog is a lighter dialog version to be displayed with a message
const LoadingDialog = ({ open, message }) => (
  <Dialog
    open={open}
    aria-labelledby="loading-dialog-title"
    style={{ zIndex: 1500 }} // Ensure the dialog is on top
  >
    <DialogTitle id="loading-dialog-title">Please Wait</DialogTitle>
    <DialogContent>
      <div className="loading-dialog-content" style={{ textAlign: 'center' }}>
        <p
          style={{
            color: 'red',
            fontSize: '1.5rem', // Adjust as needed
            fontWeight: 'bold',
          }}
        >
          {message}
        </p>
        <div className="spinner"></div>
      </div>
    </DialogContent>
  </Dialog>
);

export default LoadingDialog;
