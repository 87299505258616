import React, { memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isUserCareCoordinator } from '../../../common/utility';
import '../../../../src/App.css';
const MenuProps = { PaperProps: { className: 'customMenuPaper' } };

const apptTypes = [
  'Remote Then Office',
  'Remote Only',
  'Office Only',
  'Office Then Remote',
];

const AddSchedulingModal = ({
  open,
  handleClose,
  selectedAvailableSlot,
  existingClientTurning18,
  selectedDuration,
  handleChangeDuration,
  durations,
  selectedStartDate,
  setSelectedStartDate,
  testingOrTherapyClientComingToOffice,
  getTherapyDocsDueSection,
  selectedApptType,
  selectedEndDate,
  handleSelectedEndDate,
  setSelectedAvailableSlot,
  selectedOfficeLocation,
  selectedProviders,
  handleChange,
  handleChangeApptType,
  providersOptions,
  handleChangeOfficeLocation,
  officeLocations,
  step01FormValues,
  isValidEmail,
  exceptionText,
  setExceptionText,
  setTaMrn,
  createSchedule,
  exceptionChecked,
  setExceptionChecked,
  selectedDate,
  setSelectedDate,
  taMrn,
  setOpen,
  nonFilteredData,
  setNonFilteredData,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalStyle">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Scheduling Details
          </Typography>

          {selectedAvailableSlot.isManual && (
            <>
              {!existingClientTurning18() && (
                <FormControl sx={{ width: 320, margin: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    Duration
                  </InputLabel>

                  <Select
                    labelId="demo-multiple-checkbox-label"
                    name="frequency"
                    value={selectedDuration}
                    onChange={handleChangeDuration}
                    input={<OutlinedInput label="duration" />}
                    renderValue={(selected) =>
                      selected?.map((v) => durations[v]).join(', ')
                    }
                    MenuProps={MenuProps}
                  >
                    {Object.keys(durations).map((key, idx) => (
                      <MenuItem key={key} value={key}>
                        <Checkbox
                          checked={selectedDuration.indexOf(key) > -1}
                        />
                        <ListItemText primary={durations[key]} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl sx={{ width: 320, margin: 2 }}>
                <div className="form-group">
                  <input
                    type="datetime-local"
                    className="form-control"
                    name="appointmentDate"
                    value={selectedStartDate}
                    onChange={(event) => {
                      setSelectedStartDate(event.target.value);
                      let selectedAvailableSlotClone = {
                        ...selectedAvailableSlot,
                      };
                      selectedAvailableSlotClone.start_date_time =
                        event.target.value;
                      setSelectedAvailableSlot(selectedAvailableSlotClone);
                    }}
                  />
                </div>
              </FormControl>
              {!existingClientTurning18() && (
                <FormControl
                  sx={{ width: 320, margin: 2 }}
                  // error={formValues['insurances'].length>0?false:true}
                >
                  <div className="form-group">
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="appointmentDate"
                      value={moment(selectedEndDate).format('YYYY-MM-DDTHH:mm')}
                      onChange={handleSelectedEndDate}
                    />
                  </div>
                </FormControl>
              )}
            </>
          )}

          <FormControl
            sx={{ width: 320, margin: 2 }}
            // error={formValues['insurances'].length>0?false:true}
          >
            <InputLabel id="demo-multiple-checkbox-label">
              Care Coordinator Name
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              name="providers"
              value={selectedProviders}
              onChange={handleChange}
              input={<OutlinedInput label="Providers" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {providersOptions
                .filter((obj) => isUserCareCoordinator(obj))
                .map((obj) => (
                  <MenuItem key={obj.username} value={obj.username}>
                    <Checkbox
                      checked={
                        selectedProviders.findIndex((masterObj) => {
                          return masterObj === obj.username;
                        }) > -1
                      }
                    />
                    <ListItemText primary={obj.username} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 320, margin: 2 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Appointment Type
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              name="apptTypes"
              value={selectedApptType}
              onChange={handleChangeApptType}
              input={<OutlinedInput label="apptTypes" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {!testingOrTherapyClientComingToOffice() &&
                apptTypes.map((masterObj) => (
                  <MenuItem key={masterObj} value={masterObj}>
                    <Checkbox
                      checked={selectedApptType.indexOf(masterObj) > -1}
                      disabled={true}
                    />
                    <ListItemText primary={masterObj} />
                  </MenuItem>
                ))}
              {testingOrTherapyClientComingToOffice() &&
                ['Office Only'].map((masterObj) => (
                  <MenuItem key={masterObj} value={masterObj}>
                    <Checkbox
                      checked={selectedApptType.indexOf(masterObj) > -1}
                      disabled={true}
                    />
                    <ListItemText primary={masterObj} />
                  </MenuItem>
                ))}
            </Select>
            {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
          </FormControl>

          {selectedApptType?.length > 0 &&
            !selectedApptType.includes('Remote Only') && (
              <FormControl
                sx={{ width: 320, margin: 2 }}
                // error={formValues['insurances'].length>0?false:true}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Office Location
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  name="officeLocation"
                  value={selectedOfficeLocation}
                  onChange={handleChangeOfficeLocation}
                  input={<OutlinedInput label="apptTypes" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {officeLocations.map((masterObj) => (
                    <MenuItem key={masterObj} value={masterObj}>
                      <Checkbox
                        checked={selectedOfficeLocation.indexOf(masterObj) > -1}
                      />
                      <ListItemText primary={masterObj} />
                    </MenuItem>
                  ))}
                </Select>
                {/* {formValues['insurances'].length == 0 && <FormHelperText>Required field</FormHelperText>} */}
              </FormControl>
            )}

          <FormControl
            sx={{ width: 320, margin: 2 }}
            // error={formValues['insurances'].length>0?false:true}
          >
            <TextField
              required
              autoComplete="off"
              fullWidth
              variant="outlined"
              id="ta_mrn"
              label="TA MRN"
              onChange={(event) => {
                setTaMrn(event.target.value.trimStart());
              }}
              value={taMrn}
            />
          </FormControl>

          {!existingClientTurning18() && (
            <>
              <Row style={{ margin: 2 }}>
                <div style={{ textAlign: 'center', width: '100%' }}>
                  Confirm the following scheduling details:
                </div>
              </Row>

              <Row style={{ margin: 2 }}>
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {step01FormValues?.first_name} {step01FormValues?.last_name} -{' '}
                  {isValidEmail(step01FormValues?.email)}{' '}
                </div>
              </Row>

              <Row style={{ margin: 2 }}>
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    Selected Cal Event:
                  </span>{' '}
                  {selectedApptType.includes('Remote Only')
                    ? 'Remote'
                    : selectedAvailableSlot?.location}
                </div>
              </Row>

              <Row style={{ margin: 2 }}>
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {selectedAvailableSlot?.obj?.username}
                  </span>{' '}
                  -{' '}
                  {`${moment
                    .utc(selectedAvailableSlot?.start_date_time)
                    .format('llll')}`}{' '}
                  -{' '}
                  {`${moment
                    .utc(selectedAvailableSlot?.end_date_time)
                    .format('llll')}`}{' '}
                  [
                  {selectedApptType.includes('Remote Only')
                    ? 'Remote'
                    : selectedAvailableSlot?.location}
                  ] <span style={{ fontWeight: 'bold' }}></span>
                </div>
              </Row>

              {selectedDate && getTherapyDocsDueSection(selectedDate)}
              {!selectedDate && getTherapyDocsDueSection()}
            </>
          )}

          {existingClientTurning18() && (
            <Row style={{ margin: 2 }}>
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {step01FormValues?.first_name} {step01FormValues?.last_name} -{' '}
                {step01FormValues?.email}{' '}
              </div>
            </Row>
          )}

          <Row style={{ margin: 14 }} xs="12">
            <Col xs="6"></Col>
            <Col xs="6">
              {/*<Row style={{right: 0, position: 'absolute'}}>*/}
              <Row>
                <Col xs="3">
                  <Button
                    variant="contained"
                    onClick={async (e) => {
                      setOpen(false);
                    }}
                  >
                    CANCEL
                  </Button>
                </Col>

                {((selectedApptType?.length > 0 &&
                  selectedApptType.includes('Remote Only')) ||
                  (selectedApptType?.length > 0 &&
                    !selectedApptType.includes('Remote Only') &&
                    selectedOfficeLocation[0] !== null)) &&
                  selectedStartDate &&
                  taMrn &&
                  selectedProviders &&
                  selectedProviders.length > 0 &&
                  ((exceptionChecked && exceptionText && selectedDate) ||
                    (!exceptionChecked && !exceptionText && !selectedDate)) && (
                    <Col xs="6">
                      <Button
                        variant="contained"
                        onClick={async (e) => {
                          createSchedule();
                        }}
                      >
                        {existingClientTurning18() ? 'SEND EMAIL' : 'SCHEDULE'}
                      </Button>
                    </Col>
                  )}
              </Row>
              {/*TODO: Donot remove this May be needed in future that is commented*/}
              <Grid
                item
                xs={12}
                style={{ marginBottom: exceptionChecked ? '-5px' : '-40px' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exceptionChecked}
                      onChange={(e) => {
                        setExceptionChecked(e.target.checked);
                        if (e.target.checked === false) {
                          setExceptionText(null);
                          setSelectedDate(null);
                          const nonFilteredDataObject = nonFilteredData;
                          delete nonFilteredDataObject.docsDueDate;
                          delete nonFilteredDataObject.exceptionForDocs;
                          setNonFilteredData({
                            ...nonFilteredDataObject,
                          });
                        }
                      }}
                    />
                  }
                  label="Exception for Due Date for Docs"
                />
                {exceptionChecked && (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ marginBottom: '8px' }}>
                          e.g. Min 24 hours before appointment for therapy and
                          72 hours before a testing appointment
                        </label>
                        <DatePicker
                          variant="inline"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          value={selectedDate}
                          name="custom_date_docs_due"
                          InputAdornmentProps={{ position: 'start' }}
                          onChange={(date) => {
                            if (
                              moment(date).isAfter(
                                selectedAvailableSlot?.start_date_time
                              )
                            ) {
                              setAlertMessage(
                                'You cannot select docs due date after the appointment date.'
                              );
                              setAlertOpen(true);
                            } else {
                              setSelectedDate(date);
                              setNonFilteredData({
                                ...nonFilteredData,
                                docsDueDate: date,
                              });
                            }
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: '100%' }} // Make it full width relative to its container
                        />
                      </div>
                      {selectedDate && (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                        ></Typography>
                      )}
                    </Grid>

                    <InputLabel
                      htmlFor="exception-textarea"
                      style={{ color: '#333', textAlign: 'left' }}
                    >
                      Explanation for Exception
                    </InputLabel>
                    <TextareaAutosize
                      id="exception-textarea"
                      rowsMin={3}
                      required
                      style={{
                        width: '100%',
                        padding: '8px',
                        border: exceptionText
                          ? '1px solid black'
                          : '1px solid red',
                        borderRadius: '4px',
                        textAlign: 'left', // Optionally, to align text inside the textarea to the left
                      }}
                      value={exceptionText}
                      onChange={(e) => {
                        setExceptionText(e.target.value);
                        setNonFilteredData({
                          ...nonFilteredData,
                          exceptionForDocs: exceptionText,
                        });
                      }}
                      // ... other handlers
                    />
                  </React.Fragment>
                )}
              </Grid>
            </Col>
          </Row>
        </Box>
      </Modal>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAlertClose}
          severity="error"
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default memo(AddSchedulingModal);
