import React, { useEffect, useState, memo } from 'react';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const locationOptions = [
  { title: 'Any' },
  { title: 'Silver Spring' },
  { title: 'Columbia' },
  { title: 'Baltimore' },
];

const settingOptions = ['Any Setting', 'In-Person Only', 'Remote Only'];

let MenuITems1 = [
  '2 weeks',
  '4 weeks',
  '8 weeks',
  '3 months',
  '6 months',
  '1 year',
];
let MenuITems2 = [
  '2 weeks',
  '4 weeks',
  '8 weeks',
  '3 months',
  '6 months',
  '1 year',
];
let types = ['All', 'Open', 'Hold'];
let frequencies = ['All', 'Weekly', 'Every_Other_Week', 'Floater'];

const SchedulingStep06 = (props) => {
  const [typeDefaultValue, setTypeDefaultValue] = useState('All');
  const [frequencyDefaultValue, setfrequencyDefaultValue] = useState('All');
  const [selectedSetting, setSelectedSetting] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage] = React.useState('');

  const [defaultValue1, setDefaultValue1] = useState(
    props.serviceTesting ? '3 months' : '8 weeks'
  );
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (props.settingValues?.length > 0) {
      handleChangeSettingFromRiskQuestions(props.settingValues);
    } else if (props.settingValues == null) {
      handleChangeSettingFromRiskQuestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.settingValues]);

  useEffect(() => {
    if (props.serviceTesting === true) {
      setMenuItems([...MenuITems2]);
      setDefaultValue1('3 months');
      const propsClone = { ...props.step06FormValues };
      propsClone.range = '3 months';
      props.setStep06FormValues(propsClone);
    } else {
      setMenuItems([...MenuITems1]);
      setDefaultValue1('8 weeks');
      const propsClone = { ...props.step06FormValues };
      propsClone.range = '8 weeks';
      props.setStep06FormValues(propsClone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceTesting]);

  const onChangeLocation = (event, values) => {
    const isAnyFound = values.findIndex((obj) => {
      return obj.title === 'Any';
    });
    const propsClone = { ...props.step06FormValues };
    propsClone.location = isAnyFound >= 0 ? [{ title: 'Any' }] : values;
    props.setStep06FormValues(propsClone);
  };

  const onChangeRange = (event) => {
    const selectedValue = event.target.value;
    setDefaultValue1(selectedValue);
    const propsClone = { ...props.step06FormValues };
    propsClone.range = selectedValue;
    props.setStep06FormValues(propsClone);
  };

  const onChangeType = (event) => {
    const selectedValue = event.target.value;
    setTypeDefaultValue(selectedValue);
    const propsClone = { ...props.step06FormValues };
    propsClone.type = selectedValue;
    props.setStep06FormValues(propsClone);
  };

  const onChangeFrequency = (event) => {
    const selectedValue = event.target.value;
    setfrequencyDefaultValue(selectedValue);
    const propsClone = { ...props.step06FormValues };
    propsClone.frequency = selectedValue;
    props.setStep06FormValues(propsClone);
  };

  const onChangeDay = (event) => {
    const selectedValue = event.target.value;
    const propsClone = { ...props.step06FormValues };
    propsClone.day = selectedValue;
    props.setStep06FormValues(propsClone);
  };

  const onChangeTime = (event) => {
    const selectedValue = event.target.value;
    const propsClone = { ...props.step06FormValues };
    propsClone.time = selectedValue;
    props.setStep06FormValues(propsClone);
  };
  const handleChangeSettingFromRiskQuestions = (values) => {
    const selectedValues = values;
    setSelectedSetting(selectedValues);

    const propsClone = { ...props.step06FormValues };
    if (selectedValues.length === 0) {
      delete propsClone['setting'];
    } else {
      propsClone.setting = selectedValues;
    }
    props.setStep06FormValues(propsClone);
  };
  const onChangeSetting = (event) => {
    const selectedValues = event.target.value;

    // Enforce the rule that 'In-Person Only' and 'Remote Only' cannot be selected together
    // if (selectedValues.includes('In-Person Only') && selectedValues.includes('Remote Only')) {
    //   setSnackbarMessage('You cannot select both "In-Person Only" and "Remote Only".');
    //   setSnackbarOpen(true);
    //   return;
    // }

    // Enforce the rule that 'Any Setting' can only be selected alone or with 'In-Person Only' or 'Remote Only'
    // if (selectedValues.includes('Any Setting') && selectedValues.length > 2) {
    //   setSnackbarMessage('You must select "Any Setting" alone or with either "Remote Only" or "In-Person Only".');
    //   setSnackbarOpen(true);
    //   return;
    // }

    setSelectedSetting(selectedValues);

    const propsClone = { ...props.step06FormValues };
    if (selectedValues.length === 0) {
      delete propsClone['setting'];
    } else {
      propsClone.setting = selectedValues;
    }
    props.setStep06FormValues(propsClone);
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid 
        container spacing={3}
        style={{ marginTop: '-115px'}}
        >
          {!props.manualEntry && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Day</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={'Any Weekday'}
                  label="Day"
                  onChange={onChangeDay}
                >
                  <MenuItem value={'Any Weekday'}>Any day</MenuItem>
                  <MenuItem value={'Sunday'}>Sunday</MenuItem>
                  <MenuItem value={'Monday'}>Monday</MenuItem>
                  <MenuItem value={'Tuesday'}>Tuesday</MenuItem>
                  <MenuItem value={'Wednesday'}>Wednesday</MenuItem>
                  <MenuItem value={'Thursday'}>Thursday</MenuItem>
                  <MenuItem value={'Friday'}>Friday</MenuItem>
                  <MenuItem value={'Saturday'}>Saturday</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {!props.manualEntry && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Time</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={'Anytime'}
                  label="Time"
                  onChange={onChangeTime}
                >
                  <MenuItem value={'Anytime'}>Anytime</MenuItem>
                  <MenuItem value={'Mornings (8am-Noon)'}>
                    Mornings (8am-Noon)
                  </MenuItem>
                  <MenuItem value={'Early Afternoon (Noon-2pm)'}>
                    Early Afternoon (Noon-2pm)
                  </MenuItem>
                  <MenuItem value={'Later Afternoon (2pm-5pm)'}>
                    Later Afternoon (2pm-5pm)
                  </MenuItem>
                  <MenuItem value={'Evenings (5pm-9pm)'}>
                    Evenings (5pm-9pm)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Setting</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Range"
                multiple
                value={selectedSetting}
                onChange={onChangeSetting}
                ref={props.settingRef} // Add ref here
                renderValue={(selected) => selected.join(', ')}
              >
                {settingOptions.map((it, index) => (
                  <MenuItem key={it + index} value={it}>
                    <Checkbox checked={selectedSetting.indexOf(it) > -1} />
                    <ListItemText primary={it} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {props?.step06FormValues?.setting !== 'Remote Only' && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={locationOptions}
                getOptionLabel={(option) => option.title}
                value={props?.step06FormValues?.location ?? [{ title: 'Any' }]}
                onChange={onChangeLocation}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Cal Avail. Location(s)"
                    id="locationField"
                    ref={props.locationRef} // Add ref here
                    // error={props.step06FormValues.services ? false : true}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Range</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={defaultValue1}
                label="Range"
                onChange={onChangeRange}
              >
                {props.serviceTesting
                  ? menuItems.map((menuItemsObj, index) => {
                      return (
                        <MenuItem value={menuItemsObj} key={index}>
                          {menuItemsObj}
                        </MenuItem>
                      );
                    })
                  : menuItems.map((menuItemsObj, index) => {
                      return (
                        <MenuItem value={menuItemsObj} key={index}>
                          {menuItemsObj}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeDefaultValue}
                label="Type"
                onChange={onChangeType}
              >
                {types.map((typesObj, index) => {
                  return (
                    <MenuItem value={typesObj} key={index}>
                      {typesObj}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo1-simple-select"
                value={frequencyDefaultValue}
                label="Frequency"
                onChange={onChangeFrequency}
              >
                {frequencies.map((frequencyObj, index) => {
                  return (
                    <MenuItem value={frequencyObj} key={index}>
                      {frequencyObj}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="warning"
            onClose={() => setSnackbarOpen(false)}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
};

export default memo(SchedulingStep06);
