import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/auth/`;

// const API_URL = "http://localhost:8080/api/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + 'signup', {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + 'signin', {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};
const loginWithGoogle = (tokenId) => {
  return axios
    .post(API_URL + 'google', {
      tokenId: tokenId,
      // tokenId: JSON.stringify({
      //   token: tokenId
      // })
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = (currentUser) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    return axios
      .post(API_URL + 'signout', {
        id: user.id,
        username: user.username,
      })
      .then((response) => {
        if (response.data.message) {
          localStorage.removeItem('user');
        }
      });
  } else {
    return axios
      .post(API_URL + 'signout', {
        id: currentUser.id,
        username: currentUser.username,
      })
      .then((response) => {
        if (response.data.message) {
          localStorage.removeItem('user');
        }
      });
  }
};

// eslint-disable-next-line
export default {
  register,
  login,
  logout,
  loginWithGoogle,
};
