/* eslint-disable */
import {
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
  SET_NOTIFICATION_COUNT,
} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATION:
      return { ...state, notification: payload };

    case CLEAR_NOTIFICATION:
      return { ...state, notification: null };

    case SET_NOTIFICATION_COUNT:
      return { ...state, count: payload };
    default:
      return state;
  }
}
