/* eslint-disable */
import { SET_DIP_BADGE_NUMBERS } from '../actions/types';

const initialState = {
  // ... other state properties
  dipBadgeNumbers: { black: 0, red: 0 },
};

export default function (state = initialState, action) {
  switch (action.type) {
    // ... other case handlers
    case SET_DIP_BADGE_NUMBERS:
      return {
        ...state,
        dipBadgeNumbers: action.payload,
      };
    // ... other case handlers
    default:
      return state;
  }
}
