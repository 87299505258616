import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Form from 'react-validation/build/form';
import CheckButton from 'react-validation/build/button';
import { login, loginWithGoogle } from '../actions/auth';

const Login = (props) => {
  // The below useEffect is being added for allowing the user to be login through questionnaire
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const questionnaireValue = urlParams.get('questionnaire');
    const isQuestionnaire = questionnaireValue?.toString() === 'true';

    if (isQuestionnaire) {
      localStorage.setItem('isQuestionnaire', true);
    }
  }, []);

  const form = useRef();
  const checkBtn = useRef();
  const [username] = useState('');
  const [password] = useState('');
  const [, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          props.history.push('/personal_info');
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleLoginResponse = (response) => {
    setLoading(true);
    const { credential } = response;
    dispatch(loginWithGoogle(credential))
      .then(() => {
        if (localStorage.getItem('isQuestionnaire')?.toString() === 'true') {
          props.history.push('/clients');
        } else {
          props.history.push('/personal_info');
        }

        /*
        The below I am generating a uniqueId and then
        passing it through channel postMessage. This
        unqiueId is associated with sessionStorage
        to prevent reloading the same window.
        */
        let uniqueId = sessionStorage.getItem('uniqueId');
        if (!uniqueId) {
          uniqueId = Math.random().toString(36).substr(2, 9);
          sessionStorage.setItem('uniqueId', uniqueId);
        }
        const loginChannel = new BroadcastChannel('loginChannel');
        loginChannel.postMessage({ type: 'login', senderId: uniqueId });
        loginChannel.close();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    if (localStorage.getItem('isQuestionnaire')?.toString() === 'true') {
      return <Redirect to="/clients" />;
    } else {
      return <Redirect to="/personal_info" />;
    }
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div
        className="col-md-12"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'flex-start',
        }}
      >
        <div
          className="card card-container"
          style={{
            marginTop: '25vh', // Pushes the card to about 1/3 down the screen
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            width: '100%', // Full width on smaller screens
            maxWidth: '400px', // Max width for the card
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Optional: adding some shadow for better visibility
          }}
        >
          <img
            src="app_logo.png"
            alt="profile-img"
            className="profile-img-card"
            style={{ marginBottom: '20px' }}
          />
          <Form onSubmit={handleLogin} ref={form}>
            <div
              className="form-group"
              style={{ textAlign: 'center', width: '100%', padding: '0 50px' }}
            >
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Sign in as Levi"
                onSuccess={handleLoginResponse}
                onFailure={() => {
                  console.log('Login Failed');
                }}
                cookiePolicy={'single_host_origin'}
                style={{
                  width: '100%',
                  padding: '12px 20px',
                  fontSize: '16px',
                }}
              />
            </div>
            {message && (
              <div className="form-group">
                <div
                  className={
                    message ===
                    'You have been logged out due to inactivity. Please click on the login button to login again!'
                      ? 'alert alert-info'
                      : 'alert alert-danger'
                  }
                  role="alert"
                  style={{ width: '100%' }}
                >
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: 'none' }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
