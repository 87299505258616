import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const updateLiabilityRenewalOptions = (body, id) => {
  // eslint-disable-next-line no-useless-concat
  return axios.post(API_URL + 'liability' + `/renewalOptions/${id}`, body, {
    headers: authHeader(),
  });
};

const getLiabilityRenewalOptions = (id) => {
  // eslint-disable-next-line no-useless-concat
  return axios.get(API_URL + 'liability' + `/renewalOptions/${id}`, {
    headers: authHeader(),
  });
};

// eslint-disable-next-line
export default {
  updateLiabilityRenewalOptions,
  getLiabilityRenewalOptions,
};
