import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ThankYouPage = () => {
  // Using window.top.location.href to change the iframe window as well. Now no new page will be opened
  window.top.location.href = 'https://www.psychcaremd.com/thank-you-page-hpf/';

  // Using window.top.location.href to change the iframe window as well.
  // You could return a simple message or loader here since the page will redirect
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CheckCircleIcon
        sx={{
          color: 'success.main',
          fontSize: 100,
        }}
      />
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          color: 'rgb(46, 109, 164)',
        }}
      >
        Your Submission Has Been Received.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginTop: 1,
          textAlign: 'center',
          color: 'rgb(46, 109, 164)',
        }}
      >
        One Of Our Care Coordinators Will Get Back To You Within 24 Business
        Hours.
      </Typography>
    </Box>
  );
};

export default ThankYouPage;
