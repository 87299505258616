import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

// CRUD operations for supervisorRelationships

// Function to fetch supervisor ID based on supervisorName
const fetchSupervisorIdByName = async (name) => {
  const response = await axios.get(
    `${API_URL}providers/get-id-by-name/${name}`,
    { headers: authHeader() }
  );
  return response.data.id; // Assuming the endpoint returns an object with an id property
};

const createSupervisorRelationship = (data) => {
  console.log('Sending data to server:', data);
  return axios.post(`${API_URL}supervisor-relationships`, data, {
    headers: authHeader(),
  });
};

const getAllSupervisorRelationships = () => {
  return axios.get(`${API_URL}supervisor-relationships`, {
    headers: authHeader(),
  });
};

const getSupervisorRelationshipById = (id) => {
  return axios.get(`${API_URL}supervisor-relationships/${id}`, {
    headers: authHeader(),
  });
};

const getSupervisorSupervisee = (id) => {
  return axios.get(
    `${API_URL}supervisor-relationships/getSupervisorSupervisee/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const updateSupervisorRelationship = (id, data) => {
  return axios.put(`${API_URL}supervisor-relationships/${id}`, data, {
    headers: authHeader(),
  });
};

const deleteSupervisorRelationship = (id) => {
  return axios.delete(`${API_URL}supervisor-relationships/${id}`, {
    headers: authHeader(),
  });
};

// eslint-disable-next-line
export default {
  fetchSupervisorIdByName,
  createSupervisorRelationship,
  getAllSupervisorRelationships,
  getSupervisorRelationshipById,
  updateSupervisorRelationship,
  deleteSupervisorRelationship,
  getSupervisorSupervisee,
};
