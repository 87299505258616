import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const sendRosterEmail = (MRN, selectedOption, body) => {
  return axios.post(
    API_URL + `roster/sendEmail/${MRN}/${selectedOption}`,
    body,
    {
      headers: authHeader(),
    }
  );
};

// eslint-disable-next-line
export default {
  sendRosterEmail,
};
