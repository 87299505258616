import React from 'react';

function Table({ cols, data, action, filePreviewHandler = () => {} }) {
  const displayValue = (item, col) => {
    let txt;
    if (col?.getData) {
      txt = col?.getData(item);
    } else if (col?.key) {
      txt = item[col?.key];
    }
    return txt || '';
  };
  return (
    <>
      {data?.length > 0 && (
        <div className="border mx-3">
          <div className="grid grid-cols-12 border">
            {cols?.map((v, k) => (
              <div className={`border p-2 bg-gray-100 text-sm ${v?.colsSpan}`}>
                {v?.label}
              </div>
            ))}
            {action && (
              <div
                className={`border p-2  bg-gray-100 col-span-2 text-sm flex`}
              >
                Actions
              </div>
            )}
          </div>
          <div className="grid grid-cols-12 ">
            {data?.map((v, k) => (
              <React.Fragment>
                {cols?.map((colValue, index) => (
                  <div className={`p-2 text-sm border-b ${colValue?.colsSpan}`}>
                    {colValue?.key === 'fileName' ? (
                      <span
                        onClick={() => filePreviewHandler(v)}
                        style={{
                          marginLeft: '3px',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          color: '#05b9f5',
                        }}
                      >
                        {v?.[colValue?.key]}
                      </span>
                    ) : (
                      <> {displayValue(v, colValue)} </>
                    )}
                  </div>
                ))}
                {action && (
                  <div className={`p-2  flex border-b col-span-2`}>
                    {action?.map((singleAction, k) => (
                      <div
                        className={` cursor-pointer rounded ${singleAction?.className}`}
                        onClick={() => singleAction?.actionHandler(v)}
                      >
                        {singleAction?.icon}
                      </div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Table;
