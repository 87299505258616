import { SAVE_FORM_DATA, CLEAR_FORM_DATA } from './types';

export const saveFormData = (formData) => ({
  type: SAVE_FORM_DATA,
  payload: formData,
});

export const clearFormData = () => ({
  type: CLEAR_FORM_DATA,
});
