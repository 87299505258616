import React from 'react';

function TextArea({ label, name, value, onChange }) {
  return (
    <div>
      <label
        htmlFor="comment"
        className="block text-sm font-normal leading-6 text-black"
      >
        {label}
      </label>
      <div className="mt-0.5">
        <textarea
          rows={4}
          name={name}
          id={value}
          onChange={onChange}
          className=" block w-full border border-gray-300  hover:border-sky-200 focus:border-sky-500  py-1.5 px-1 text-gray-900 shadow-sm ring-0 placeholder:text-gray-400  sm:text-sm sm:leading-6 outline-none	"
        />
      </div>
    </div>
  );
}

export default TextArea;
