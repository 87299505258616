import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const getStats = (data) => {
  return axios.get(
    API_URL + `appointment/stats?type=${data.type}&date=${data.date}`
  );
};
const getCallLogs = (data) => {
  return axios.get(API_URL + `callLogs/stats?date=${data.date}`);
};
// New function to fetch provider metrics
const getProviderMetrics = () => {
  return axios.get(API_URL + 'provider/metrics', { headers: authHeader() });
};

// eslint-disable-next-line
export default {
  getStats,
  getCallLogs,
  getProviderMetrics,
};
