import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, memo } from 'react';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import {
  getBirthdayEventsAndDelete,
  createOrUpdateBirthdayEvent,
  createOrUpdateAnniversaryEvent,
  getAnniversaryEventsAndDelete,
} from '../../common/utility';
import moment from 'moment';
import UserService from '../../services/user.service';
import { useSnackbar } from 'notistack';
import '../../styles/ProviderConfig.css';

// to set the date in correct format
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

const CareCoordinatorSystemInfo = (props) => {
  const [careCoordinatorStatus, setCareCoordinatorStatus] =
    React.useState(false);
  // The below state is use to track if adminStatus was changed.
  const [careCoordinatorStatusChanged, setcareCoordinatorStatusChanged] =
    React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date('2025-12-31'));
  const [endDate, setEndDate] = React.useState(new Date('2025-12-31'));
  const [pw, setPw] = React.useState();
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPw(props?.selectedUserDetail?.pw);
    setStartDate(props?.selectedUserDetail?.date_start);
    setEndDate(props?.selectedUserDetail?.date_end);
    setCareCoordinatorStatus(props?.selectedUserDetail?.is_active);
  }, [props.selectedUserDetail]);

  const handleOnSubmit = async (event) => {
    try {
      console.log('props?.selectedUserDetail: ', props?.selectedUserDetail);

      props.showLoading(); // showing the loading
      event.preventDefault();

      if (props?.selectedUser?.id) {
        if (props.selectedUserDetail?.care_coordinator_email) {
          const response = await UserService.updateCareCoordinatorDetails(
            props.selectedUser.id,
            {
              careCoordinatorEmail:
                props?.selectedUserDetail?.care_coordinator_email,
              careCoordinatorName:
                props?.selectedUserDetail?.care_coordinator_name,
              gender: props?.selectedUserDetail?.gender,
              race: props?.selectedUserDetail?.race,
              date_of_birth: props?.selectedUserDetail?.date_of_birth,
              additional_Languages_spoken:
                props?.selectedUserDetail?.additional_Languages_spoken,
              is_active: careCoordinatorStatus, // Updated field from the page
              address1: props?.selectedUserDetail?.address1,
              address2: props?.selectedUserDetail?.address2,
              city: props?.selectedUserDetail?.city,
              state: props?.selectedUserDetail?.state,
              personal_email: props?.selectedUserDetail?.personal_email,
              mobile_number: props?.selectedUserDetail?.mobile_number,
              ext: props?.selectedUserDetail?.ext,
              ss_num: props?.selectedUserDetail?.ss_num,
              hrs_p_week: props?.selectedUserDetail?.hrs_p_week,
              date_start: startDate, // Updated field from the page
              date_end: endDate, // Updated field from the page
              pw: pw, // Updated field from the page
              schedule: props?.selectedUserDetail?.schedule,
              emergencyContactName:
                props?.selectedUserDetail?.emergencyContactName,
              emergencyContactRelationship:
                props?.selectedUserDetail?.emergencyContactRelationship,
              customRelationship: props?.selectedUserDetail?.customRelationship,
              emergencyContactPhone:
                props?.selectedUserDetail?.emergencyContactPhone,
            }
          );

          if (response && response.data && response.data.success) {
            enqueueSnackbar('Care coordinator details updated successfully.', {
              variant: 'success',
              timeout: 3000,
            });
          }

          // The below is the case when careCoordinator is being made inactive
          // we would need to delete the event on calendar
          if (careCoordinatorStatus === false) {
            // The below getting admin birthday events and then deleting it
            await getBirthdayEventsAndDelete(
              props?.selectedUserDetail?.care_coordinator_name
            );

            // The below I am creating the Anniversary Date
            await getAnniversaryEventsAndDelete(
              props?.selectedUserDetail?.care_coordinator_name
            );
          } else {
            // The below condition makes sure that if adminStatus was changed
            if (careCoordinatorStatusChanged) {
              // Passing username and date of birth for the creation of birthday event
              await createOrUpdateBirthdayEvent(
                props?.selectedUserDetail?.care_coordinator_name,
                moment
                  .utc(props?.selectedUserDetail?.date_of_birth)
                  .format('YYYY-MM-DD')
              );
            }

            // // The below I am creating the Anniversary Date
            await createOrUpdateAnniversaryEvent(
              props?.selectedUserDetail?.care_coordinator_name,
              startDate
            );
          }

          props.hideLoading(); // hiding the loading

          enqueueSnackbar('Carecoordinator updated', {
            variant: 'success',
            timeout: 3000,
          });
          //forcefully reloading the window because status has been set to false.
          if (careCoordinatorStatus === false) {
            window.location.reload();
          }
        }
      }
    } catch (error) {
      console.log('handleOnSubmit.error', error);
    } finally {
      props.hideLoading();
    }
  };

  return (
    <React.Fragment>
      <div className="mainContainer">
        <Grid container spacing={3} className="GridItemTextField">
          {shouldShow && (
            <Grid item xs={3}>
              <FormControl
                className="fullWidthTextField borderedDropdown"
                sx={{ width: '100%' }}
              >
                <InputLabel id="demo-simple-select-label">
                  Carecoordinator Active Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={careCoordinatorStatus}
                  onChange={(event) => {
                    setCareCoordinatorStatus(event.target.value);
                    setcareCoordinatorStatusChanged(true);
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={3}>
            <TextField
              fullWidth
              autoComplete="off"
              variant="outlined"
              id="start_date"
              label="Start Date"
              value={startDate}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </Grid>

          {careCoordinatorStatus === false && (
            <Grid item xs={3}>
              <TextField
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="end_date"
                label="End Date"
                value={endDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Grid>
          )}

          {
            <Grid item xs={3}>
              <TextField
                className="fullWidthTextField borderedDropdown"
                autoComplete="off"
                variant="outlined"
                id="pw"
                label="Password"
                value={pw}
                onChange={(e) => {
                  setPw(e.target.value);
                }}
              />
            </Grid>
          }
        </Grid>

        <Grid container spacing={3} className="GridItemTextField">
          <Grid item xs={3}>
            <button
              xs={3}
              className="btn btn-primary btn-block buttonWithMargin"
              type="button"
              onClick={handleOnSubmit}
            >
              Save
            </button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default memo(CareCoordinatorSystemInfo);
