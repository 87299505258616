import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState, memo } from 'react';
import userService from '../../services/user.service';
import { Button } from '@mui/material';
import driveServices from '../../services/drive.services';
import FilesMapping from './FilesMapping';
import DialogComponent from '../Dialogs';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';
import Input from '../Input';
import Select from '../Select';
import TextArea from '../TextArea';
import moment from 'moment';

let defaultCols = [
  { key: 'fileName', label: 'File Name', colsSpan: 'col-span-5' },
  {
    key: 'createdAt',
    label: 'Uploaded Date',
    colsSpan: 'col-span-5',
    getData: (c) => {
      return moment.utc(c?.createdAt).format('MM-DD-YYYY');
    },
  },
];

const parentTabs = [{ label: 'CURRENT', value: '1' }];

function DiplomaComponent({
  selectedUser,
  selectedUserDetail,
  setTriggerAPICall,
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cols, setCols] = useState([...defaultCols]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
  const [value, setValue] = React.useState('1');
  const [currentFolderType, setCurrentFolderType] = useState('');
  const [childValue, setChildValue] = React.useState('diploma');
  const [selectedFileId, setSelectedFileId] = useState('');
  const [listArr, setListArr] = useState([
    {
      type: 'diploma',
      fileStartingName: 'DIPLOMA',
      diploma: true,
      title: 'Diploma Proof',
      subTitle: 'Diploma Card',
      icon: <PersonSearchIcon />,
      folderID: '',
      imagePreview: '',
      data: { graduating_school: '', graduating_date: '', other: '' },
    },
  ]);

  useEffect(() => {
    if (currentFolderType) {
      getDataFromDriveFolder(currentFolderType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolderType, selectedUserDetail]);

  useEffect(() => {
    dispatch(setLoading());
    userService
      .getDriveDetail({ provider_id: selectedUser.id, type: 'tab' })
      .then(async (res) => {
        if (res?.data?.drive_details) {
          dispatch(clearLoading());
          let { current_diploma, expired_diploma } = res?.data?.drive_details;

          let lArray = [
            {
              type: 'diploma',
              fileStartingName: 'DIPLOMA',
              diploma: true,
              title: 'Diploma Proof',
              subTitle: 'Diploma Card',
              folderID: current_diploma || expired_diploma || '',
              imagePreview: '',
              data: { graduating_school: '', graduating_date: '', other: '' },
            },
          ];

          setListArr([...lArray]);
          setCurrentFolderType('diploma');
          setChildValue('diploma');
        } else dispatch(clearLoading());
      })
      .catch(() => {
        dispatch(clearLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveToExpiredHandler = (type, id) => {
    dispatch(setLoading());
    driveServices
      .moveToExpire({
        type: type,
        file_id: id,
        provider_id: selectedUserDetail?.id,
      })
      .then((res) => {
        dispatch(clearLoading());
        if (res?.data?.msg) {
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Moved Successfully', {
            variant: 'success',
            timeout: 3000,
          });
        }
      })
      .catch(() => {
        dispatch(clearLoading());
      });
  };

  const getDataFromDriveFolder = (folderType) => {
    if (folderType && folderType === currentFolderType) {
      setCols([
        { key: 'fileName', label: 'File Name', colsSpan: 'col-span-4' },
        {
          key: 'graduating_school',
          label: 'Graduating School',
          colsSpan: 'col-span-2',
        },
        {
          key: 'graduating_date',
          label: 'Date Of Graduation',
          colsSpan: 'col-span-2',
          getData: (c) => {
            return moment.utc(c?.graduating_date).format('MM-DD-YYYY');
          },
        },
        {
          key: 'createdAt',
          label: 'Uploaded Date',
          colsSpan: 'col-span-2',
          getData: (c) => {
            return moment.utc(c?.createdAt).format('MM-DD-YYYY');
          },
        },
      ]);

      dispatch(setLoading());
      driveServices
        .viewFiles({ folder: folderType, provider_id: selectedUser?.id })
        .then((res) => {
          if (res?.data?.data?.files) {
            setData(res?.data?.data?.files);
          } else {
            setData([]);
          }
          dispatch(clearLoading());
        })
        .catch(() => {
          dispatch(clearLoading());
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentFolderType('diploma');
    setChildValue('diploma');
  };

  const handleChangeChild = (event, newValue) => {
    setData([]);
    setChildValue(newValue);
    setCurrentFolderType(newValue);
  };

  const deleteFileHandler = () => {
    dispatch(setLoading());
    driveServices
      .deleteFiles({ fileId: selectedFileId, type: childValue })
      .then((res) => {
        if (res?.data?.msg) {
          dispatch(clearLoading());
          setTimeout(() => getDataFromDriveFolder(currentFolderType), 200);
          setConfirmDelete(false);
          enqueueSnackbar('File Delete Successfully', {
            variant: 'success',
            timeout: 3000,
          });
          setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
        }
      })
      .catch((err) => {
        dispatch(clearLoading());
      });
  };

  const previewFileHandler = () => {
    setOpenFileUploadDialog(true);
  };

  const uploadFileAsPerDocumentHandler = (event, index) => {
    let lArray = [...listArr];
    lArray[index].imagePreview = event.target.files[0];
    setListArr([...lArray]);
  };

  const onChangeHandler = (e, index) => {
    let lArray = [...listArr];
    listArr[index].data = {
      ...listArr[index].data,
      [e.target.name]: e.target.value,
    };
    setListArr([...lArray]);
  };

  const submitHandler = () => {
    for (let i = 0; i < listArr?.length; i++) {
      if (listArr[i]?.folderID && listArr[i]?.imagePreview) {
        let formData = new FormData();
        formData.append('diploma', listArr[i]?.diploma);
        formData.append('file', listArr[i]?.imagePreview);
        formData.append('folder', listArr[i]?.folderID);
        formData.append('user_id', selectedUser?.id);
        formData.append(
          'folder_name',
          listArr[i]?.fileStartingName || 'DIPLOMA'
        );
        formData.append('provider_id', selectedUser?.id);
        formData.append('provider_name', selectedUserDetail?.provider_name);
        for (var key in listArr[i]?.data) {
          formData.append(key, listArr[i]?.data[key]);
        }
        dispatch(setLoading());

        driveServices
          .uploadFiles(formData)
          .then((response) => {
            setOpenFileUploadDialog(false);
            enqueueSnackbar('All File Upload Successfully', {
              variant: 'success',
              timeout: 3000,
            });
            setTimeout(() => {
              getDataFromDriveFolder(listArr[i]?.type);
            }, 200);
            dispatch(clearLoading());
            setTriggerAPICall((prevTriggerAPICall) => !prevTriggerAPICall);
          })
          .catch((error) => {
            console.error('File upload error:', error);
            dispatch(clearLoading());
          });
      }
      let resetListData = listArr?.map((v) => ({ ...v, imagePreview: '' }));
      setListArr([...resetListData]);
      setOpenFileUploadDialog(false);
    }
  };

  return (
    <>
      {confirmDelete && (
        <DialogComponent
          maxWidth={'xs'}
          open={confirmDelete}
          setOpen={setConfirmDelete}
          content={
            <>
              <p className="text-center font-semibold text-lg">
                Are You Sure You Want to Delete - This Action Can Not Be Undone
              </p>
              <div className="flex items-center justify-center mt-7">
                <button
                  className="bg-gray-100 to-blue-500 px-10 py-2 rounded"
                  onClick={() => setConfirmDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className="ml-3 bg-gradient-to-r from-cyan-500  text-white to-blue-500 px-10 py-2 rounded"
                  onClick={() => deleteFileHandler()}
                >
                  Delete
                </button>
              </div>
            </>
          }
        />
      )}
      {openFileUploadDialog && (
        <DialogComponent
          maxWidth={'md'}
          open={openFileUploadDialog}
          setOpen={setOpenFileUploadDialog}
          content={
            <div className="">
              <p className="text-center font-semibold text-lg">
                Document <span className="text-sky-500"> upload </span> Center
              </p>
              <p className="text-center text-sm mt-0.5">
                The following are the file formats that you are able to upload -
                Png, Doc, Docx, Jpeg, Pdf
              </p>
              {listArr?.map(
                (v, k) =>
                  v?.type === childValue && (
                    <div className="grid grid-col-12 mx-10 mt-7" key={k}>
                      <p className="col-span-12 font-semibold flex items-center mb-1">
                        {v?.title}
                      </p>
                      <div className="border border-gray-300 col-span-12">
                        <div className="grid grid-cols-12 gap-2 p-3">
                          <div className="col-span-6">
                            <Select
                              name={'graduating_school'}
                              onChange={(e) => onChangeHandler(e, k)}
                              value={v?.data?.graduating_school}
                              label={'Name of Graduating School'}
                              data={[
                                {
                                  value: 'Andrews University',
                                  name: 'Andrews University',
                                },
                                {
                                  value: 'Argosy University',
                                  name: 'Argosy University',
                                },
                                {
                                  value:
                                    'California Institute of Integral Studies',
                                  name: 'California Institute of Integral Studies',
                                },
                                {
                                  value: 'Chatham University',
                                  name: 'Chatham University',
                                },
                                {
                                  value: 'Catholic University',
                                  name: 'Catholic University',
                                },
                                {
                                  value: 'Clark University',
                                  name: 'Clark University',
                                },
                                {
                                  value: 'Columbia University',
                                  name: 'Columbia University',
                                },
                                {
                                  value: 'Fuller Theological Seminary',
                                  name: 'Fuller Theological Seminary',
                                },
                                {
                                  value: 'George Washington University',
                                  name: 'George Washington University',
                                },
                                {
                                  value: 'Howard University',
                                  name: 'Howard University',
                                },
                                {
                                  value: 'Johns Hopkins University',
                                  name: 'Johns Hopkins University',
                                },
                                {
                                  value: 'Louisiana State University',
                                  name: 'Louisiana State University',
                                },
                                {
                                  value: 'Loyola University',
                                  name: 'Loyola University',
                                },
                                {
                                  value: 'NOVA Southeastern University',
                                  name: 'NOVA Southeastern University',
                                },
                                {
                                  value: 'Ohio State University',
                                  name: 'Ohio State University',
                                },
                                {
                                  value: 'Salisbury University',
                                  name: 'Salisbury University',
                                },
                                {
                                  value: 'Smith College',
                                  name: 'Smith College',
                                },
                                {
                                  value: 'Towson University',
                                  name: 'Towson University',
                                },
                                {
                                  value: 'University of Cincinnati',
                                  name: 'University of Cincinnati',
                                },
                                {
                                  value: 'University of Maryland',
                                  name: 'University of Maryland',
                                },
                                {
                                  value:
                                    'University of Maryland at Baltimore School of Social Work',
                                  name: 'University of Maryland at Baltimore School of Social Work',
                                },
                                {
                                  value: 'University of Michigan',
                                  name: 'University of Michigan',
                                },
                                {
                                  value: 'Virginia Commonwealth University',
                                  name: 'Virginia Commonwealth University',
                                },
                                {
                                  value: 'Yeshiva University',
                                  name: 'Yeshiva University',
                                },
                                { value: 'Other', name: 'Other' },
                              ]}
                            />
                          </div>
                          <div className="col-span-6">
                            <Input
                              onChange={(e) => onChangeHandler(e, k)}
                              value={v?.data?.graduating_date}
                              name={'graduating_date'}
                              label={'Date of Graduation'}
                              type="date"
                            />
                          </div>
                          {v?.data?.graduating_school === 'Other' && (
                            <div className="col-span-12">
                              <TextArea
                                onChange={(e) => onChangeHandler(e, k)}
                                value={v?.data?.other}
                                name="other"
                                label={'Other'}
                                type=""
                              />
                            </div>
                          )}
                        </div>
                        <div className="grid grid-cols-1 flex items-center">
                          <div
                            className={`border border-l border-gray-300 px-2 py-3 border-b ${
                              v?.imagePreview
                                ? ' border-b-green-600 '
                                : ' border-b-blue-500 '
                            }  border-b-2 `}
                          >
                            {v?.imagePreview ? (
                              <div className="flex justify-between items-center px-3">
                                <a
                                  href={
                                    v?.imagePreview
                                      ? URL.createObjectURL(v?.imagePreview)
                                      : ''
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-sky-400 underline "
                                >
                                  {v?.imagePreview?.name || 'testing.jpg'}
                                </a>
                                <div className="flex">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    style={{
                                      backgroundColor: 'white',
                                      color: 'skyblue',
                                      boxShadow: 'none',
                                    }}
                                    component="label"
                                  >
                                    <input
                                      type="file"
                                      accept=".pdf, .png, .jpeg"
                                      hidden
                                      onChange={(e) =>
                                        uploadFileAsPerDocumentHandler(e, k)
                                      }
                                    />
                                    <div className="flex flex-col items-center justify-center  cursor-pointer">
                                      <PublishedWithChangesIcon className="text-green-600" />
                                      <p
                                        className="text-xs  "
                                        style={{
                                          fontSize: '11px',
                                          color: 'black',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Replace
                                      </p>
                                    </div>
                                  </Button>
                                  <div
                                    className="flex flex-col items-center justify-center  mr-3 cursor-pointer"
                                    onClick={() => {
                                      let lArray = [...listArr];
                                      lArray[k].imagePreview = '';
                                      setListArr([...lArray]);
                                    }}
                                  >
                                    <ClearIcon className="text-red-600" />
                                    <p className="text-xs">Clear</p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <p className="text-center">
                                <input
                                  id="file_input"
                                  key={k}
                                  type="file"
                                  onChange={(e) =>
                                    uploadFileAsPerDocumentHandler(e, k)
                                  }
                                  accept=".pdf, .png, .jpeg"
                                  style={{ display: 'none' }}
                                />
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    backgroundColor: 'white',
                                    color: 'skyblue',
                                    boxShadow: 'none',
                                  }}
                                  component="label"
                                >
                                  Click
                                  <input
                                    type="file"
                                    accept=".pdf, .png, .jpeg"
                                    hidden
                                    onChange={(e) =>
                                      uploadFileAsPerDocumentHandler(e, k)
                                    }
                                  />
                                </Button>
                                here to Upload a File
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}

              <div className="flex items-center justify-center text-white mt-10">
                <button
                  style={{
                    backgroundColor: 'blue',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '0.25rem',
                  }}
                  onClick={submitHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          }
        />
      )}
      <Tabs
        className=""
        onChange={handleChange}
        value={value}
        sx={{
          '& button.Mui-selected': { backgroundColor: '#ebeded' },
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {parentTabs.map((v) => (
          <Tab
            key={v.value}
            sx={{
              '&.Mui-selected': {
                outline: 'none',
              },
            }}
            label={v?.label}
            value={v?.value}
          />
        ))}
      </Tabs>
      {value === '1' && (
        <FilesMapping
          moveToExpiredHandler={moveToExpiredHandler}
          currentFolderType={currentFolderType}
          setConfirmDelete={setConfirmDelete}
          setSelectedFileId={setSelectedFileId}
          value={value}
          cols={cols}
          showUploadButton={true}
          childValue={childValue}
          handleChangeChild={handleChangeChild}
          childTab={[]}
          uploadFileHandler={previewFileHandler}
          data={data}
        />
      )}
    </>
  );
}

export default memo(DiplomaComponent);
