import axios from 'axios';
import authHeader from './auth-header';
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`;

const getFutureAppt = (providerId, serviceType) => {
  // eslint-disable-next-line no-useless-concat
  return axios.get(API_URL + 'future-appt' + `/${providerId}/${serviceType}`, {
    headers: authHeader(),
  });
};

// eslint-disable-next-line
export default {
  getFutureAppt,
};
