import React from 'react';
import { confirmable } from 'react-confirm';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';

const ConfirmDialog = ({ show, proceed, confirmation, title }) => (
  <Dialog onClose={() => proceed(false)} open={show} maxWidth={'md'} fullWidth>
    <Box sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
      {' '}
      {/* Scroll the container */}
      <Card sx={{ minWidth: 600 }}>
        <CardHeader title={title} />
        <CardContent>{confirmation}</CardContent>
        <CardActions>
          <Button onClick={() => proceed(false)} size="small">
            Cancel
          </Button>
          <Button onClick={() => proceed(true)} size="small">
            Ok
          </Button>
        </CardActions>
      </Card>
    </Box>
  </Dialog>
);

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialog);
