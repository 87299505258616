export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const DOCTORS_SUCCESS = 'DOCTORS_SUCCESS';
export const DOCTORS_FAIL = 'DOCTORS_FAIL';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';

export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';

export const SET_DIP_BADGE_NUMBERS = 'SET_DIP_BADGE_NUMBERS';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';

export const SET_LIGHT_LOADER = 'SET_LIGHT_LOADER';
export const CLEAR_LIGHT_LOADER = 'CLEAR_LIGHT_LOADER';
