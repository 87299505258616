import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { UserActivityLogger } from './hooks/UserActivityLogger';
import { logout } from './actions/auth';
import EventBus from './common/EventBus';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './cutomStyle.css';

import Login from './components/Login';
import CustomForm from './components/CustomForm/CustomForm';
import Drawer from './components/MaterialDrawer';

import { clearMessage } from './actions/message';
import { history, useQuery } from './helpers/history';

import NotificationContainer from 'react-notifications';
import useVerifyUserActivity from './common/VerifyUserActivity';

const App = () => {
  useVerifyUserActivity();

  // The below are the broadcase channel for communication between multiple tabs
  const logoutChannel = new BroadcastChannel('logoutChannel');
  const loginChannel = new BroadcastChannel('loginChannel');

  // Listen for incoming messages
  logoutChannel.onmessage = (event) => {
    if (event.data === 'logout') {
      // Handle logout event, on logout redirecting user to login page
      window.location.href = '/login';
    }
  };

  // Listen for incoming messages
  loginChannel.onmessage = (event) => {
    const { type, senderId } = event.data;
    // getting the unqiueId from sessionStorage
    const uniqueId = sessionStorage.getItem('uniqueId');
    // Here I am checking if type is login and both senderId and uniqueId is different
    if (type === 'login' && senderId !== uniqueId) {
      if (localStorage.getItem('isQuestionnaire')?.toString() === 'true') {
        window.location.href = '/clients';
      } else {
        window.location.href = '/personal_info';
      }
    }
  };

  const { user: currentUser } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.loading);
  const { light } = useSelector((state) => state.light);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const query = useQuery();
  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(logout(currentUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    EventBus.on('logout', () => {
      logOut();
    });

    return () => {
      EventBus.remove('logout');
    };
  }, [currentUser, logOut]);

  useEffect(() => {
    const notification = query.get('notification');
    if (notification) {
      enqueueSnackbar(notification, {
        variant: 'info',
        timeout: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* The below component is use to log userActivity */}
      {currentUser && <UserActivityLogger />}

      {loading && (
        <div className={`loading ${light ? 'loading-light' : 'loading'}`}>
          Loading&#8230;
        </div>
      )}

      {/* Common Drawer component for all users */}
      {currentUser && <Drawer history={history} />}
      <Switch>
        {/* Open routes */}
        {!currentUser && (
          <>
            <Switch>
              <Route exact path="/appt-request-form" component={CustomForm} />
              <Route path="/login" component={Login} />
              <Redirect from="/" to="/login" />
            </Switch>
          </>
        )}
      </Switch>
      <NotificationContainer />
    </>
  );
};

export default App;
