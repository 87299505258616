import { Box, Grid, Tab, Tabs, Button } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Table from '../Table';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';

function FilesMapping({
  setConfirmDelete,
  setSelectedFileId,
  value,
  cols,
  childValue,
  showUploadButton,
  handleChangeChild,
  childTab,
  uploadFileHandler,
  data,
  currentFolderType,
  moveToExpiredHandler,
}) {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (value === '1') {
      setTableData([...data]?.filter((v, k) => v?.type === 'current') || []);
    } else if (value === '2') {
      setTableData([...data]?.filter((v, k) => v?.type === 'expired') || []);
    }
  }, [data, value]);

  const getButtonLabelAndTooltip = () => {
    // Check if the current folder is 'liability' and if there are files in it
    if (currentFolderType === 'liability' && tableData.length > 0) {
      return {
        label: 'Renew Malpractice',
        tooltip:
          'Click to renew your malpractice insurance. This will upload the renewed documents to the system.',
      };
    } else {
      return {
        label: 'Upload File',
        tooltip: 'Upload a new file to the system.',
      };
    }
  };

  const { label, tooltip } = getButtonLabelAndTooltip();

  return (
    <div>
      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          padding: '10px',
        }}
      >
        <Grid container spacing={2} gap={5}>
          <Grid container spacing={2}>
            {childTab?.length > 0 && (
              <Grid item xs={2}>
                <Box sx={{ display: 'flex' }}>
                  <Tabs
                    orientation="vertical"
                    value={childValue}
                    onChange={handleChangeChild}
                    aria-label="Vertical tabs example"
                    sx={{
                      '& button.Mui-selected': { backgroundColor: '#ebeded' },
                    }}
                  >
                    {childTab?.map((v, k) => (
                      <Tab
                        sx={{
                          '&.Mui-selected': {
                            outline: 'none',
                          },
                        }}
                        label={v?.label}
                        value={v?.value}
                        key={k + v?.value}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Grid>
            )}

            <Grid item xs={childTab?.length > 0 ? 10 : 12} sx={{ pr: 0 }}>
              {showUploadButton && (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Tooltip title={tooltip}>
                    <Button
                      childTab={childTab}
                      sx={{ mb: 4 }}
                      style={{ marginTop: '-65px' }}
                      onClick={uploadFileHandler}
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                    >
                      {label}
                    </Button>
                  </Tooltip>
                </div>
              )}

              <Grid container spacing={2} style={{ marginTop: '6px' }}>
                <div style={{ width: '100%' }}>
                  {data?.length > 0 && (
                    <Table
                      filePreviewHandler={(v) =>
                        window.open(
                          `https://drive.google.com/file/d/${v.file_id}/view`
                        )
                      }
                      cols={cols}
                      data={tableData}
                      action={[
                        {
                          className: 'text-red-600',
                          icon: (
                            <Tooltip title="Delete">
                              <span>x</span>
                            </Tooltip>
                          ),
                          actionHandler: (v) => {
                            setConfirmDelete(true);
                            setSelectedFileId(v?.file_id);
                          },
                        },
                        value === '1' &&
                          (currentFolderType === 'liability' ||
                            currentFolderType === 'license') && {
                            className: 'text-sky-500',
                            icon: (
                              <Tooltip title="Move To Expired">
                                <span className="ml-2">
                                  <CachedIcon
                                    style={{ height: '13px', width: '13px' }}
                                  />
                                </span>
                              </Tooltip>
                            ),
                            actionHandler: (v) => {
                              moveToExpiredHandler(
                                currentFolderType,
                                v?.file_id
                              );
                              setSelectedFileId(v?.file_id);
                            },
                          },
                      ]}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default memo(FilesMapping);
