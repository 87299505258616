import { SET_NOTIFICATION, CLEAR_NOTIFICATION, SET_NOTIFICATION_COUNT } from "./types";

export const setNotification = (notification) => ({
  type: SET_NOTIFICATION,
  payload: notification,
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});

export const setNotificationCount = (count) => ({
  type: SET_NOTIFICATION_COUNT,
  payload: count
});
