/* eslint-disable */
import { SET_LIGHT_LOADER, CLEAR_LIGHT_LOADER } from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_LIGHT_LOADER:
      return { light: true };

    case CLEAR_LIGHT_LOADER:
      return { light: false };

    default:
      return state;
  }
}

// The above state is being use to manage the color of loader
