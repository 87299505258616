import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import React, { memo } from 'react';

const HistoryShowingComponentSuperivsee = ({
  question,
  handleOpenHistory,
  formValues,
}) => {
  let shouldShow = false;
  const supervisionInitialModifications =
    formValues?.supervision?.supervision_initial_modifications;
  const supervisionToAndFroModifications =
    formValues?.supervision?.modifications_history;

  // Loop through supervisionInitialModifications
  for (let i = 0; i < supervisionInitialModifications?.length; i++) {
    const currentObject = supervisionInitialModifications[i];
    // Loop through keys of the current object
    for (const key in currentObject) {
      if (key.startsWith('area')) {
        const areaValue = currentObject[key];
        // Check if the areaValue contains the question
        if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
          shouldShow = true;
          break;
        }
      }
    }
    if (shouldShow) break; // Exit the loop if the condition is met
  }

  for (let i = 0; i < supervisionToAndFroModifications?.length; i++) {
    const currentObject = supervisionToAndFroModifications[i];
    // Loop through keys of the current object
    for (const key in currentObject) {
      if (key.startsWith('area')) {
        const areaValue = currentObject[key];
        // Check if the areaValue contains the question
        if (areaValue?.toLowerCase().includes(question?.toLowerCase())) {
          shouldShow = true;
          break;
        }
      }
    }
    if (shouldShow) break; // Exit the loop if the condition is met
  }

  // Return the appropriate component based on shouldShow
  if (shouldShow) {
    return (
      <IconButton onClick={handleOpenHistory}>
        <HistoryIcon />
      </IconButton>
    );
  } else {
    return <></>;
  }
};
export default memo(HistoryShowingComponentSuperivsee);
