import React, { memo } from 'react';

function Select({ label, value, onChange, data, name }) {
  return (
    <div>
      <label
        htmlFor="location"
        className="block text-sm font-normal leading-6 text-gray-900"
      >
        {label}
      </label>
      <select
        id="location"
        name={name}
        className="mt-0.5 shadow-sm block w-full h-10 border border-gray-300 hover:border-sky-200 focus:border-sky-300 py-1.5 px-1 pr-10 text-gray-900 outline-none sm:text-sm sm:leading-6"
        value={value}
        onChange={onChange}
      >
        <option disabled="disabled" value="">
          Select an option
        </option>
        {data?.map((v, k) => (
          <option value={v?.value}>{v?.name}</option>
        ))}
      </select>
    </div>
  );
}

export default memo(Select);
