import React, { useState, useEffect, useRef, memo } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Paper, Typography } from '@mui/material';
import BarCharts from './UI/BarChart';
import DashboardService from '../services/dashboardService';
import moment from 'moment';
import PieCharts from './UI/PieCharts';
import CircularProgress from '@mui/material/CircularProgress';
import { LabelList } from 'recharts';
import StackBarCharts from './UI/StackBarChat';
import userService from '../services/user.service';
import eventBus from '../common/EventBus';

const insuranceList = [
  'Aetna',
  'Aetna Medicare Advantage',
  'BCBS',
  'CIGNA',
  'Hopkins EHP',
  'Hopkins USFHP',
  'Medical Assistance',
  'Medicare',
  'Tricare',
  'Other',
];

const healthcareProvidersOptions = [
  'Blanche Kattie, NP',
  'Laura Bothe, NP',
  'Dr. Yaniv Berger (Internist)',
  'Dr. Francisca Bruney (Family Medicine)',
  'Dr. Makeida Koyi (Psychiatrist)',
  'Dr. Steven Miller (Internist)',
  'Other',
];

const reasonOptions = [
  'No Availability',
  'In-Office Only Request (COVID)',
  'Not Appropriate for VC',
  'Psychologist Only',
  'Other',
];

const referalOptions = [
  'psychcare therapist',
  'other healthcare provider',
  'insurance list',
  'word of mouth',
  'other please specify',
];

const reasonColor = [
  '#FF5733',
  '#5733FF',
  '#FF33A3',
  '#33A3FF',
  '#FF3366',
  '#3366FF',
  '#6633FF',
  '#33FFA3',
  '#33FF66',
  '#66FF33',
  '#FF6633',
  '#33A3FF',
  '#FF33FF',
  '#33FFFF',
  '#FF33CC',
  '#33CCFF',
  '#CC33FF',
  '#33FF33',
  '#FFCC33',
  '#33FF00',
  '#00FF33',
  '#33FF99',
  '#9933FF',
  '#FF9933',
  '#33FFCC',
  '#CC33FF',
  '#FF99CC',
  '#CC99FF',
  '#FFCC99',
  '#CCFF99',
  '#99CCFF',
  '#99FFCC',
  '#FF99FF',
  '#CCFFFF',
  '#FFCCFF',
  '#FFFF33',
  '#33FFFF',
  '#FFFF66',
  '#66FFFF',
  '#FFFF99',
  '#99FFFF',
  '#FF9933',
  '#33FF33',
  '#33CC33',
];

const child1Tabs = [
  { label: 'Type of Inquiries & Results', value: 'inquiries' },
  { label: 'Non-Conversion Reasons', value: 'non-conversion' },
  { label: 'Referral Sources', value: 'referral_sources' },
  {
    label: 'Non Conversion - Preferred Providers',
    value: 'non_conversion-preferred_providers',
  },
  { label: 'Appt Type & Location', value: 'appointment_type' },
  { label: 'Service Type - Appts', value: 'service_type_appts' },
  { label: 'Service Type - Non-Appts', value: 'service_type_non_appts' },
  { label: 'Provider & New Appts', value: 'provider_new_ppts' },
];

const child2Tabs = [
  { label: 'All Calls', value: 'all_calls' },
  {
    label: (
      <>
        New Client Inquiries <br /> ( Ext 1 + Ext 11)
      </>
    ),
    value: 'new_client',
  },
  {
    label: (
      <>
        Cancellations <br /> (Ext 3)
      </>
    ),
    value: 'cancellations',
  },
  { label: <>Outgoing Calls</>, value: 'outgoing' },
];

const transformData = (inputData, reasonOptions) => {
  const transformedData = {};
  inputData.forEach((item) => {
    const name = item.name;
    const reasonIndex = reasonOptions.indexOf(item.reason);
    if (reasonIndex >= 0) {
      if (!transformedData[name]) {
        transformedData[name] = {
          name,
        };
        for (let i = 1; i <= reasonOptions.length; i++) {
          transformedData[name][`value${i}`] = '0';
          transformedData[name][`labelForValue${i}`] = reasonOptions[i - 1];
        }
      }
      transformedData[name][`value${reasonIndex + 1}`] = item?.value
        ? item?.value
        : '1';
    }
  });
  const output = Object.values(transformedData);
  return output;
};

const CustomTooltipForInquiry = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className=""
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '10px',
          backgroundColor: 'black',
          cursor: 'pointer',
          border: '1px solid #e3e1e1',
          borderRadius: '3px',
          color: 'white',
          fontSize: '13px',
        }}
      >
        <p className="intro">
          {payload[0].payload.service_group} : {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

const CustomTooltipForCallLogs = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className=""
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '10px',
          backgroundColor: 'black',
          cursor: 'pointer',
          border: '1px solid #e3e1e1',
          borderRadius: '3px',
          color: 'white',
          fontSize: '13px',
        }}
      >
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue} : {payload[0]?.payload?.value}
        </p>
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue1} : {payload[0]?.payload?.value1}
        </p>
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue2} : {payload[0]?.payload?.value2}
        </p>
        <p className="intro" style={{ marginTop: '5px' }}>
          {payload[0].payload.labelForValue3} : {payload[0]?.payload?.value3}
        </p>
      </div>
    );
  }

  return null;
};

function capitalize(string) {
  if (string === null || string === undefined) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Dashboard = () => {
  const xs9GridRef = useRef(null);
  const [providersOptions, setProvidersOptions] = React.useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState('1');
  const [childValue, setChildValue] = React.useState('inquiries');
  const [view, setView] = useState('appointments');
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [secondData, setSecondData] = useState([]);
  const [xs9Width, setXs9Width] = useState(0);
  const { pathname } = useLocation();

  const referalGraph = [
    {
      name: 'PsychCare Therapist Therapy',
      value: 'psychcareTherapist',
      type: 'therapy',
      options: providersOptions,
      childValue: true,
    },
    {
      name: 'Other Healthcare Provider Therapy',
      value: 'otherHealthCareProvider',
      type: 'therapy',
      options: healthcareProvidersOptions,
    },
    {
      name: 'Insurance List Therapy',
      value: 'insuranceList',
      type: 'therapy',
      options: insuranceList,
    },
    {
      name: 'PsychCare Therapist Testing',
      value: 'psychcareTherapist',
      type: 'testing',
      options: providersOptions,
    },
    {
      name: 'Other Healthcare Provider Testing',
      value: 'otherHealthCareProvider',
      type: 'testing',
      options: healthcareProvidersOptions,
      childValue: true,
    },
    {
      name: 'Insurance List Testing',
      value: 'insuranceList',
      type: 'testing',
      options: insuranceList,
    },
  ];

  const CustomTooltipForReferredOut = ({
    active,
    payload,
    label,
    reasonOptions,
    type,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className=""
          style={{
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            backgroundColor: 'black',
            cursor: 'pointer',
            border: '1px solid #e3e1e1',
            borderRadius: '3px',
            color: 'white',
            fontSize: '13px',
          }}
        >
          <p className="intro" style={{ marginTop: '5px' }}>
            {payload[0].payload.name}
          </p>
          {type === 'referral_sources' ? (
            <>
              {' '}
              {reasonOptions?.map((v, k) => (
                <>
                  {payload[0]?.payload[`value${k + 1}`] !== '0' && (
                    <p
                      className="intro"
                      style={{ marginTop: '5px', textTransform: 'capitalize' }}
                    >
                      {payload[0].payload[`labelForValue${k + 1}`]} :{' '}
                      {payload[0]?.payload[`value${k + 1}`]}
                    </p>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {reasonOptions?.map((v, k) => (
                <p
                  className="intro"
                  style={{ marginTop: '5px', textTransform: 'capitalize' }}
                >
                  {payload[0].payload[`labelForValue${k + 1}`]} :{' '}
                  {payload[0]?.payload[`value${k + 1}`]}
                </p>
              ))}
            </>
          )}
        </div>
      );
    }

    return null;
  };

  const onChangeDate = (e) => {
    let startdate = moment();
    startdate = startdate.subtract(e.target.value, 'days');
    setDate(startdate);
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = (event, newValue) => {
    setDate(moment());
    setData([]);
    if (newValue === '1') {
      setChildValue('inquiries');
    } else if (newValue === '2') {
      setChildValue('all_calls');
    } else if (newValue === '3') {
      setChildValue('1');
    }
    setValue(newValue);
  };
  const handleChangeChild = (event, newValue) => {
    setChildValue(newValue);
    if (value === '1') {
      setData([]);
    }
  };

  useEffect(() => {
    userService.getUsers().then(
      (response) => {
        let users = response?.data?.users ?? [];
        let providers = users
          .filter((user) => {
            return user.roleId === 5; // filtering provider from users
          })
          .sort((user1, user2) => {
            const name1 = user1?.username?.split(' ')[1] ?? '';
            const name2 = user2?.username?.split(' ')[1] ?? '';
            return name1.localeCompare(name2);
          });
        // The above filtering providers from users and setting providers.
        setProvidersOptions(
          providers?.map((v, k) => v?.username?.toLowerCase())
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          eventBus.dispatch('logout');
        }
      }
    );
  }, []);

  useEffect(() => {
    function handleResize() {
      if (xs9GridRef.current) {
        setXs9Width(xs9GridRef.current.clientWidth - 50);
      }
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial width measurement
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (value === '1') {
      setLoading(true);

      DashboardService.getStats({ type: childValue, date: date }).then(
        (res) => {
          setLoading(false);
          setData(res?.data?.stats);
          if (res?.data?.stats1) {
            let testingData = res?.data?.stats1?.filter(
              (v, k) => v.service_group === 'Testing'
            );
            let therapyData = res?.data?.stats1?.filter(
              (v, k) => v.service_group === 'Therapy'
            );

            setSecondData({
              therapy: {
                psychcareTherapist: transformData(
                  therapyData?.filter(
                    (v, k) => v.aboutUsInfo === referalOptions[0]
                  ),
                  providersOptions
                ),
                otherHealthCareProvider: transformData(
                  therapyData?.filter(
                    (v, k) => v.aboutUsInfo === referalOptions[1]
                  ),
                  healthcareProvidersOptions?.map((v, k) => v?.toLowerCase())
                ),
                insuranceList: transformData(
                  therapyData?.filter(
                    (v, k) => v.aboutUsInfo === referalOptions[2]
                  ),
                  insuranceList?.map((v, k) => v?.toLowerCase())
                ),
                // wordOfMouth:transformData(therapyData,providersOptions),
                //otherPleaseSpecify:transformData(therapyData,providersOptions)
              },
              testing: {
                psychcareTherapist: transformData(
                  testingData?.filter(
                    (v, k) => v.aboutUsInfo === referalOptions[0]
                  ),
                  providersOptions
                ),
                otherHealthCareProvider: transformData(
                  testingData?.filter(
                    (v, k) => v.aboutUsInfo === referalOptions[1]
                  ),
                  healthcareProvidersOptions?.map((v, k) => v?.toLowerCase())
                ),
                insuranceList: transformData(
                  testingData?.filter(
                    (v, k) => v.aboutUsInfo === referalOptions[2]
                  ),
                  insuranceList?.map((v, k) => v?.toLowerCase())
                ),
                // wordOfMouth:transformData(testingData,providersOptions),
                //otherPleaseSpecify:transformData(testingData,providersOptions)
              },
            });
            //  console.log(transformData(testingData,referalOptions),"hereee")
          }
        }
      );
      // non-conversion-result-for-referal
      // "Referred Out"
      if (childValue === 'non-conversion') {
        DashboardService.getStats({
          type: 'non-conversion-result',
          date: date,
        }).then((genericRes) => {
          setLoading(false);
          DashboardService.getStats({
            type: 'non-conversion-result-for-referal',
            date: date,
          }).then((referredOutRes) => {
            setLoading(false);
            setSecondData({
              generic: genericRes?.data?.stats,
              referred_out_testing: transformData(
                referredOutRes?.data?.stats?.filter(
                  (v, k) => v.service_group === 'Testing'
                ),
                reasonOptions
              ),
              referred_out_threpy: transformData(
                referredOutRes?.data?.stats?.filter(
                  (v, k) => v.service_group === 'Therapy'
                ),
                reasonOptions
              ),
            });
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childValue, date]);

  useEffect(() => {
    if (value === '2') {
      setLoading(true);
      DashboardService.getCallLogs({ date: date }).then((res) => {
        setLoading(false);
        setData(res?.data?.stats);
      });
    }
  }, [value, date]);

  useEffect(() => {
    if (pathname === '/dashboard/appointments') {
      setView('appointments');
      setChildValue('inquiries'); // Set to a valid value for appointments
    } else if (pathname === '/dashboard/call-logs') {
      setView('callLogs');
      setChildValue('all_calls'); // Set to a valid value for call logs
    }
  }, [pathname]);

  useEffect(() => {
    console.log('Dashboard mounted');

    return () => {
      console.log('Dashboard unmounting');
    };
  }, []);

  useEffect(() => {
    console.log('View changed: ', view);
    setLoading(true);
    if (view === 'appointments') {
      DashboardService.getStats({ type: childValue, date: date }).then(
        (res) => {
          console.log('Appointment Data:', res?.data?.stats);
          setData(res?.data?.stats);
          setLoading(false);
        }
      );
    } else if (view === 'callLogs') {
      DashboardService.getCallLogs({ date: date })
        .then((res) => {
          console.log('Full Call Logs Response:', res);
          console.log('Call Logs Data:', res?.data?.stats);
          setData(res?.data?.stats);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching call logs:', error);
          setLoading(false);
        });
    }
  }, [view, childValue, date]);

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        {view === 'appointments' && (
          <>
            <Typography
              variant="h6"
              gutterBottom
              center
              sx={{ marginTop: '10px' }}
            >
              {value === '1' ? '' : 'All Call Logs'}
            </Typography>
            {value === '1' && (
              <div>
                {/* <Typography >Number Of Appropriateness</Typography> */}

                <div style={{ marginTop: '100px', display: 'flex' }}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Box sx={{ display: 'flex' }}>
                          <Tabs
                            orientation="vertical"
                            value={childValue}
                            onChange={handleChangeChild}
                            aria-label="Vertical tabs example"
                            sx={{
                              '& .MuiTabs-flexContainer': {
                                flexDirection: 'column',
                              },
                              '& .MuiButtonBase-root': {
                                // Apply general style for all tabs
                                py: 0.95, // Adjust vertical padding for compact spacing
                                minHeight: 32, // Optionally adjust the minimum height
                                '&:first-of-type': {
                                  mt: '65px', // Apply top margin only to the first tab
                                },
                              },
                              '& .Mui-selected': {
                                // Styles for selected tab
                                backgroundColor: '#ebeded', // Background color for selected tab
                                outline: 'none',
                              },
                            }}
                          >
                            {child1Tabs.map((tab) => (
                              <Tab
                                label={tab.label}
                                value={tab.value}
                                key={tab.label}
                                sx={{
                                  typography: 'body2',
                                  textTransform: 'none',
                                  fontSize: '1.0rem', // Set a specific font size for all tabs
                                  // Additional individual tab styles can be applied here if necessary
                                }}
                              />
                            ))}
                          </Tabs>
                        </Box>
                      </Grid>
                      <Grid item xs={10} ref={xs9GridRef} sx={{ pr: 10 }}>
                        <div
                          style={{
                            width: xs9Width?.toString()?.concat('px'),
                            overflowX: 'auto',
                          }}
                        >
                          {loading && (
                            <div
                              style={{
                                position: 'absolute',
                                right: '130px',
                                marginTop: '-60px',
                                width: '140px',
                              }}
                            >
                              <CircularProgress />
                            </div>
                          )}

                          <Paper style={{ width: '100%' }}>
                            <div
                              style={{
                                position: 'absolute',
                                right: '63px',
                                marginTop: '70px',
                                width: '150px',
                              }}
                            >
                              <div
                                style={{ fontSize: '14px', marginTop: '50px' }}
                              >
                                Select Days
                              </div>
                              <select
                                onChange={(e) => {
                                  onChangeDate(e);
                                }}
                                className="outline-none"
                                style={{
                                  padding: '2px 1.5px',
                                  background: '#ebeded',
                                  width: '100%',
                                }}
                              >
                                <option value={0}>Today</option>
                                <option value={1}>Yesterday</option>
                                <option value={7}>Last 7 Days</option>
                                <option value={30}>Past Month</option>
                                <option value={180}>Past 6 Months</option>
                                <option value={365}>Past 1 Year</option>
                              </select>
                            </div>
                          </Paper>

                          {childValue === 'inquiries' && (
                            <Paper
                              style={{
                                width:
                                  data?.length > 6
                                    ? ((data?.length || 0) * 200)
                                        ?.toString()
                                        ?.concat('px')
                                    : '100%',
                              }}
                            >
                              <>
                                <div
                                  style={{
                                    height: '500px',
                                    width: '100%',
                                    marginTop: '160px',
                                  }}
                                >
                                  <BarCharts
                                    data={
                                      data?.length > 0 &&
                                      data?.map((v, k) => ({
                                        ...v,
                                        name: `${capitalize(
                                          v?.name
                                        )} \n (${capitalize(
                                          v?.service_group
                                        )})`,
                                      }))
                                    } // Pass the sorted data here
                                    CustomTooltip={<CustomTooltipForInquiry />}
                                  />{' '}
                                </div>
                              </>
                            </Paper>
                          )}
                          {childValue === 'non-conversion' && (
                            <>
                              <Paper
                                style={{
                                  marginTop: '160px',
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                    marginTop: '160px',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Therapy
                                  </Typography>
                                  <BarCharts
                                    yAxisLabel={
                                      <LabelList
                                        dataKey="reason"
                                        position="top"
                                      />
                                    }
                                    // CustomTooltip={<CustomTooltipForConversionReason />}
                                    data={secondData?.generic?.filter(
                                      (v, k) => v.service_group === 'Therapy'
                                    )}
                                  />
                                </div>
                              </Paper>

                              <Paper
                                style={{
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                    marginTop: '160px',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '160px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Referred Out Therapy
                                  </Typography>
                                  <StackBarCharts
                                    // yAxisLabel={<LabelList dataKey="reason" position="top" />}
                                    CustomTooltip={
                                      <CustomTooltipForReferredOut
                                        reasonOptions={reasonOptions}
                                      />
                                    }
                                    data={secondData?.referred_out_threpy}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {reasonOptions?.map((v, k) => (
                                    <div style={{ display: 'flex' }}>
                                      <div>{v}</div>
                                      <div
                                        style={{
                                          backgroundColor: reasonColor[k],
                                          height: '10px',
                                          marginTop: '160px',
                                          width: '20px',
                                          marginLeft: '10px',
                                          marginRight: '20px',
                                        }}
                                      ></div>
                                    </div>
                                  ))}
                                </div>
                              </Paper>
                              <Paper
                                style={{
                                  width:
                                    data?.length > 6
                                      ? ((data?.length || 0) * 200)
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    marginTop: '160px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Testing
                                  </Typography>
                                  <BarCharts
                                    // CustomTooltip={<CustomTooltipForConversionReason />}
                                    yAxisLabel={
                                      <LabelList
                                        dataKey="reason"
                                        position="top"
                                      />
                                    }
                                    data={secondData?.generic?.filter(
                                      (v, k) => v.service_group === 'Testing'
                                    )}
                                  />
                                </div>
                              </Paper>
                              <div
                                style={{
                                  height: '500px',
                                  overflowX: 'auto',
                                  paddingBottom: '50px',
                                  width: '100%',
                                  marginTop: '160px',
                                  overflowY: 'hidden',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  center
                                  sx={{ marginTop: '10px', marginLeft: '5px' }}
                                >
                                  Referred Out Testing
                                </Typography>
                                <StackBarCharts
                                  // yAxisLabel={<LabelList dataKey="reason" position="top" />}
                                  CustomTooltip={
                                    <CustomTooltipForReferredOut
                                      reasonOptions={reasonOptions}
                                    />
                                  }
                                  data={secondData?.referred_out_testing}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                {reasonOptions?.map((v, k) => (
                                  <div style={{ display: 'flex' }}>
                                    <div>{v}</div>
                                    <div
                                      style={{
                                        backgroundColor: reasonColor[k],
                                        height: '10px',
                                        marginTop: '160px',
                                        width: '20px',
                                        marginLeft: '10px',
                                        marginRight: '20px',
                                      }}
                                    ></div>
                                  </div>
                                ))}
                              </div>
                              <div style={{ height: '500px' }}>
                                <PieCharts data={data || []} />
                              </div>
                            </>
                          )}

                          {childValue ===
                            'non_conversion-preferred_providers' && (
                            <Paper
                              style={{
                                width:
                                  data?.length > 6
                                    ? ((data?.length || 0) * 200)
                                        ?.toString()
                                        ?.concat('px')
                                    : '100%',
                              }}
                            >
                              <div
                                style={{ height: '500px', marginTop: '160px' }}
                              >
                                <BarCharts data={data} />{' '}
                              </div>
                            </Paper>
                          )}
                          {(childValue === 'referral_sources' ||
                            childValue === 'service_type_appts' ||
                            childValue === 'service_type_non_appts' ||
                            childValue === 'appointment_type' ||
                            childValue === 'provider_new_ppts') && (
                            <>
                              <Paper
                                style={{
                                  width:
                                    data?.filter(
                                      (v, k) => v.service_group === 'Therapy'
                                    )?.length > 6
                                      ? (
                                          (data?.filter(
                                            (v, k) =>
                                              v.service_group === 'Therapy'
                                          )?.length || 0) * 200
                                        )
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                    marginTop: '160px',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Therapy
                                  </Typography>
                                  <BarCharts
                                    barWidth={150}
                                    data={data
                                      ?.filter(
                                        (v, k) => v.service_group === 'Therapy'
                                      )
                                      ?.map((v, k) => ({
                                        ...v,
                                        name:
                                          v?.name?.length > 35
                                            ? v?.name?.split(' ')[0]
                                            : capitalize(v?.name),
                                      }))}
                                  />
                                </div>
                              </Paper>

                              <Paper
                                style={{
                                  width:
                                    data?.filter(
                                      (v, k) => v.service_group === 'Testing'
                                    )?.length > 6
                                      ? (
                                          (data?.filter(
                                            (v, k) =>
                                              v.service_group === 'Testing'
                                          )?.length || 0) * 200
                                        )
                                          ?.toString()
                                          ?.concat('px')
                                      : '100%',
                                }}
                              >
                                <div
                                  style={{
                                    height: '500px',
                                    overflowX: 'auto',
                                    marginTop: '160px',
                                    paddingBottom: '50px',
                                    width: '100%',
                                    overflowY: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    center
                                    sx={{
                                      marginTop: '10px',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    Testing
                                  </Typography>
                                  <BarCharts
                                    barWidth={150}
                                    data={data
                                      ?.filter(
                                        (v, k) => v.service_group === 'Testing'
                                      )
                                      ?.map((v, k) => ({
                                        ...v,
                                        name: capitalize(v?.name),
                                      }))}
                                  />
                                </div>
                              </Paper>

                              {childValue === 'referral_sources' && (
                                <>
                                  {referalGraph?.map((v, k) => (
                                    <>
                                      <Paper
                                        style={{
                                          width:
                                            secondData?.[v?.type]?.[v?.value]
                                              ?.length > 6
                                              ? (
                                                  (secondData?.[v?.type]?.[
                                                    v?.value
                                                  ]?.length || 0) * 200
                                                )
                                                  ?.toString()
                                                  ?.concat('px')
                                              : '100%',
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: '500px',
                                            overflowX: 'auto',
                                            paddingBottom: '50px',
                                            width: '100%',
                                            overflowY: 'hidden',
                                            marginTop: '160px',
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            gutterBottom
                                            center
                                            sx={{
                                              marginTop: '10px',
                                              marginLeft: '5px',
                                            }}
                                          >
                                            {v?.name}
                                          </Typography>
                                          <StackBarCharts
                                            // yAxisLabel={<LabelList dataKey="reason" position="top" />}
                                            CustomTooltip={
                                              <CustomTooltipForReferredOut
                                                type={
                                                  v?.childValue
                                                    ? childValue
                                                    : false
                                                }
                                                reasonOptions={v?.options}
                                              />
                                            }
                                            data={
                                              secondData?.[v?.type]?.[v?.value]
                                            }
                                          />
                                        </div>
                                      </Paper>
                                      {v?.value === 'psychcareTherapist' && (
                                        <div
                                          style={{
                                            height: '500px',
                                            overflowX: 'auto',
                                            paddingBottom: '50px',
                                            width: '100%',
                                            overflowY: 'hidden',
                                            marginTop: '160px',
                                          }}
                                        >
                                          {providersOptions?.map((v, k) => (
                                            <div style={{ display: 'flex' }}>
                                              <div
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {v}
                                              </div>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    reasonColor[k],
                                                  height: '10px',
                                                  marginTop: '7px',
                                                  width: '20px',
                                                  marginLeft: '10px',
                                                  marginRight: '20px',
                                                }}
                                              ></div>
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                      {v?.value ===
                                        'otherHealthCareProvider' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                            marginTop: '160px',
                                          }}
                                        >
                                          {healthcareProvidersOptions?.map(
                                            (v, k) => (
                                              <div style={{ display: 'flex' }}>
                                                <div
                                                  style={{
                                                    textTransform: 'capitalize',
                                                  }}
                                                >
                                                  {v}
                                                </div>
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      reasonColor[k],
                                                    height: '10px',
                                                    marginTop: '160px',
                                                    width: '20px',
                                                    marginLeft: '10px',
                                                    marginRight: '20px',
                                                  }}
                                                ></div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}

                                      {v?.type === 'insuranceList' && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                            marginTop: '160px',
                                          }}
                                        >
                                          {insuranceList?.map((v, k) => (
                                            <div style={{ display: 'flex' }}>
                                              <div
                                                style={{
                                                  textTransform: 'capitalize',
                                                }}
                                              >
                                                {v}
                                              </div>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    reasonColor[k],
                                                  height: '10px',
                                                  marginTop: '160px',
                                                  width: '20px',
                                                  marginLeft: '10px',
                                                  marginRight: '20px',
                                                }}
                                              ></div>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}

        {view === 'callLogs' && (
          <>
            <div>
              {/* <Typography >Number Of Appropriateness</Typography> */}

              <div style={{ marginTop: '160px', display: 'flex' }}>
                <Box sx={{ display: 'flex', width: '15%' }}>
                  <Tabs
                    orientation="vertical"
                    value={childValue}
                    onChange={handleChangeChild}
                    aria-label="Vertical tabs example"
                    //sx={{ borderBottom: 0, borderColor: 'divider' }}
                    sx={{
                      '& button.Mui-selected': { backgroundColor: '#ebeded' },
                    }}
                  >
                    {child2Tabs?.map((v, k) => (
                      <Tab
                        sx={{
                          '&.Mui-selected': {
                            outline: 'none',
                          },
                        }}
                        label={v?.label}
                        value={v?.value}
                      />
                    ))}
                  </Tabs>
                </Box>

                <div style={{ padding: '10px', width: '85%', height: '500px' }}>
                  {loading && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '130px',
                        marginTop: '160px',
                        width: '140px',
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}

                  <div
                    style={{
                      position: 'absolute',
                      right: '63px',
                      marginTop: '-70px',
                      width: '150px',
                    }}
                  >
                    <div style={{ fontSize: '14px', marginTop: '75px' }}>
                      Select Days
                    </div>
                    <select
                      onChange={(e) => {
                        onChangeDate(e);
                      }}
                      className="outline-none"
                      style={{
                        padding: '2px 1.5px',
                        background: '#ebeded',
                        width: '100%',
                      }}
                    >
                      <option value={0}>Today</option>
                      <option value={1}>Yesterday</option>
                      <option value={7}>Last 7 Days</option>
                      <option value={30}>Past Month</option>
                      <option value={180}>Past 6 Months</option>
                      {/* <option value={365}>Past 1 Year</option> */}
                    </select>
                  </div>
                  <div style={{ display: 'flex', marginLeft: '75px' }}>
                    <div style={{ display: 'flex' }}>
                      <div>Total</div>
                      <div
                        style={{
                          backgroundColor: '#0088FE',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>0-3 min</div>
                      <div
                        style={{
                          backgroundColor: '#00C49F',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>3-10 min</div>
                      <div
                        style={{
                          backgroundColor: '#FFBB28',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>10+ min</div>
                      <div
                        style={{
                          backgroundColor: '#ff2a00',
                          height: '10px',
                          marginTop: '7px',
                          width: '20px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                      ></div>
                    </div>
                  </div>

                  {childValue === 'all_calls' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        forthBar={true}
                        data={data?.['allCalls']}
                      />{' '}
                    </div>
                  )}

                  {childValue === 'new_client' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        forthBar={true}
                        data={data?.['Extension_1+11']}
                      />{' '}
                    </div>
                  )}

                  {childValue === 'cancellations' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        forthBar={true}
                        data={data?.['Extension_3']}
                      />{' '}
                    </div>
                  )}

                  {childValue === 'outgoing' && (
                    <div style={{ height: '500px' }}>
                      <BarCharts
                        CustomTooltip={<CustomTooltipForCallLogs />}
                        firstBarColor="#0088FE"
                        secondBarColor="#00C49F"
                        thirdBarColor="#FFBB28"
                        forthBarColor="#ff2a00"
                        secondBar={true}
                        thirdBar={true}
                        forthBar={true}
                        data={data?.['outGoingCalls']}
                      />{' '}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Box>
    </>
  );
};

export default memo(Dashboard);
