import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
import React from 'react';

export const history = createBrowserHistory();

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
